const TrplDashboardMenu = [
  {
    name: 'AI MODELS',
      items: [
        {
          name: 'STOCK GPT',
          link: '/stock_gpt',
        },
        {
          name: 'PORTFOLIO GPT',
          link: '/portfolio_gpt',
        },
        {
          name: 'QUARTERLY EARNINGS',
          link: '/earnings_summary',
        }
      ]
  },
  {
    name: 'WATCHLISTS',
      items: [
        {
          name: 'BREAKOUTS',
          link: '/stock_breakouts',
        },
        {
          name: 'CLASSIC LONGS',
          link: '/stock_longs',
        },
        {
          name: 'CLASSIC SHORTS',
          link: '/stock_shorts',
        },
      ]
  },
  {
    name: 'FUNDAMENTALS',
      items: [
        {
          name: 'STOCK',
          link: '/stock_fundamentals',
        },
      ]
  },
  {
    name: 'OPTIONS',
      items: [
        {
          name: 'CALCULATOR',
          link: '/calculator_option',
        },
        {
          name: 'CALLS',
          link: '/call_option',
        },
        {
          name: 'PUTS',
          link: '/put_option',
        },
        {
          name: 'CALL SPREADS',
          link: '/bull_spread_option',
        },
        {
          name: 'PUT SPREADS',
          link: '/bear_spread_option',
        },
      ]
  },
  {
      name: 'TECHNICALS',
        items: [
          {
            name: 'SUPPORT AND RESISTANCE',
            link: '/technicals_support_resistance',
          },
          {
            name: 'LEVELS BREAKOUTS',
            link: '/technicals_breakouts',
          },
          {
            name: 'TRENDLINE BREAKOUTS',
            link: '/technicals_trendline_breakouts',
          },
        ]
  },
  {
    name: 'NEWS & SENTIMENT',
      items: [
        {
          name: 'STOCK SENTIMENT',
          link: '/stock_sentiment',
        },
        {
          name: 'VOLATILITY & CATALYSTS',
          link: '/volatility_news',
        },
        {
          name: 'MARKET NEWS',
          link: '/market_news',
        },
        {
          name: 'STOCK NEWS',
          link: '/stock_news',
        },
      ]
  },
  {
    name: 'PORTFOLIO',
      items: [
        {
          name: 'VOLATILITY',
          link: '/portfolio_volatility',
        },
        {
          name: 'CORRELATION',
          link: '/portfolio_correlation',
        },
        {
          name: 'PERFORMANCE',
          link: '/portfolio_performance',
        },
      ]
  },
  {
    name: 'VOLATILITY',
      items: [
        {
          name: 'HISTORICAL',
          link: '/historical_volatility',
        },
        {
          name: 'ATR',
          link: '/atr_volatility',
        },
        {
          name: 'OPTIONS IV',
          link: '/implied_vol',
        },
        {
          name: 'MARKET IV',
          link: '/market_vol',
        },
        {
          name: 'STOCK IV',
          link: '/stock_vol',
        },
      ]
  },
  {
    name: 'CYCLES',
      items: [
        {
          name: 'MARKET',
          link: '/presidential_cycle',
        },
        {
          name: 'SECTOR',
          link: '/sector_cycle',
        },
        {
          name: 'STOCK',
          link: '/stock_cycle',
        },
      ]
  },
];

export default TrplDashboardMenu;