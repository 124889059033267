import React from 'react';
import { EChartsOption } from 'echarts';
import ReactECharts from 'echarts-for-react';

import Theme from '../../Theme';

interface PresidentialCycleFrame {
    mean: number;
    median: number;
    min: number;
    max: number;
    last: number;
    cycleLabel: string;
  }
  
  interface PresidentialCycleGridProps {
    yearly_cycle: PresidentialCycleFrame[];
  }

const TrplBarChart2: React.FC<PresidentialCycleGridProps> = ({ yearly_cycle }) => {
  const xAxisData = yearly_cycle.map((cycle) => cycle.cycleLabel);
  const yAxisData = yearly_cycle.map((cycle) => cycle.mean);

  const options: EChartsOption = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'none',
      },
      textStyle: {
        fontFamily: 'ChivoMono', // Add your desired font here
        fontSize: 14, // Change the font size as needed
      },
      formatter: function (params : any) {
        const { value, name } = params[0];
        return `Month(s): ${name}<br />Mean Returns: ${value}%`;
      },
    },
    grid: {
        bottom: '25%', // Increase this value if needed
      },
    xAxis: {
      type: 'category',
      data: xAxisData,
      name: 'Month(s)',
      nameLocation: 'middle',
      nameGap: 120,
      nameTextStyle: {
        fontFamily: 'ChivoMono',
        fontSize: 16,
      },
      axisLabel: {
        fontFamily: 'ChivoMono',
        fontSize: 13,
        interval: 0,
        rotate: 45,
        margin: 15,
      },
    },
    yAxis: {
      type: 'value',
      name: 'Returns (%)',
      nameLocation: 'middle',
      nameGap: 82,
      splitNumber: 5,
      nameTextStyle: {
        fontFamily: 'ChivoMono',
        fontSize: 16,
      },
      axisLabel: {
        fontFamily: 'ChivoMono',
        fontSize: 13,
        rotate: 0,
      },
    },
    series: [
        {
          data: yAxisData,
          type: 'bar',
          itemStyle: {
            color: (params: any) => {
              return params.value < 0 ? '#ff7575' : Theme.palette.secondary.light;
            },
          },
        },
      ],      
  };

  return (
    <div style={{ height: '700px', width: '1000px' }}>
      <ReactECharts option={options} style={{ height: '100%' }} />
    </div>
  );
};

export default TrplBarChart2;
