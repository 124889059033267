import React from 'react';

import { DataGrid, GridColDef } from '@mui/x-data-grid';

import { StockData } from './HistoricalVolatility';
import { Box, styled } from '@mui/material';

import TrplPageHeadingFootnote from '../../../components/pages/PageHeadingFootnote';

import Theme from '../../../Theme';
import TrplPageSubheading from '../../../components/pages/PageSubheading';
import TrplBarChart from '../../../components/pages/BarChart';


let sigmaDistributionText = 'This table contains 3 standard deviations of the assets\' price range for the selected time interval.';
let rangeDistributionText = 'This table contains the frequency and probability distribution of the assets\' price range for the selected given time interval.';
let chartText = 'This chart contains the probability distribution of the assets\' price range for the selected given time interval.';

interface StockDataTableProps {
  stockData: StockData;
}

const buildSigmaDistributionColumns = (): GridColDef[] => [
    { field: 'Sigma', headerName: 'Std Dev', width: 150 },
    { field: 'PctRange', headerName: '% Range', width: 200 },
    { field: 'PriceRange', headerName: 'Price Range', width: 250 },
    { field: 'Probability', headerName: 'Probability', width: 150 },
  ];  
  
  const buildRangeDistributionColumns = (): GridColDef[] => [
    { field: 'PctRange', headerName: '% Range', width: 200 },
    { field: 'Frequency', headerName: 'Frequency', width: 150 },
    { field: 'Probability', headerName: 'Probability', width: 150 },
  ];
  
  const TrplDataGrid = styled(DataGrid)(({ theme }) => ({   
    '& .MuiDataGrid-cell': {
      borderColor: theme.palette.divider,
      borderWidth: '.5px',
      borderStyle: 'solid',
      color: 'grey',
      fontFamily: 'RobotoMono', // Add your desired font here
      fontStyle: 'thin',
    },
    '& .MuiDataGrid-columnHeader': {
      borderColor: theme.palette.divider,
      borderWidth: '.5px',
      borderStyle: 'solid',
      fontFamily: 'RobotoMono', // Add your desired font here
      color: 'grey',
      fontStyle: 'normal',
      backgroundColor: "#eef7ed",
    },
    '& .MuiDataGrid-iconSeparator': {
        display: 'none',
      },
  }));
  


const StockDataTable: React.FC<StockDataTableProps> = ({ stockData }) => {
    if (!stockData) {
        return <div>Loading...</div>;
      }
    
    const calculateTotalWidth = (columns: GridColDef[]): number => {
        return columns.reduce((accumulator, column) => accumulator + (column.width as number), 0);
      };
    
    const sigmaDistributionColumns = buildSigmaDistributionColumns();
    const sigmaDistributionWidth = calculateTotalWidth(sigmaDistributionColumns);
      
    const rangeDistributionColumns = buildRangeDistributionColumns();
    const rangeDistributionWidth = calculateTotalWidth(rangeDistributionColumns);

    return (
        <div >
          <Box height={"2vh"}/>
          <TrplPageSubheading title="Distribution Chart" fontSize="18px" color={Theme.palette.primary.dark} />
          <Box height={"1vh"}/>
          <TrplPageHeadingFootnote title={chartText} fontSize="14px" color={Theme.palette.primary.main} />
          <TrplBarChart data={stockData.range_distribution} />
          <Box height={"4vh"}/>
          <TrplPageSubheading title="Sigma Distribution" fontSize="18px" color={Theme.palette.primary.dark} />
          <Box height={"1vh"}/>
          <TrplPageHeadingFootnote title={sigmaDistributionText} fontSize="14px" color={Theme.palette.primary.main} />
          <Box height={"1vh"}/>
          <TrplDataGrid
                style={{ width: sigmaDistributionWidth }}
                columns={sigmaDistributionColumns}
                rows={stockData.sigma_distribution.map((row, index) => ({ id: index, ...row }))}
                pageSize={10}
                rowsPerPageOptions={[10]}
                autoHeight
                disableColumnMenu
                hideFooter
            />

          <Box height={"4vh"}/>
          <TrplPageSubheading title="Range Distribution" fontSize="18px" color={Theme.palette.primary.dark}/>
          <Box height={"1vh"}/>
          <TrplPageHeadingFootnote title={rangeDistributionText} fontSize="14px" color={Theme.palette.primary.main} />
          <Box height={"1vh"}/>
          <TrplDataGrid
            style={{ width: rangeDistributionWidth }}
            columns={rangeDistributionColumns}
            rows={stockData.range_distribution.map((row, index) => ({ id: index, ...row }))}
            pageSize={10}
            rowsPerPageOptions={[10]}
            autoHeight
            disableColumnMenu
            hideFooter
            />
            <Box height={"6vh"}/>
        </div>
      );
      
};

export default StockDataTable;
