import React, { useEffect, useState } from 'react';

import { DataGrid, GridColDef } from '@mui/x-data-grid';

import { Box, styled } from '@mui/material';

import TrplPageHeadingFootnote from '../../../components/pages/PageHeadingFootnote';

import Theme from '../../../Theme';
import TrplPageSubheading from '../../../components/pages/PageSubheading';


interface sentiment_frame {
    pos_mean : number;  
    pos_med  : number;
    neg_mean : number;
    neg_med : number;
};

interface StockSentimentDataGridProps {
  sentiment: sentiment_frame;
}

const buildSentimentDistributionColumns = (): GridColDef[] => [
    { field: 'sentiment', headerName: 'Sentiment', width: 150 },
    { field: 'measure', headerName: 'Measure', width: 150 },
    { field: 'score', headerName: 'Score', width: 150 },
];
  
  const TrplDataGrid = styled(DataGrid)(({ theme }) => ({   
    '& .MuiDataGrid-cell': {
      borderColor: theme.palette.divider,
      borderWidth: '.5px',
      borderStyle: 'solid',
      color: 'grey',
      fontFamily: 'RobotoMono', // Add your desired font here
      fontStyle: 'thin',
    },
    '& .MuiDataGrid-columnHeader': {
      borderColor: theme.palette.divider,
      borderWidth: '.5px',
      borderStyle: 'solid',
      fontFamily: 'RobotoMono', // Add your desired font here
      color: 'grey',
      fontStyle: 'normal',
      backgroundColor: "#eef7ed",
    },
    '& .MuiDataGrid-iconSeparator': {
        display: 'none',
      },
  }));
  
  const sentimentTableText =  'This table contains the mean and median sentiment of the ticker using recent news.';


const StockSentimentDataGrid: React.FC<StockSentimentDataGridProps> = ({ sentiment }) => {    
    const calculateTotalWidth = (columns: GridColDef[]): number => {
        return columns.reduce((accumulator, column) => accumulator + (column.width as number), 0);
      };

    console.log(sentiment.neg_mean)
    
    const distributionColumns = buildSentimentDistributionColumns();
    const sentimentRows = [
        { id: 1, sentiment: 'Positive', measure: 'Mean', score: sentiment.pos_mean },
        { id: 2, sentiment: 'Positive', measure: 'Median', score: sentiment.pos_med },
        { id: 3, sentiment: 'Negative', measure: 'Mean', score: sentiment.neg_mean },
        { id: 4, sentiment: 'Negative', measure: 'Median', score: sentiment.neg_med },
    ];

    const distributionWidth = calculateTotalWidth(distributionColumns);
    
    return (
        <div >
          <TrplPageSubheading title="Sentiment Table" fontSize="18px" color={Theme.palette.primary.dark} />
          <Box height={"1vh"}/>
          <TrplPageHeadingFootnote title={sentimentTableText} />
          <Box height={"1vh"}/>
          <TrplDataGrid
                style={{ width: distributionWidth }}
                columns={distributionColumns}
                rows={sentimentRows}
                pageSize={10}
                rowsPerPageOptions={[6]}
                autoHeight
                disableColumnMenu
                hideFooter
            />
            <Box height={"6vh"}/>
        </div>
      );
      
};

export default StockSentimentDataGrid;
