import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Telemetry from './Telemetry';

interface AuthCallbackProps {
  setIsAuthenticated: React.Dispatch<React.SetStateAction<boolean>>;
}

export const AuthCallback: React.FC<AuthCallbackProps> = ({ setIsAuthenticated }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const verifyAuth = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get('code');

      if (code) {
        try {
          const response = await fetch(`${process.env.REACT_APP_BACKEND}/auth/callback/`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ code }),
          });

          const data = await response.json();
          if (response.ok && data.profile) {
            localStorage.setItem('PrismFI_isAuthenticated', 'true');
            setIsAuthenticated(true);

            const telemetry = Telemetry.getInstance();
            telemetry.trackEvent('Authentication', { Success: true });

            navigate('/');
          } else {
            console.error('Authentication failed');
            setError('Authentication failed');
            const telemetry = Telemetry.getInstance();
            telemetry.trackEvent('Authentication', { Success: false, Error: 'Authentication failed'});
          }
        } catch (error) {
          console.error('Error during authentication:', error);
          setError('Authentication failed');
          const telemetry = Telemetry.getInstance();
            telemetry.trackEvent('Authentication', { Success: false, Error: error});
        } finally {
          setIsLoading(false);
        }
      } else {
        setError('Authentication code not found');
        const telemetry = Telemetry.getInstance();
            telemetry.trackEvent('Authentication', { Success: false, Error: 'Authentication code not found'});
        setIsLoading(false);
      }
    };

    verifyAuth();
  }, [navigate, setIsAuthenticated]);

  return null;
};
