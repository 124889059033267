import React, { useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { styled } from '@mui/system';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import TrplPageHeading from '../../../components/pages/PageHeading';
import TrplPageHeadingFootnote from '../../../components/pages/PageHeadingFootnote';
import TrplBasicButton from '../../../components/pages/BasicButton';
import TrplPageInputText from '../../../components/pages/PageInputText';
import TrplInputTextField from '../../../components/pages/InputTextField';
import { Box } from '@mui/material';
import TrplPageSubheading from '../../../components/pages/PageSubheading';
import Theme from '../../../Theme';
import TrplDropdownMenu from '../../../components/pages/DropdownMenu';
import { OptionKeys, FundamentalChart } from '../../../components/pages/FundamentalChart';
import Telemetry from '../../../Telemetry';

const pageTitle = 'Stock Fundamentals';
const pageSubheading = 'This model obtains fundamental information for a stock.';
const pageInputText = 'Enter a ticker and press enter to execute the model.';
const pageDropdownText = 'Select a fundamental metric to visualize.';
const stockFundamentalText = 'The table below displays fundamental data for the stock.';
const stockFundamentalChartText = 'The chart below displays fundamental data for the stock.';

const chartOptions = ['TOTAL REVENUE', 'EPS ACTUAL', 'EPS ESTIMATE', 'TOTAL SHARES', 'FORWARD PE', 'MARKET CAP', 'NET INCOME', 'PRICE TO SALES', 'CLOSING PRICE'];

interface StockFundamentalsData {
    Date: string;
    'Total Revenue': number;
    'EPS Actual': number;
    'EPS Estimate': number;
    'Total Shares': number;
    'Fwd PE': number;
    'Market Cap': number;
    'Net Income': number;
    'Price To Sales': number;
    'Previous Close': number;
}

const TrplDataGrid = styled(DataGrid)(({ theme }) => ({
    '& .MuiDataGrid-cell': {
        borderColor: theme.palette.divider,
        borderWidth: '.5px',
        borderStyle: 'solid',
        color: 'grey',
        fontFamily: 'RobotoMono',
        fontStyle: 'thin',
    },
    '& .MuiDataGrid-columnHeader': {
        borderColor: theme.palette.divider,
        borderWidth: '.5px',
        borderStyle: 'solid',
        fontFamily: 'RobotoMono',
        color: 'grey',
        fontStyle: 'normal',
        backgroundColor: "#eef7ed",
    },
    '& .MuiDataGrid-iconSeparator': {
        display: 'none',
    },
}));

export default function StockFundamentals() {
    const [inputValue, setInputValue] = useState('');
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(event.target.value);
    };

    const [selectedChartOption, setSelectedChartOption] = useState<string>(chartOptions[0]);

    const handleChartDropdownChange = (value: string) => {
    setSelectedChartOption(value);
    };

    const [data, setData] = useState<StockFundamentalsData[]>();
    const fetchData = (ticker: string) => {
        fetch(`${process.env.REACT_APP_BACKEND}/fundamentals-stock/?stockTicker=${ticker}`)
            .then(response => response.json())
            .then((data: StockFundamentalsData[]) => {
                const formattedData = data.map((item, index) => {
                    const dateObj = new Date(item.Date);
                    const formattedDate = `${dateObj.getMonth() + 1}-${dateObj.getDate()}-${dateObj.getFullYear()}`;
                    return { id: index, ...item, Date: formattedDate};
                });
                setData(formattedData);
                const telemetry = Telemetry.getInstance();
                telemetry.trackEvent('Fundamentals_Stock_Fetch', {Stock_Ticker: ticker, Success: true});
              })
              .catch((error) => {
                const telemetry = Telemetry.getInstance();
                telemetry.trackEvent('Fundamentals_Stock_Fetch', {Stock_Ticker: ticker, Success: false, Error: error});
              });
    };
    
    const handleClick = () => {
        fetchData(inputValue);
    };

    return (
        <div>
            <TrplPageHeading title={pageTitle}/>
            <TrplPageHeadingFootnote title={pageSubheading} />
            <Box sx={{ flexDirection: 'row', display: 'flex', gap: '6px', mt: 4, mb: .5}}>
                <TrplInputTextField label='STOCK TICKER' height='4.7vh' fontSize='0.9rem' value={inputValue} onChange={handleInputChange} />
                <TrplBasicButton label='ENTER' height='4.7vh' width='4vw' onClick={handleClick}/>
            </Box>
            <TrplPageInputText title={pageInputText}/>
            <Box height="2vh"/>
            {data && (
                <>
                    <TrplPageSubheading title="Fundamentals Chart" fontSize="18px" color={Theme.palette.primary.dark} />
                    <TrplPageHeadingFootnote title={stockFundamentalChartText} />
                    <Box height={"2vh"}/>
                    <TrplDropdownMenu options={chartOptions} onChange={handleChartDropdownChange} height='4.7vh' width='12vw' endIcon={<KeyboardArrowDownIcon />}/>
                    <Box height={"1vh"}/>
                    <TrplPageInputText title={pageDropdownText}/>
                    <FundamentalChart data={data} option={selectedChartOption as OptionKeys} />
                    <TrplPageSubheading title="Fundamentals Table" fontSize="18px" color={Theme.palette.primary.dark} />
                    <Box height={"1vh"}/>
                    <TrplPageHeadingFootnote title={stockFundamentalText} />
                    <Box height={"1vh"}/>
                    <TrplDataGrid
                        rows={data}
                        style={{ width: 1320, height: 630 }} // <- add a height here
                        columns={[
                            { field: 'id', headerName: 'ID', width: 90, hide: true },
                            { field: 'Date', headerName: 'Date', width: 120 },
                            { field: 'Total Revenue', headerName: 'Total Revenue', width: 130 },
                            { field: 'EPS Actual', headerName: 'EPS Actual', width: 120 },
                            { field: 'EPS Estimate', headerName: 'EPS Estimate', width: 120 },
                            { field: 'Total Shares', headerName: 'Total Shares', width: 130 },
                            { field: 'Fwd PE', headerName: 'Fwd PE', width: 150 },
                            { field: 'Market Cap', headerName: 'Market Cap', width: 150 },
                            { field: 'Net Income', headerName: 'Net Income', width: 120 },
                            { field: 'Price To Sales', headerName: 'Price To Sales', width: 140 },
                            { field: 'Previous Close', headerName: 'Previous Close', width: 140 },
                        ]}
                        pageSize={10}
                    />
                    <Box height="10vh"/>
                </>
            )}
        </div>
    );
}