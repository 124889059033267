import { useState, useEffect } from 'react';
import { Position } from './PortfolioGPT';
import Telemetry from '../../../Telemetry';

export interface PortfolioAnalysisData {
  position_exposure: {
    ticker: string;
    net_exposure: number;
    absolute_net_exposure: number;
    size: number;
    exposure: string;
  }[];
  summary_exposure: {
    net_exposure: number;
    size: number;
    exposure: string;
    absolute_net_exposure: number;
  }[];
  exposure_interpretation: string;
  correlation_matrix: {
    [key: string]: {
      [key: string]: number;
    };
  };
  overall_correlation: number;
  correlation_interpretation: string;
  high_prices: {
    Time_Period: string;
    Date: string;
    [key: string]: string | number;
  }[];
  low_prices: {
    Time_Period: string;
    Date: string;
    [key: string]: string | number;
  }[];
  high_prices_p_l: {
    Week_s: string;
    TOTAL: number;
    [key: string]: string | number;
  }[];
  high_prices_position_pl: {
    Week_s: string;
    TOTAL: number;
    [key: string]: string | number;
  }[];
  high_prices_interpretation: string;
  low_prices_p_l: {
    Week_s: string;
    TOTAL: number;
    [key: string]: string | number;
  }[];
  low_prices_position_pl: {
    Week_s: string;
    TOTAL: number;
    [key: string]: string | number;
  }[];
  low_prices_interpretation: string;
}

export function usePortfolioAnalysis(positions: Position[], triggerAnalysis: boolean) {
  const [socket, setSocket] = useState<WebSocket | null>(null);
  const [analysisData, setAnalysisData] = useState<PortfolioAnalysisData | null>(null);

  useEffect(() => {
    if (triggerAnalysis) {
      const newSocket = new WebSocket(`${process.env.REACT_APP_WEBSOCKET}/portfolio-analysis/`);
      setSocket(newSocket);

      newSocket.onopen = () => {
        console.log('WebSocket connection established');
        const telemetry = Telemetry.getInstance();
        telemetry.trackEvent('AI_Portfolio_GPT_Start', { Positions: JSON.stringify({ positions }), Success: true});
        newSocket.send(JSON.stringify({ positions }));
      };

      newSocket.onmessage = (event) => {
        const data = JSON.parse(event.data);
        console.log("Received data:", data);
        setAnalysisData((prevData) => {
          const updatedData: PortfolioAnalysisData = {
            position_exposure: data.position_exposure || prevData?.position_exposure || [],
            summary_exposure: data.summary_exposure || prevData?.summary_exposure || [],
            exposure_interpretation: (prevData?.exposure_interpretation || "") + (data.exposure_interpretation || ""),
            correlation_matrix: data.correlation_matrix || prevData?.correlation_matrix || {},
            overall_correlation: data.overall_correlation || prevData?.overall_correlation || 0,
            correlation_interpretation: (prevData?.correlation_interpretation || "") + (data.correlation_interpretation || ""),
            high_prices: data.high_prices || prevData?.high_prices || [],
            low_prices: data.low_prices || prevData?.low_prices || [],
            high_prices_p_l: data.high_prices_p_l || prevData?.high_prices_p_l || [],
            high_prices_position_pl: data.high_prices_position_pl || prevData?.high_prices_position_pl || [],
            high_prices_interpretation: (prevData?.high_prices_interpretation || "") + (data.high_prices_interpretation || ""),
            low_prices_p_l: data.low_prices_p_l || prevData?.low_prices_p_l || [],
            low_prices_position_pl: data.low_prices_position_pl || prevData?.low_prices_position_pl || [],
            low_prices_interpretation: (prevData?.low_prices_interpretation || "") + (data.low_prices_interpretation || ""),
          };

          return updatedData;
        });
      };

      newSocket.onclose = () => {
        console.log('WebSocket connection closed');
        const telemetry = Telemetry.getInstance();
        telemetry.trackEvent('AI_Portfolio_GPT_End', { Positions: JSON.stringify({ positions }), Success: true});
        setSocket(null);
      };

      newSocket.onerror = (error) => {
        console.log('WebSocket error:', error);
        setSocket(null);
        const telemetry = Telemetry.getInstance();
        telemetry.trackEvent('AI_Portfolio_GPT_End', { Positions: JSON.stringify({ positions }), Success: false});

      };

      return () => {
        if (newSocket) {
          newSocket.close();
          setSocket(null);
        }
      };
    }
  }, [positions, triggerAnalysis]);

  return analysisData;
}