import React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Avatar from "@mui/material/Avatar";
import TrplTextButton from "./components/TextButton";
import Logo from "./PrismLogo.png";
import TrplTitleTextButton from "./components/TitleTextButton";
import LearnMore from "./LearnMore";
import useLearnMore from "./UseLearnMore";

interface SignInProps {
  handleLogin: () => void;
}

const SignIn: React.FC<SignInProps> = ({ handleLogin }) => {
  const { showLearnMore, handleLearnMore, handleBack } = useLearnMore();

  const title = "PRISM FI";
  const login = "Login / Register";
  const learnMore = "Learn More";

  return (
    <>
      {showLearnMore ? (
        <LearnMore handleBack={handleBack} handleLogin={handleLogin} />
      ) : (
        <>
          <Stack
            spacing={1}
            sx={{
              justifyContent: "center",
              alignItems: "center",
              minHeight: "90vh",
            }}
          >
            <Box paddingTop="10vh">
              <Avatar
                src={Logo}
                alt="Logo"
                sx={{ width: 110, height: 110, margin: "auto" }}
              />
            </Box>
            <Box
              paddingBottom="9vh"
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              <TrplTitleTextButton label={title} fontSize="32px" />
              <TrplTitleTextButton label="( BETA )" fontSize="20px" />
            </Box>

            <Box padding="3vh">
              <TrplTextButton
                label={login}
                fontSize="22px"
                disableRipple={true}
                onClick={handleLogin}
                fontFamily="RamaGothic"
                color="#757575"
              />
            </Box>
          </Stack>

          <Stack
            sx={{
              justifyContent: "center",
              alignItems: "center",
              minHeight: "10vh",
            }}
          >
            <TrplTextButton
              label={learnMore}
              fontSize="18px"
              disableRipple={true}
              fontFamily="RamaGothic"
              color="#757575"
              onClick={handleLearnMore}
            />
          </Stack>
        </>
      )}
      );
    </>
  );
};

export default SignIn;
