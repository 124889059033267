import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import TrplTheme from '../../Theme';
import styled from '@emotion/styled';
import { Divider } from '@mui/material';
import TrplDropdownMenuItem from '../DropdownMenuItem';
import { useDropdownMenu } from '../../hooks/UseDropdownMenu';

export default function TrplDropdownMenu(props: any) {
  const { anchorEl, selectedLabel, open, handleClick, handleClose } = useDropdownMenu(props.options[0], props.onChange);

  const StyledMenu = styled(Menu)({
    color: TrplTheme.palette.primary.light,
    '& .MuiPaper-root': {
      borderRadius: 0,
      border: '.5px solid grey',
      width: props.width,
      overflowY: 'auto', // Enable vertical scrolling
    },
  });

  const RenderMenuItems = () => {
    return props.options.map((option: string, index: number, array: string[]) => (
      <div key={option}>
        <TrplDropdownMenuItem onClick={() => handleClose(option)} label={option} />
        {index !== array.length - 1 && <Divider />}
      </div>
    ));
  };

  return (
    <div>
      <Button
        disableRipple={props.disableRipple}
        id={props.id}
        variant="outlined"
        onClick={handleClick}
        endIcon={props.endIcon}
        sx={{
          fontSize: props.fontSize,
          padding: props.padding,
          borderRadius: 0,
          width: props.width,
          height: props.height,
        }}
      >
        {selectedLabel}
      </Button>
      <StyledMenu
        elevation={0}
        anchorEl={anchorEl}
        open={open}
        onClose={() => handleClose(selectedLabel)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          style: {
            maxHeight: '300px',
            overflowY: 'auto',
          },
        }}
      >
        {RenderMenuItems()}
      </StyledMenu>
    </div>
  );
}