import React, { useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Box, styled } from '@mui/material';

import TrplInputTextField from '../../../components/pages/InputTextField';
import TrplPageHeading from '../../../components/pages/PageHeading';
import TrplPageHeadingFootnote from '../../../components/pages/PageHeadingFootnote';
import TrplBasicButton from '../../../components/pages/BasicButton';
import TrplPageInputText from '../../../components/pages/PageInputText';
import TrplPageSubheading from '../../../components/pages/PageSubheading';
import Theme from '../../../Theme';
import LoadingErrorDisplay from '../../../components/pages/LoadingErrorDisplay';
import Telemetry from '../../../Telemetry';

const pageTitle = 'Call Option Pricing';
const pageSubheading = 'This model calculates the optimal call option price for an asset while maximizing the risk-reward profile.';
const pageInputText = 'Enter the stock ticker, position size and target price to execute the model.';
const callTableText = 'This table contains the optimal call options for the target price sorted by risk-reward profiles.';

const TrplDataGrid = styled(DataGrid)(({ theme }) => ({
  '& .MuiDataGrid-cell': {
    borderColor: theme.palette.divider,
    borderWidth: '.5px',
    borderStyle: 'solid',
    color: 'grey',
    fontFamily: 'RobotoMono',
    fontStyle: 'thin',
  },
  '& .MuiDataGrid-columnHeader': {
    borderColor: theme.palette.divider,
    borderWidth: '.5px',
    borderStyle: 'solid',
    fontFamily: 'RobotoMono',
    color: 'grey',
    fontStyle: 'normal',
    backgroundColor: "#eef7ed",
  },
  '& .MuiDataGrid-iconSeparator': {
    display: 'none',
  },
}));

const CallOption: React.FC = () => {
  const [stockData, setStockData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<{ message: string; code?: number } | null>(null);

  const fetchData = async (stockTicker: string, positionSize: string, targetPrice: string) => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND}/call-options/?stockTicker=${stockTicker}&positionSize=${positionSize}&targetPrice=${targetPrice}`);
      if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
      const data = await response.json();
      const callOptionsWithIds = JSON.parse(data.CallOptions).map((option: Object, index: number) => ({
        ...option,
        id: index,
      }));
      setStockData(callOptionsWithIds);
      const telemetry = Telemetry.getInstance();
      telemetry.trackEvent('Options_Call_Fetch', {Stock_Ticker: stockTicker, Success: true});
    } catch (error: any) {
      setError({ message: error.message, code: error.status });
      const telemetry = Telemetry.getInstance();
      telemetry.trackEvent('Options_Call_Fetch', {Stock_Ticker: stockTicker, Success: false, Error: error.message});
    } finally {
      setIsLoading(false);
    }
  };

  const [tickerValue, setTickerValue] = useState('');
  const handleTickerChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTickerValue(event.target.value);
  };

  const [positionSize, setPositionSize] = useState('');
  const handleSizeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPositionSize(event.target.value);
  };

  const [targetPrice, setTargetPrice] = useState('');
  const handlePriceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTargetPrice(event.target.value);
  };

  const handleClick = () => {
    fetchData(tickerValue, positionSize, targetPrice);
  };

  return (
      <div>
        <TrplPageHeading title={pageTitle} />
        <TrplPageHeadingFootnote title={pageSubheading} />
        <Box sx={{ flexDirection: 'row', display: 'flex', gap: '6px', mt: 4, mb: .5 }}>
          <TrplInputTextField label='STOCK TICKER' height='4.7vh' fontSize='0.9rem' value={tickerValue} onChange={handleTickerChange} />
          <TrplInputTextField label='POSITION SIZE' height='4.7vh' width='8vw' fontSize='0.9rem' value={positionSize} onChange={handleSizeChange} />
          <TrplInputTextField label='TARGET PRICE' height='4.7vh' width='8vw' fontSize='0.9rem' value={targetPrice} onChange={handlePriceChange} />
          <TrplBasicButton label='ENTER' height='4.7vh' width='4vw' onClick={handleClick} />
        </Box>
        <TrplPageInputText title={pageInputText} />
        <Box height={"4vh"} />

        {/* Use the LoadingErrorDisplay component */}
      <LoadingErrorDisplay isLoading={isLoading} error={error} />

      {!isLoading && !error && stockData.length > 0 && (
          <>
            <TrplPageSubheading title="Optimal Call Options" fontSize="18px" color={Theme.palette.primary.dark} />
            <Box height={"1vh"} />
            <TrplPageHeadingFootnote title={callTableText} />
            <Box height={"1vh"} />
            <TrplDataGrid
              style={{ width: 1320, height: 630 }}
              rows={stockData}
              columns={[
                { field: 'Option Date', headerName: 'DATE', width: 150 },
                { field: '# of Options', headerName: '# OPTIONS', width: 100 },
                { field: 'Buy at Strike', headerName: 'STRIKE', width: 100 },
                { field: 'Buy Price', headerName: 'PRICE', width: 100 },
                { field: 'Cost of Buy', headerName: 'COST', width: 100 },
                { field: 'Max Loss', headerName: 'MAX LOSS', width: 100 },
                { field: 'Scaled Vol', headerName: 'VOLATILITY', width: 120 },
                { field: 'Volatility Limit Price', headerName: 'LIMIT PRICE', width: 120 },
                { field: 'Profit at Target', headerName: 'PROFIT AT TARGET', width: 150 },
                { field: 'Profit at Volatility Limit', headerName: 'PROFIT AT LIMIT', width: 150 },
                { field: 'Risk-Reward', headerName: 'RISK-REWARD', width: 130 },
              ]}
              pageSize={10}
              rowsPerPageOptions={[10]}
              checkboxSelection={false}
            />
            <Box height={"10vh"} />
          </>
        )}
      </div>
  );
}

export default CallOption;
