import { styled } from '@mui/material/styles';

import Button from '@mui/material/Button';

import TrplTheme from '../Theme';
import { Typography } from '@mui/material';

export default function TrplTitleTextButton(props: any) {
    
    const StyledButton = styled(Typography)({
        '&:hover': {
            backgroundColor: 'transparent',
        },
        
        color: "#545454",
        //color: TrplTheme.palette.primary.dark,
        fontFamily: 'DharmaGothic',
        fontSize: props.fontSize,
        padding: props.padding,
        transform: props.transform,
        paddingLeft: props.mx,
        paddingRight: props.mx,
    });

    return (
        <StyledButton 
            
            id={props.id}
            
            onClick={props.onClick}
        >
            {props.label}
        </StyledButton>
    );
}