import { Box } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import TrplDropdownMenu from '../../../components/pages/DropdownMenu';
import TrplInputTextField from '../../../components/pages/InputTextField';

import TrplPageHeading from '../../../components/pages/PageHeading';
import TrplPageHeadingFootnote from '../../../components/pages/PageHeadingFootnote';
import TrplBasicButton from '../../../components/pages/BasicButton';
import TrplPageInputText from '../../../components/pages/PageInputText';

import Theme from '../../../Theme';
import StockSentimentDataGrid from './StockSentimentDataGrid';
import NewsCard from './NewsCard';
import TrplPageSubheading from '../../../components/pages/PageSubheading';
import LoadingErrorDisplay from '../../../components/pages/LoadingErrorDisplay';
import FetchNewsSummary, { FetchNewsSummaryHandles } from './NewsSummary'; // adjust the import path as necessary
import TrplPageMainText from '../../../components/pages/PageMainText'; // adjust the import path as necessary
import Telemetry from '../../../Telemetry';

const pageTitle = 'Stock Sentiment';
const pageSubheading = 'This model measures the sentiment of the stock using recent news.';
const pageInputText = 'Enter the stock ticker and number of news articles to execute the model.';

const options = [12, 24, 48, 96];

interface sentiment_frame {
    pos_mean : number;  
    pos_med  : number;
    neg_mean : number;
    neg_med : number;
};

interface news_frame {
    title : string;
    link : string;
    date : string;
    content : string;
    pos_sentiment : number;
    neg_sentiment : number;
};

interface StockSentimentResponse {
    ticker_name : string;
    sentiment_frame : sentiment_frame;
    news_frame : news_frame[];
}


export default function StockSentiment() {
  const [stockSentimentData, setStockSentimentData] = useState<StockSentimentResponse | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<{ message: string; code?: number } | null>(null);
  
  const fetchNewsSummaryRef = useRef<FetchNewsSummaryHandles>(null);
  const [newsSummary, setNewsSummary] = useState<string>('');

  const handleSummarizeClick = (articleInfo: {content: string, title: string, date: string}) => {
    newsSummary && setNewsSummary('');
    setSelectedArticle({ title: articleInfo.title, date: articleInfo.date }); // Save article info
    if (fetchNewsSummaryRef.current) {
      fetchNewsSummaryRef.current.fetchNewsSummary({
        content: articleInfo.content,
        onNewsSummaryReceived: (chunk: string) => {
          setNewsSummary(prevNewsSummary => prevNewsSummary + chunk);
        },
      });
    }
  };
  

  const fetchData = (stockTicker: string, numArticles: number) => {
    setIsLoading(true);
    newsSummary && setNewsSummary('');
    stockSentimentData && setStockSentimentData(null);
    fetch(`${process.env.REACT_APP_BACKEND}/stock-sentiment/?stockTicker=${stockTicker}&numArticles=${numArticles}`)
      .then(response => response.text())
      .then(text => {
        const parsedText = JSON.parse(text);
  
        const data: StockSentimentResponse = {
          ticker_name: parsedText.ticker_name.replace(/"/g, ''),
          sentiment_frame: parsedText.sentiment_frame[0],
          news_frame: parsedText.news_frame,
        };
  
        setStockSentimentData(data);
        setIsLoading(false);

        const telemetry = Telemetry.getInstance();
        telemetry.trackEvent('Card_News_Ticker_Fetch', {Stock_Ticker: stockTicker, Success: true});
      })
      .catch(error => {
        const telemetry = Telemetry.getInstance();
        telemetry.trackEvent('Card_News_Ticker_Fetch', {Stock_Ticker: stockTicker, Success: false, Error: error});
      });
  };
  

  useEffect(() => {
    console.log(stockSentimentData);
    }, [stockSentimentData]);
  

  const [selectedOption, setSelectedOption] = useState(options[0]);
  const handleDropdownChange = (value: number) => {
    setSelectedOption(value);
  };  

  const [inputValue, setInputValue] = useState('');
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const handleClick = () => {
    fetchData(inputValue, selectedOption);
  };

  function formatDate(dateString: string): string {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'long',
      day: '2-digit'
    }).format(date);
  }

  const [selectedArticle, setSelectedArticle] = useState<{
    title: string;
    date: string;
  } | null>(null);
  
  return (
    <div>
        <TrplPageHeading title={pageTitle}/>
        <TrplPageHeadingFootnote title={pageSubheading} />
        <Box sx={{ flexDirection: 'row', display: 'flex', gap: '6px', mt: 4, mb: .5}}>
          <TrplInputTextField label='STOCK TICKER' height='4.7vh' fontSize='0.9rem' value={inputValue} onChange={handleInputChange} />
          <TrplDropdownMenu options={options} onChange={handleDropdownChange} height='4.7vh' width='8vw' endIcon={<KeyboardArrowDownIcon />}/>
          <TrplBasicButton label='ENTER' height='4.7vh' width='4vw' onClick={handleClick}/>
        </Box>
        <TrplPageInputText title={pageInputText}/>
        <Box height="4vh"/>
        <LoadingErrorDisplay isLoading={isLoading} error={error} />
        {stockSentimentData && (
          <>
            <TrplPageHeading title={stockSentimentData.ticker_name} fontSize='21px' color={Theme.palette.primary.dark} /> 
            <Box height="2vh"/>
            <TrplPageSubheading title="Recent Headlines" fontSize="18px" color={Theme.palette.primary.dark} />
            <Box height="1vh"/>
            <NewsCard news_frame={stockSentimentData.news_frame} onSummarizeClick={handleSummarizeClick}/>
            <Box height='2vh'/>
            {newsSummary && selectedArticle &&
              <>
                <TrplPageSubheading title="News Summary" fontSize="18px" color={Theme.palette.primary.dark} />
                <Box height='1vh'/>
                <TrplPageSubheading title={selectedArticle.title} fontSize="16px" color={Theme.palette.primary.dark} />
                <TrplPageSubheading title={formatDate(selectedArticle.date)} fontSize="16px" color={Theme.palette.primary.dark} />
                <TrplPageMainText text={newsSummary}/>
              </>
            }
            <Box height='4vh'/>
            <StockSentimentDataGrid sentiment={stockSentimentData.sentiment_frame}/>
          </>
        )}
        <FetchNewsSummary ref={fetchNewsSummaryRef} />
    </div>
  );
}
