export enum MenuType {
  Dashboard,
  Training,
  Analysis,
}

export interface MenuPageItem {
    name: string;
    link: string;
  }
  
  export interface MenuHeadingItem {
    name: string;
    items: MenuPageItem[];
  }
  
  export interface Menu {
    name: string;
    menuList: MenuHeadingItem[];
    type: MenuType;
}