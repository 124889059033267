// LineChart.tsx
import React, { useEffect, useRef } from 'react';
import * as echarts from 'echarts';
import { ECharts, EChartsOption, SeriesOption } from 'echarts';

interface LineChartProps {
  data: { [key: string]: string | number }[];
}

const getRandomColor = () => {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

export const LineChart: React.FC<LineChartProps> = ({ data }) => {
  const chartRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (chartRef.current && data) {
      const chart: ECharts = echarts.init(chartRef.current);
      const options: EChartsOption = {
        animation: true,
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
          },
          textStyle: {
            fontFamily: 'ChivoMono',
            fontSize: 14,
          },
          formatter: function (params: any) {
            const date = params[0].axisValue;
            const tooltipData = params.map((param: any) => {
              const seriesName = param.seriesName;
              if (seriesName === 'Time Period' || seriesName === 'Week(s)') {
                return `${seriesName}: ${param.value}`;
              } else {
                const seriesColor = param.color;
                return `<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${seriesColor};"></span>${seriesName}: ${param.value}`;
              }
            });
            return `Date: ${date}<br/>${tooltipData.join('<br/>')}`;
          },
        },
        xAxis: {
          name: 'Date',
          nameLocation: 'middle',
          nameGap: 70,
          nameTextStyle: {
            fontFamily: 'ChivoMono',
            fontSize: 16,
          },
          axisLabel: {
            fontFamily: 'ChivoMono',
            fontSize: 13,
            interval: 0,
            margin: 12,
            rotate: 30,
          },
          data: data.map((item) => item.Date),
        },
        grid: {
          bottom: '10%',
          containLabel: true,
        },
        yAxis: {
          name: 'Price',
          nameLocation: 'middle',
          nameGap: 140,
          nameTextStyle: {
            fontFamily: 'ChivoMono',
            fontSize: 16,
            padding: [0, -90, 0, 0],
          },
          axisLabel: {
            fontFamily: 'ChivoMono',
            fontSize: 13,
            interval: 20,
            rotate: 0,
          },
        },
        series: [],
      };

      // Clear existing series
      chart.setOption({ series: [] }, true);

      // Populate new series based on data
      const series: SeriesOption[] = Object.keys(data[0])
        .filter((key) => key !== 'Date')
        .map((key) => ({
          name: key,
          type: 'line',
          data: data.map((item) => item[key]),
          itemStyle: {
            color: getRandomColor(),
            borderColor: getRandomColor(),
          },
        }));
      
      options.series = series;
      chart.setOption(options, true);
    }
  }, [data]); // Dependency on data ensures update when data changes

  return <div ref={chartRef} style={{ width: '100%', height: '600px' }}></div>;
};