import React, { useState } from 'react';
import { Box, styled } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import TrplDropdownMenu from '../../../components/pages/DropdownMenu';
import TrplInputTextField from '../../../components/pages/InputTextField';
import TrplPageHeading from '../../../components/pages/PageHeading';
import TrplPageHeadingFootnote from '../../../components/pages/PageHeadingFootnote';
import TrplBasicButton from '../../../components/pages/BasicButton';
import TrplPageInputText from '../../../components/pages/PageInputText';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import Telemetry from '../../../Telemetry';

const pageTitle = 'Implied Volatility';
const pageSubheading = 'This model returns the implied volatility for a given stock ticker and strike price.';
const pageInputText = 'Enter the stock ticker and select a strike price to execute the model.';

interface ImpliedVolData {
  date: string;
  option_type: string;
  implied_vol: number;
}

const TrplDataGrid = styled(DataGrid)(({ theme }) => ({
  '& .MuiDataGrid-cell': {
    borderColor: theme.palette.divider,
    borderWidth: '.5px',
    borderStyle: 'solid',
    color: 'grey',
    fontFamily: 'RobotoMono', // Add your desired font here
    fontStyle: 'thin',
  },
  '& .MuiDataGrid-columnHeader': {
    borderColor: theme.palette.divider,
    borderWidth: '.5px',
    borderStyle: 'solid',
    fontFamily: 'RobotoMono', // Add your desired font here
    color: 'grey',
    fontStyle: 'normal',
    backgroundColor: "#eef7ed",
  },
  '& .MuiDataGrid-iconSeparator': {
    display: 'none',
  },
}));

const ImpliedVolatility = () => {
  const [ticker, setTicker] = useState('');
  const [strikes, setStrikes] = useState<string[]>([]);
  const [selectedStrike, setSelectedStrike] = useState('');
  const [impliedVolData, setImpliedVolData] = useState<ImpliedVolData[]>([]);

  const handleTickerSubmit = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND}/get-strikes/?stockTicker=${ticker}`);
      const data = await response.json();
      setStrikes(data);
        const telemetry = Telemetry.getInstance();
        telemetry.trackEvent('Options_Implied_Volatility_Fetch', {Stock_Ticker: ticker, Success: true});
      } catch (error) {
        const telemetry = Telemetry.getInstance();
        telemetry.trackEvent('Options_Implied_Volatility_Fetch', {Stock_Ticker: ticker, Success: false, Error: error});
      }
  };

  const handleStrikeSubmit = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND}/get-implied-vol/?stockTicker=${ticker}&strikePrice=${selectedStrike}`);
      const data = await response.json();
      setImpliedVolData(data);
        const telemetry = Telemetry.getInstance();
        telemetry.trackEvent('Options_Implied_Volatility_Fetch', {Stock_Ticker: ticker, Selected_Strike: selectedStrike, Success: true});
    } catch (error) {
      console.error('Error fetching implied volatility data:', error);
      const telemetry = Telemetry.getInstance();
      telemetry.trackEvent('Options_Implied_Volatility_Fetch', {Stock_Ticker: ticker, Selected_Strike: selectedStrike, Success: false, Error: error});
    }
  };

  const columns: GridColDef[] = [
    { field: 'date', headerName: 'Date', width: 150 },
    { field: 'option_type', headerName: 'Option Type', width: 150 },
    { field: 'implied_vol', headerName: 'Implied Volatility', width: 200 },
  ];

  return (
    <div>
      <TrplPageHeading title={pageTitle} />
      <TrplPageHeadingFootnote title={pageSubheading} />
      <Box sx={{ flexDirection: 'row', display: 'flex', gap: '6px', mt: 4, mb: 0.5 }}>
        <TrplInputTextField
          label="STOCK TICKER"
          height="4.7vh"
          fontSize="0.9rem"
          value={ticker}
          onChange={(e) => setTicker(e.target.value)}
        />
        <TrplBasicButton label="ENTER" height="4.7vh" width="4vw" onClick={handleTickerSubmit} />
      </Box>
      {strikes.length > 0 && (
        <Box sx={{ flexDirection: 'row', display: 'flex', gap: '6px', mt: 2, mb: 0.5 }}>
          <TrplDropdownMenu
            options={strikes}
            onChange={(value: string) => setSelectedStrike(value)}
            height="4.7vh"
            width="8vw"
            endIcon={<KeyboardArrowDownIcon />}
          />
          <TrplBasicButton label="ENTER" height="4.7vh" width="4vw" onClick={handleStrikeSubmit} />
        </Box>
      )}
      <TrplPageInputText title={pageInputText} />
      <Box height="4vh" />
      {impliedVolData.length > 0 && (
        <TrplDataGrid
          columns={columns}
          rows={impliedVolData.map((row, index) => ({ id: index, ...row }))}
          pageSize={10}
          rowsPerPageOptions={[10]}
          autoHeight
          disableColumnMenu
          style={{ width: 500 }}
        />
      )}
      <Box height="4vh" />
    </div>
  );
};

export default ImpliedVolatility;