import { Typography } from '@mui/material';
import TrplTheme from '../../Theme';

interface TrplPageHeadingProps {
  title: string;
  fontSize?: string;
  color?: string;
  highlight?: boolean;
}

const TrplPageHeading: React.FC<TrplPageHeadingProps> = ({ title, fontSize, color, highlight, ...props }) => {
  const highlightStyles = highlight
    ? {
        backgroundColor: '#e1fcde',
        padding: '0 0px',
      }
    : {};

  return (
    <Typography
      variant="h5"
      sx={{
        fontFamily: 'RobotoMono',
        fontStyle: 'light',
        fontSize: fontSize,
      }}
      color={color ? color : TrplTheme.palette.secondary.light}
      {...props}
    >
      <span style={highlightStyles}>{title}</span>
    </Typography>
  );
};

export default TrplPageHeading;
