import { useImperativeHandle, forwardRef } from 'react';
import Telemetry from '../../../Telemetry';

interface FetchNewsSummaryParams {
    content: string;
    onNewsSummaryReceived: (newsData: string) => void;
}

export interface FetchNewsSummaryHandles {
  fetchNewsSummary: (params: FetchNewsSummaryParams) => void;
}

const FetchNewsSummary = forwardRef<FetchNewsSummaryHandles, {}>((props, ref) => {
    useImperativeHandle(ref, () => ({
        fetchNewsSummary({ content, onNewsSummaryReceived }: FetchNewsSummaryParams) {
        const backendUrl = `${process.env.REACT_APP_BACKEND}/news-summary/`;
  
        fetch(backendUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ content: content }),
        })
        .then(async response => {
          if (!response.ok) throw new Error('Network response was not ok');
          if (!response.body) throw new Error('ReadableStream not available');
  
          const reader = response.body.getReader();
          const decoder = new TextDecoder();
  
          while (true) {
            const { done, value } = await reader.read();
            if (done) break;
            const chunk = decoder.decode(value, {stream: true});
            onNewsSummaryReceived(chunk);
          }

          const telemetry = Telemetry.getInstance();
          telemetry.trackEvent('Card_News_Summary_Fetch', {Success: true});
        })
        .catch(error => {
          const telemetry = Telemetry.getInstance();
          telemetry.trackEvent('Card_News_Summary_Fetch', {Success: false, Error: error});
        });
      }
    }));

    
    return null;
  });
  
  export default FetchNewsSummary;  