import React, { useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Box, styled } from '@mui/material';

import TrplInputTextField from '../../../components/pages/InputTextField';
import TrplPageHeading from '../../../components/pages/PageHeading';
import TrplPageHeadingFootnote from '../../../components/pages/PageHeadingFootnote';
import TrplBasicButton from '../../../components/pages/BasicButton';
import TrplPageInputText from '../../../components/pages/PageInputText';
import TrplPageSubheading from '../../../components/pages/PageSubheading';
import Theme from '../../../Theme';
import LoadingErrorDisplay from '../../../components/pages/LoadingErrorDisplay';
import Telemetry from '../../../Telemetry';

const pageTitle = 'Bull Call Spread Pricing';
const pageSubheading = 'This model calculates the optimal bull call spread pricing for an asset while maximizing the risk-reward profile.';
const pageInputText = 'Enter the stock ticker, position size and target price to execute the model.';
const callTableText =  'This table contains the optimal call spread for the target price sorted by risk-reward profiles.';

const TrplDataGrid = styled(DataGrid)(({ theme }) => ({
    '& .MuiDataGrid-cell': {
      borderColor: theme.palette.divider,
      borderWidth: '.5px',
      borderStyle: 'solid',
      color: 'grey',
      fontFamily: 'RobotoMono', // Add your desired font here
      fontStyle: 'thin',
    },
    '& .MuiDataGrid-columnHeader': {
      borderColor: theme.palette.divider,
      borderWidth: '.5px',
      borderStyle: 'solid',
      fontFamily: 'RobotoMono', // Add your desired font here
      color: 'grey',
      fontStyle: 'normal',
      backgroundColor: "#eef7ed",
    },
    '& .MuiDataGrid-iconSeparator': {
      display: 'none',
    },
  }));

function BullCallSpread() {
  const [stockData, setStockData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<{ message: string; code?: number } | null>(null);

  const fetchData = async (stockTicker: string, positionSize: string, targetPrice: string) => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND}/bullspread-options/?stockTicker=${stockTicker}&positionSize=${positionSize}&targetPrice=${targetPrice}`);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      if (data.CallSpread) {
        const callOptionsWithIds = JSON.parse(data.CallSpread).map((option: any, index: number) => ({
          ...option,
          id: index,
        }));
        setStockData(callOptionsWithIds);
        const telemetry = Telemetry.getInstance();
      telemetry.trackEvent('Options_Spread_Call_Fetch', {Stock_Ticker: stockTicker, Position_Size: positionSize, Target_Price: targetPrice, Success: true});
      } else {
        throw new Error('Invalid data format');
      }
    } catch (error: any) {
      setError({ message: error.toString(), code: error.status });
      const telemetry = Telemetry.getInstance();
      telemetry.trackEvent('Options_Spread_Call_Fetch', {Stock_Ticker: stockTicker, Position_Size: positionSize, Target_Price: targetPrice, Success: false, Error: error.message});
    } finally {
      setIsLoading(false);
    }
  };

  const [tickerValue, setTickerValue] = useState('');
  const handleTickerChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTickerValue(event.target.value);
  };

  const [positionSize, setPositionSize] = useState('');
  const handleSizeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPositionSize(event.target.value);
  };

  const [targetPrice, setTargetPrice] = useState('');
  const handlePriceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTargetPrice(event.target.value);
  };

  const handleClick = () => {
    fetchData(tickerValue, positionSize, targetPrice);
  };

  return (
    <div>
      <TrplPageHeading title={pageTitle}/>
      <TrplPageHeadingFootnote title={pageSubheading} />
      <Box sx={{ flexDirection: 'row', display: 'flex', gap: '6px', mt: 4, mb: .5}}>
        <TrplInputTextField label='STOCK TICKER' height='4.7vh' fontSize='0.9rem' value={tickerValue} onChange={handleTickerChange} />
        <TrplInputTextField label='POSITION SIZE' height='4.7vh' width='8vw' fontSize='0.9rem' value={positionSize} onChange={handleSizeChange} />
        <TrplInputTextField label='TARGET PRICE' height='4.7vh' width='8vw' fontSize='0.9rem' value={targetPrice} onChange={handlePriceChange} />
        <TrplBasicButton label='ENTER' height='4.7vh' width='4vw' onClick={handleClick}/>
      </Box>
      <TrplPageInputText title={pageInputText}/>
      <Box height="4vh"/>

      {/* Use the LoadingErrorDisplay component */}
      <LoadingErrorDisplay isLoading={isLoading} error={error} />
      {!isLoading && !error && stockData.length > 0 && (
        <>
            <TrplPageSubheading title="Optimal Bull Call Spreads" fontSize="18px" color={Theme.palette.primary.dark} />
            <Box height={"1vh"}/>
            <TrplPageHeadingFootnote title={callTableText} />
            <Box height={"1vh"}/>
            <TrplDataGrid
            style={{ width: 1320, height: 630 }} // <- add a height here
            rows={stockData}
            columns={[
                { field: 'Option Date', headerName: 'DATE', width: 150 },
                { field: '# of Options', headerName: '# OPTIONS', width: 100 },
                { field: 'Buy at Strike', headerName: 'BUY STRIKE', width: 100 },
                { field: 'Buy Price', headerName: 'BUY PRICE', width: 100 },
                { field: 'Cost of Buy', headerName: 'BUY COST', width: 100 },
                { field: 'Sell at Strike', headerName: 'SELL STRIKE', width: 110 },
                { field: 'Sell Price', headerName: 'SELL PRICE', width: 100 },
                { field: 'Cost of Sell', headerName: 'SELL COST', width: 100 },
                { field: 'Max Loss', headerName: 'MAX LOSS', width: 90 },
                { field: 'Max Profit', headerName: 'MAX PROFIT', width: 120 },
                { field: 'Risk-Reward', headerName: 'RISK-REWARD', width: 130 },
                { field: 'Max Price', headerName: 'MAX PRICE', width: 120 },
                { field: 'Scaled Vol', headerName: 'VOLATILITY', width: 120 },
            ]}
            pageSize={10}
            rowsPerPageOptions={[10]}
            checkboxSelection={false}
            />
            <Box height={"10vh"}/>
        </>
      )}
    </div>
  );
}

export default BullCallSpread;

