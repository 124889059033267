import List from '@mui/material/List';

import React from 'react';
import { TrplMenuMap } from '../constants/AppMenuConstants';

import { MenuHeadingItem, MenuType } from './MenuInterface';
import TrplMenuListItem from './MenuListItem';

export default function TrplAppMenu(props: { selectedMenuType: MenuType }) {
    const selectedMenu = TrplMenuMap[props.selectedMenuType];

    return (
        <List>
            {selectedMenu.menuList.map((menu : MenuHeadingItem, index) => (
                <TrplMenuListItem key={index} menu={menu} />
            ))}
        </List>
    );
}
