import React, { useState, useEffect } from 'react';
import { GridColDef } from '@mui/x-data-grid';
import { Box, Fade } from '@mui/material';
import TrplPageHeading from '../../../components/pages/PageHeading';
import TrplPageHeadingFootnote from '../../../components/pages/PageHeadingFootnote';
import TrplBasicButton from '../../../components/pages/BasicButton';
import TrplPageInputText from '../../../components/pages/PageInputText';
import TrplInputTextField from '../../../components/pages/InputTextField';
import TrplPageSubheading from '../../../components/pages/PageSubheading';
import TrplPageMainText from '../../../components/pages/PageMainText';

import {
    TrplDataGrid,
    columnsAnnual,
    columnsQuarterly,
    columnsPeer,
    pageTitle,
    pageSubheading,
    pageInputText,
    annualDataText,
    quarterlyDataText,
    peerDataText,
    GPTResponseData,
} from './StockGPTConstants';

import HistoricalVolatilityTable from './StockGPTHistoricalVolatility';
import StockGPTPerformance from './StockGPTPricePerformance';
import ImpliedVolatilityTable from './StockGPTImpliedVolatility';
import Telemetry from '../../../Telemetry';

export default function StockFundamentals() {
    const [inputValue, setInputValue] = useState('');
    const [responseData, setResponseData] = useState<GPTResponseData | null>(null);
    const [socket, setSocket] = useState<WebSocket | null>(null);

    useEffect(() => {
        const newSocket = new WebSocket(`${process.env.REACT_APP_WEBSOCKET}/stock-gpt/`);
        setSocket(newSocket);

        newSocket.onopen = () => {
            console.log('WebSocket connection established');
            const telemetry = Telemetry.getInstance();
            telemetry.trackEvent('AI_Stock_GPT_Start', {Stock_Ticker: inputValue, Success: true});
        };

        newSocket.onclose = (event) => {
            console.log('WebSocket connection closed:', event);
            const telemetry = Telemetry.getInstance();
            telemetry.trackEvent('AI_Stock_GPT_End', {Stock_Ticker: inputValue, Success: true});
        };

        newSocket.onmessage = (event) => {
            const data = JSON.parse(event.data);
            
            setResponseData((prevData) => {
                const updatedData: GPTResponseData = {
                    name: data.name || prevData?.name || "",
                    description: (prevData?.description || "") + (data.description || ""),
                    annual_fundamentals: data.annual_fundamentals
                        ? data.annual_fundamentals.map((item: any, index: number) => ({
                                ...item,
                                id: `annual-${index}`,
                            }))
                        : prevData?.annual_fundamentals || [],
                    annual_data_interpretation: (prevData?.annual_data_interpretation || "") + (data.annual_data_interpretation || ""),
                    quarterly_fundamentals: data.quarterly_fundamentals
                        ? data.quarterly_fundamentals.map((item: any, index: number) => ({
                                ...item,
                                id: `quarter-${index}`,
                            }))
                        : prevData?.quarterly_fundamentals || [],
                    quarterly_data_interpretation: (prevData?.quarterly_data_interpretation || "") + (data.quarterly_data_interpretation || ""),
                    peer_fundamentals: data.peer_fundamentals
                        ? data.peer_fundamentals.map((item: any, index: number) => ({
                                ...item,
                                id: `peer-${index}`,
                            }))
                        : prevData?.peer_fundamentals || [],
                    peer_data_interpretation: (prevData?.peer_data_interpretation || "") + (data.peer_data_interpretation || ""),
                    earnings_transcript: (prevData?.earnings_transcript || "") + (data.earnings_transcript || ""),
                    news_summary: (prevData?.news_summary || "") + (data.news_summary || ""),
                    sigma_distribution: data.sigma_distribution ? data.sigma_distribution : prevData?.sigma_distribution || [],
                    sigma_distribution_interpretation: (prevData?.sigma_distribution_interpretation || "") + (data.sigma_distribution_interpretation || ""),
                    stock_data: data.stock_data || prevData?.stock_data || [],
                    performance: data.performance || prevData?.performance || [],
                    implied_volatilities: data.implied_volatilities || prevData?.implied_volatilities || [],
                };
        
                return updatedData;
            });
        };

        newSocket.onclose = () => {
            console.log('WebSocket connection closed');
            const telemetry = Telemetry.getInstance();
            telemetry.trackEvent('AI_Stock_GPT_End', {Stock_Ticker: inputValue, Success: true});
        };

        return () => {
            newSocket.close();
            const telemetry = Telemetry.getInstance();
            telemetry.trackEvent('AI_Stock_GPT_End', {Stock_Ticker: inputValue, Success: true});
        };
    }, []);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(event.target.value);
    };

    const handleClick = () => {
        setResponseData(null);
        if (inputValue && socket) {
            socket.send(JSON.stringify({ ticker: inputValue }));
        }
    };

    const calculateTotalWidth = (columns: GridColDef[]): number => {
        return columns.reduce((accumulator, column) => accumulator + (column.width ?? 0), 0);
    };

    return (
        <div>
            <TrplPageHeading title={pageTitle} />
            <TrplPageHeadingFootnote title={pageSubheading} />
            <Box sx={{ flexDirection: 'row', display: 'flex', gap: '6px', mt: 4, mb: 0.5 }}>
                <TrplInputTextField label='STOCK TICKER' height='4.7vh' fontSize='0.9rem' value={inputValue} onChange={handleInputChange} />
                <TrplBasicButton label='ENTER' height='4.7vh' width='4vw' onClick={handleClick} />
            </Box>
            <TrplPageInputText title={pageInputText} />
            {responseData && (
                <>
                    <Box height='2vh' />
                    <TrplPageSubheading title={responseData.name} />
                    <Box height='1vh' />
                    <TrplPageSubheading title="Business Description" fontSize='18px'/>
                    <TrplPageMainText text={responseData.description} />

                    {responseData.stock_data && responseData.stock_data.length > 0 && (
                        <Fade in={true} timeout={1000}>
                            <div>
                                <TrplPageSubheading title="Price Data" fontSize='20px'/>
                                <Box height='2vh' />
                                <StockGPTPerformance
                                    stockData={responseData.stock_data}
                                    performance={responseData.performance}
                                    currentPrice={responseData.stock_data[responseData.stock_data.length - 1].Close}
                                />
                            </div>
                        </Fade>
                    )}

                    {responseData.annual_fundamentals.length > 0 && (
                        <Fade in={true} timeout={1000}>
                            <div>
                                <Box height='2vh' />
                                <TrplPageSubheading title="Annual Fundamentals" fontSize='18px'/>
                                <Box height='1vh' />
                                <TrplPageHeadingFootnote title={annualDataText} />
                                <Box height='2vh' />
                                <TrplDataGrid rows={responseData.annual_fundamentals} columns={columnsAnnual} style={{ width: calculateTotalWidth(columnsAnnual) }} autoHeight hideFooter />
                                <Box height='2vh' />
                                <TrplPageSubheading title="Comments" fontSize='16px'/>
                                <TrplPageMainText text={responseData.annual_data_interpretation} />
                            </div>
                        </Fade>
                    )}

                    {responseData.quarterly_fundamentals.length > 0 && (
                        <Fade in={true} timeout={1000}>
                            <div>
                                <Box height='2vh' />
                                <TrplPageSubheading title="Quarterly Fundamentals" fontSize='18px'/>
                                <Box height='1vh' />
                                <TrplPageHeadingFootnote title={quarterlyDataText} />
                                <Box height='2vh' />
                                <TrplDataGrid rows={responseData.quarterly_fundamentals} columns={columnsQuarterly} style={{ width: calculateTotalWidth(columnsQuarterly) }} autoHeight hideFooter />
                                <Box height='2vh' />
                                <TrplPageSubheading title="Comments" fontSize='16px'/>
                                <TrplPageMainText text={responseData.quarterly_data_interpretation} />
                            </div>
                        </Fade>
                    )}

                    {responseData.peer_fundamentals.length > 0 && (
                        <Fade in={true} timeout={1000}>
                            <div>
                                <Box height='2vh' />
                                <TrplPageSubheading title="Peer Fundamentals" fontSize='18px'/>
                                <Box height='1vh' />
                                <TrplPageHeadingFootnote title={peerDataText} />
                                <Box height='2vh' />
                                <TrplDataGrid rows={responseData.peer_fundamentals} columns={columnsPeer} style={{ width: 1320 }} autoHeight hideFooter />
                                <Box height='2vh' />
                                <TrplPageSubheading title="Comments" fontSize='16px'/>
                                <TrplPageMainText text={responseData.peer_data_interpretation} />
                            </div>
                        </Fade>
                    )}

                    {responseData.earnings_transcript && (
                        <Fade in={true} timeout={1000}>
                            <div>
                                <Box height='2vh' />
                                <TrplPageSubheading title="Qualitative Metrics" fontSize='18px'/>
                                <Box height='1vh' />
                                <TrplPageMainText text={responseData.earnings_transcript} />
                            </div>
                        </Fade>
                    )}

                    {responseData.news_summary && (
                        <Fade in={true} timeout={1000}>
                            <div>
                                <Box height='2vh' />
                                <TrplPageSubheading title="Recent Stock News" fontSize='18px'/>
                                <Box height='1vh' />
                                <TrplPageMainText text={responseData.news_summary} />
                            </div>
                        </Fade>
                    )}

                    {responseData.sigma_distribution && responseData.sigma_distribution.length > 0 &&(
                        <Fade in={true} timeout={1000}>
                            <div>
                                <Box height='2vh' />
                                <TrplPageSubheading title="1 Month Historical Volatility" fontSize='18px'/>
                                <Box height='1vh' />
                                <HistoricalVolatilityTable sigmaDistribution={responseData.sigma_distribution} />
                                <Box height='2vh' />
                                <TrplPageSubheading title="Comments" fontSize='16px'/>
                                <TrplPageMainText text={responseData.sigma_distribution_interpretation} />
                            </div>
                        </Fade>
                    )}

                    {responseData.implied_volatilities && responseData.implied_volatilities.length > 0 && (
                        <Fade in={true} timeout={1000}>
                            <div>
                                <Box height='2vh' />
                                <TrplPageSubheading title="Implied Volatility" fontSize='18px' />
                                <Box height='1vh' />
                                <ImpliedVolatilityTable impliedVolatilities={responseData.implied_volatilities} />
                            </div>
                        </Fade>
                    )}

                    <Box height='6vh' />
                </>   
            )}
        </div>
    );
}