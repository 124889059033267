import { styled } from '@mui/material/styles';
import TrplTheme from '../../Theme';
import { Card, CardContent, Typography } from '@mui/material';

interface TrplCardProps {
  id?: string;
  onClick?: () => void;
  title?: string;
  fontSize?: string;
  padding?: string;
  transform?: string;
  children?: React.ReactNode;
}

export default function TrplCard({
  id,
  onClick,
  title,
  fontSize,
  padding,
  transform,
  children,
}: TrplCardProps) {
  const StyledCard = styled(Card)({
    '&:hover': {
      color: TrplTheme.palette.secondary.main,
    },

    color: TrplTheme.palette.primary.main,
    fontSize: fontSize,
    padding: padding,
    transform: transform,
    elevation: 0,
    border: '.5px solid grey',
    borderRadius: '0px',
    width: 350, // set fixed width
    height: 220, // set fixed height
  });

  const StyledCardContent = styled(CardContent)({
    width: 350, // set fixed width
    height: 220, // set fixed height
  });

  return (
    <StyledCard id={id} onClick={onClick}>
      <StyledCardContent>
        <Typography variant="h5" component="div">
          {title}
        </Typography>
        {children}
      </StyledCardContent>
    </StyledCard>
  );
}
