import {  Box, Divider, Drawer, Paper } from '@mui/material';
import TrplAppMenu from './AppMenu';

import TrplPositionedMenu from './PositionedMenu';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { MenuType } from './MenuInterface';
import React from 'react';
import Avatar from '@mui/material/Avatar';

import Logo from '../PrismLogo.png'

export default function TrplAppDrawer(props: { selectedMenuType: MenuType })
{
    const [selectedMenuType, setSelectedMenuType] = React.useState<MenuType>(MenuType.Dashboard);

    return (
        <Drawer variant="permanent" sx={{ width: '300', '& .MuiDrawer-paper':{ width: '16vw', boxSizing: 'border-box' }}}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '8vh', width: '16vw'}}>
                <Avatar src={Logo} sx={{ width: 60, height: 60}} />
            </Box>
            <Divider/>
            <Paper sx={{overflowY: 'auto', height: 'calc(92vh)', width: '16vw'}} elevation={0}>
                <TrplAppMenu selectedMenuType={selectedMenuType} />
            </Paper>
        </Drawer>
    );
}