// PortfolioAnalysisChart.tsx
import React, { useEffect, useRef, useState } from 'react';
import { Box } from '@mui/material';
import TrplDropdownMenu from '../../../components/pages/DropdownMenu';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { LineChart } from '../../../components/pages/LineChart';

interface PortfolioAnalysisChartProps {
  chartType: 'high' | 'low';
  pricesData: { [key: string]: string | number }[];
  pricesPLData: { [key: string]: string | number }[];
  pricesPositionPLData: { [key: string]: string | number }[];
}

const PortfolioAnalysisChart: React.FC<PortfolioAnalysisChartProps> = ({
  chartType,
  pricesData,
  pricesPLData,
  pricesPositionPLData,
}) => {
  const [selectedOption, setSelectedOption] = useState(
    chartType === 'high' ? 'HIGH PRICES' : 'LOW PRICES'
  );
  const chartRef = useRef<HTMLDivElement | null>(null);

  const handleDropdownChange = (value: string) => {
    setSelectedOption(value);
  };

  const getChartData = () => {
    switch (selectedOption) {
      case 'HIGH PRICES':
      case 'LOW PRICES':
        return pricesData;
      case 'HIGH PRICES POSITION P&L':
      case 'LOW PRICES POSITION P&L':
        return pricesPositionPLData;
      case 'HIGH PRICES P&L':
      case 'LOW PRICES P&L':
        return pricesPLData;
      default:
        return pricesData;
    }
  };

  const dropdownOptions =
    chartType === 'high'
      ? ['HIGH PRICES', 'HIGH PRICES POSITION P&L', 'HIGH PRICES P&L']
      : ['LOW PRICES', 'LOW PRICES POSITION P&L', 'LOW PRICES P&L'];

  return (
    <>
      <Box height="2vh" />
      <TrplDropdownMenu
        options={dropdownOptions}
        onChange={handleDropdownChange}
        height="4.7vh"
        width="15vw"
        endIcon={<KeyboardArrowDownIcon />}
      />
      <Box height="1vh" />
      <div ref={chartRef}>
        <LineChart
        data={getChartData().map((item) => {
          const convertedItem: { [key: string]: number } = {};
          for (const key in item) {
           
            if (key !== 'Date' && key !== 'Time Period' && key !== 'Week(s)') {
              convertedItem[key] = Number(item[key]);
            } else {
              convertedItem[key] = item[key] as number;
            }
          }
          return convertedItem;
        })}
      />
      </div>
    </>
  );
};

export default PortfolioAnalysisChart;