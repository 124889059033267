import React, { useImperativeHandle, forwardRef } from 'react';

interface FetchGuidanceParams {
  earningsTranscript: string;
  onEarningsGuidanceReceived: (kpiData: string) => void;
}

export interface FetchGudianceHandles {
  fetchGuidance: (params: FetchGuidanceParams) => void;
}

const FetchEarningsGuidance = forwardRef<FetchGudianceHandles, {}>((props, ref) => {
    useImperativeHandle(ref, () => ({
        fetchGuidance({ earningsTranscript, onEarningsGuidanceReceived }: FetchGuidanceParams) {
        const backendUrl = `${process.env.REACT_APP_BACKEND}/earnings-fwd-guidance/`;
  
        fetch(backendUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ earnings: earningsTranscript }),
        })
        .then(async response => {
          if (!response.ok) throw new Error('Network response was not ok');
          if (!response.body) throw new Error('ReadableStream not available');
  
          const reader = response.body.getReader();
          const decoder = new TextDecoder();
  
          while (true) {
            const { done, value } = await reader.read();
            if (done) break;
            const chunk = decoder.decode(value, {stream: true});
            onEarningsGuidanceReceived(chunk); // Pass each chunk to the parent component
          }
  
        })
        .catch(error => {
          console.error('Error fetching KPI data:', error);
        });
      }
    }));
  
    return null; // This component does not render anything itself
  });
  
  export default FetchEarningsGuidance;  