import React from 'react';

import { DataGrid, GridColDef } from '@mui/x-data-grid';

import { Box, styled } from '@mui/material';

import TrplPageHeadingFootnote from '../../../components/pages/PageHeadingFootnote';

import Theme from '../../../Theme';
import TrplPageSubheading from '../../../components/pages/PageSubheading';


interface AtrDistribution {
  time_label: string;
  time_intervals: number;
  atr: number;
}

interface AtrResponse {
ticker_name: string;
atr_distribution: AtrDistribution[];
}

interface AtrDataTableProps {
  atrResponse: AtrResponse;
}

const buildAtrDistributionColumns = (): GridColDef[] => [
    { field: 'time_intervals', headerName: 'Intervals', width: 150 },
    { field: 'time_label', headerName: 'Horizon', width: 150 },
    { field: 'atr', headerName: 'Average True Range', width: 200 },
  ];  
  
  const TrplDataGrid = styled(DataGrid)(({ theme }) => ({   
    '& .MuiDataGrid-cell': {
      borderColor: theme.palette.divider,
      borderWidth: '.5px',
      borderStyle: 'solid',
      color: 'grey',
      fontFamily: 'RobotoMono', // Add your desired font here
      fontStyle: 'thin',
    },
    '& .MuiDataGrid-columnHeader': {
      borderColor: theme.palette.divider,
      borderWidth: '.5px',
      borderStyle: 'solid',
      fontFamily: 'RobotoMono', // Add your desired font here
      color: 'grey',
      fontStyle: 'normal',
      backgroundColor: "#eef7ed",
    },
    '& .MuiDataGrid-iconSeparator': {
        display: 'none',
      },
  }));
  
  const atrTableText =  'This table contains the range of the asset for various periods associated with the selected time interval.';


const AtrDataTable: React.FC<AtrDataTableProps> = ({ atrResponse }) => {    
    const calculateTotalWidth = (columns: GridColDef[]): number => {
        return columns.reduce((accumulator, column) => accumulator + (column.width as number), 0);
      };
    
    const atrDistibutionCols = buildAtrDistributionColumns();
    const atrDistributionWidth = calculateTotalWidth(atrDistibutionCols);
    console.log(atrResponse.atr_distribution);
    
    return (
        <div >
          <TrplPageSubheading title="True Range Table" fontSize="18px" color={Theme.palette.primary.dark} />
          <Box height={"1vh"}/>
          <TrplPageHeadingFootnote title={atrTableText} />
          <Box height={"1vh"}/>
          <TrplDataGrid
                style={{ width: atrDistributionWidth }}
                columns={atrDistibutionCols}
                rows={atrResponse.atr_distribution.map((row, index) => ({ id: index, ...row }))}
                pageSize={10}
                rowsPerPageOptions={[10]}
                autoHeight
                disableColumnMenu
                hideFooter
            />
            <Box height={"6vh"}/>
        </div>
      );
      
};

export default AtrDataTable;
