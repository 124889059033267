import React from 'react';
import { EChartsOption } from 'echarts';
import ReactECharts from 'echarts-for-react';
import Theme from '../../../Theme';

interface TrplIVChartProps {
  currentPrice: number;
  highPrices: number[];
  lowPrices: number[];
}

const TrplIVChart: React.FC<TrplIVChartProps> = ({ currentPrice, highPrices, lowPrices }) => {
  const weeks = Array.from({ length: 12 }, (_, i) => i+1);
  const currentPrices = weeks.map(() => currentPrice);

  const roundToNearestMultiple = (value: number, multiple: number, high: boolean) => {
    var roundedNumber = Math.round(value / multiple) * multiple;
    return high ? roundedNumber + multiple : roundedNumber - multiple;
  };

  const options: EChartsOption = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
      },
      textStyle: {
        fontFamily: 'ChivoMono',
        fontSize: 14,
      },
      formatter: function (params: any) {
        const week = params[0].axisValue;
        const highPrice = params[0].value;
        const currentPrice = params[1].value;
        const lowPrice = params[2].value;
        return `Week ${week}<br />High Price: ${highPrice.toFixed(2)}<br />Current Price: ${currentPrice.toFixed(2)}<br />Low Price: ${lowPrice.toFixed(2)}`;
      },
    },
    grid: {
      left: '6%',
      right: '4%',
      bottom: '10%',
      containLabel: true,
    },
    xAxis: {
      type: 'category',
      data: weeks.map(String),
      name: 'Week',
      nameLocation: 'middle',
      nameGap: 60,
      nameTextStyle: {
        fontFamily: 'ChivoMono',
        fontSize: 16,
      },
      axisLabel: {
        fontFamily: 'ChivoMono',
        fontSize: 13,
        formatter: (value: string) => `Week ${value}`,
        rotate: 0,
      },
    },
    yAxis: {
      type: 'value',
      name: 'Price',
      nameLocation: 'middle',
      nameGap: 60,
      nameTextStyle: {
        fontFamily: 'ChivoMono',
        fontSize: 16,
      },
      axisLabel: {
        fontFamily: 'ChivoMono',
        fontSize: 13,
        rotate: 0,
      },
      min: roundToNearestMultiple(Math.min(...lowPrices), 10, false),
      max: roundToNearestMultiple(Math.max(...highPrices), 10, true),
    },
    series: [
      {
        name: 'High Price',
        type: 'line',
        data: highPrices,
        itemStyle: {
          color: Theme.palette.secondary.light,
        },
        lineStyle: {
          opacity: 1,
        },
        emphasis: {
          focus: 'series',
        },
      },
      {
        name: 'Current Price',
        type: 'line',
        data: currentPrices,
        itemStyle: {
          color: Theme.palette.primary.main,
        },
        lineStyle: {
          opacity: 1,
        },
        emphasis: {
          focus: 'series',
        },
      },
      {
        name: 'Low Price',
        type: 'line',
        data: lowPrices,
        itemStyle: {
          color: "#c86464",
        },
        lineStyle: {
          opacity: 1,
        },
        emphasis: {
          focus: 'series',
        },
      },
    ],
  };

  return (
    <div style={{ height: '550px', width: '100%' }}>
      <ReactECharts option={options} style={{ height: '100%' }} />
    </div>
  );
};

export default TrplIVChart;