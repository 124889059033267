import { CssBaseline, Divider, Paper } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import React from 'react';
import Box from '@mui/material/Box';
import { Route, Routes } from 'react-router-dom';
import TrplTheme from './Theme';
import TrplTextButton from './components/TextButton';
import TrplAppDrawer from './components/AppDrawer';
import { MenuType } from './components/MenuInterface';
import HistoricalVolatility from './pages/dashboard/volatility/HistoricalVolatility';
import ATRVolatility from './pages/dashboard/volatility/ATRVolatility';
import PresidentialCycle from './pages/dashboard/cycles/PresidentialCycle';
import SectorCycle from './pages/dashboard/cycles/SectorCycle';
import StockSentiment from './pages/dashboard/sentiment/StockSentiment';
import Correlation from './pages/dashboard/portfolio/Correlation';
import Volatility from './pages/dashboard/portfolio/Volatility';
import TrplPerformance from './pages/dashboard/portfolio/Performance';
import StockCycle from './pages/dashboard/cycles/StockCycle';
import CallOption from './pages/dashboard/options/CallOption';
import PutOption from './pages/dashboard/options/PutOption';
import BearPutSpread from './pages/dashboard/options/BearPutSpread';
import BullCallSpread from './pages/dashboard/options/BullCallSpread';
import SupportResistance from './pages/dashboard/technicals/SupportResistance';
import LevelBreakouts from './pages/dashboard/technicals/LevelBreakouts';
import TrendBreakouts from './pages/dashboard/technicals/TrendBreakouts';
import StockFundamentals from './pages/dashboard/fundamentals/Stock';
import LongStocks from './pages/dashboard/fundamentals/Longs';
import ShortStocks from './pages/dashboard/fundamentals/Shorts';
import Breakouts from './pages/dashboard/fundamentals/Breakouts';
import BullCalendarSpread from './pages/dashboard/options/BullCalendarSpread';
import BearCalendarSpread from './pages/dashboard/options/BearCalendarSpread';
import StreamEarningsSummary from './pages/dashboard/ai/StreamEarningsSummary';
import StockGPT from './pages/dashboard/ai/StockGPT';
import ImpliedVolatility from './pages/dashboard/volatility/ImpliedVolatility';
import MarketVolatility from './pages/dashboard/volatility/MarketVolatility';
import StockVolatility from './pages/dashboard/volatility/StockVolatility';
import OptionsCalculator from './pages/dashboard/options/OptionsCalculator';
import HomePage from './HomePage';
import VolatilityNews from './pages/dashboard/sentiment/VolatilityNews';
import MarketNewsSummary from './pages/dashboard/sentiment/MarketNewsSummary';
import PortfolioGPT from './pages/dashboard/ai/PortfolioGPT';
import TickerNewsSummary from './pages/dashboard/sentiment/TickerNewsSummary';
import Telemetry from './Telemetry';

export default function Main() {
  const signOut = 'Sign Out';

  const handleLogout = () => {
    localStorage.removeItem('PrismFI_isAuthenticated');
        const telemetry = Telemetry.getInstance();
        telemetry.trackEvent('App_Logout');
    window.location.href = '/';
  };

  const [selectedMenuType] = React.useState<MenuType>(MenuType.Dashboard);

  return (
    <ThemeProvider theme={TrplTheme}>
      <CssBaseline />
      <Box sx={{ display: 'flex', height: '8vh' }}>
        <Box sx={{ flexGrow: 1 }} />
        <TrplTextButton label={signOut} color={TrplTheme.palette.primary.dark} fontSize='18px' fontFamily="RamaGothic" disableRipple={true} mx={25} onClick={handleLogout}/>
        <Box width="2vw"/>
      </Box>
      <Divider />
        <Box sx={{ display: 'flex', height: '92vh' }}>
          <TrplAppDrawer selectedMenuType={selectedMenuType} />
          <Box sx={{ flexGrow: 1, marginLeft: '16vw' }}>
            <Paper sx={{ overflowY: 'scroll', height: '100%', pl: '4vw', pt: '4vh' }} elevation={0}>
              <Routes>
                <Route path="/*" element={<HomePage />} />
                <Route path="/historical_volatility" element={<HistoricalVolatility />} />
                <Route path="/atr_volatility" element={<ATRVolatility />} />
                <Route path="/presidential_cycle" element={<PresidentialCycle />} />
                <Route path="/sector_cycle" element={<SectorCycle />} />
                <Route path="/stock_cycle" element={<StockCycle />} />
                <Route path="/portfolio_correlation" element={<Correlation />} />
                <Route path="/portfolio_performance" element={<TrplPerformance />} />
                <Route path="/portfolio_volatility" element={<Volatility />} />
                <Route path="/stock_sentiment" element={<StockSentiment />} />
                <Route path="/call_option" element={<CallOption />} />
                <Route path="/put_option" element={<PutOption />} />
                <Route path="/bear_spread_option" element={<BearPutSpread />} />
                <Route path="/bull_spread_option" element={<BullCallSpread />} />
                <Route path="/technicals_support_resistance" element={<SupportResistance />} />
                <Route path="/technicals_breakouts" element={<LevelBreakouts />} />
                <Route path="/technicals_trendline_breakouts" element={<TrendBreakouts />} />
                <Route path="/stock_fundamentals" element={<StockFundamentals />} />
                <Route path="/stock_longs" element={<LongStocks />} />
                <Route path="/stock_shorts" element={<ShortStocks />} />
                <Route path="/stock_breakouts" element={<Breakouts />} />
                <Route path="/calendar_bull_spread_option" element={<BullCalendarSpread />} />
                <Route path="/calendar_bear_spread_option" element={<BearCalendarSpread />} />
                <Route path="/earnings_summary" element={<StreamEarningsSummary />} />
                <Route path="/stock_gpt" element={<StockGPT />} />
                <Route path="/portfolio_gpt" element={<PortfolioGPT />} />
                <Route path="/implied_vol" element={<ImpliedVolatility />} />
                <Route path="/market_vol" element={<MarketVolatility />} />
                <Route path="/stock_vol" element={<StockVolatility />} />
                <Route path="/calculator_option" element={<OptionsCalculator />} />
                <Route path="/volatility_news" element={<VolatilityNews />} />
                <Route path="/market_news" element={<MarketNewsSummary />} />
                <Route path="/stock_news" element={<TickerNewsSummary />} />
              </Routes>
            </Paper>
          </Box>
        </Box>
    </ThemeProvider>
  );
}
