import List from '@mui/material/List';
import { Collapse, ListItemButton, ListItemText, styled } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import React from 'react';
import TrplTheme from '../Theme';
import { useNavigate } from 'react-router-dom';

import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { MenuHeadingItem } from './MenuInterface';

interface TrplMenuListItemProps {
  menu: MenuHeadingItem;
}

export default function TrplMenuListItem({ menu }: TrplMenuListItemProps) {
  const [open, setOpen] = React.useState(false);
  const handleClick = () => {
    setOpen(!open);
  };

  const navigate = useNavigate(); // Hook for navigation
  // Handler for navigating to a child item's link
  const handleNavigate = (link: string) => {
    navigate(link);
  };
  const StyledListButton = styled(ListItemButton)({
    color: TrplTheme.palette.primary.dark,
    height: '40px',
  });

  const StyledListItemText = styled(ListItemText)({
    '& .MuiTypography-root': {
      fontSize: '14px',
    },
  });

  const StyledExpandIcon = styled(ExpandMore)({
    color: TrplTheme.palette.primary.dark,
    fontSize: '20px',
  });

  const MenuRoot = (
    <StyledListButton onClick={handleClick} disableRipple>
      <StyledListItemText primary={menu.name} />
      {
        open ? ( <StyledExpandIcon as={KeyboardArrowDownIcon} />) : (<StyledExpandIcon as={KeyboardArrowRightIcon} />)
      }
    </StyledListButton>
  );

  const MenuChildren = (
    <Collapse in={open} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        {menu.items.map((item) => (
          <StyledListButton key={item.name} sx={{ pl: 4 }} onClick={() => handleNavigate(item.link)}>
            <StyledListItemText primary={item.name} />
          </StyledListButton>
        ))}
      </List>
    </Collapse>
  );
  
  return (
    <div>
      {MenuRoot}
      {MenuChildren}
    </div>
  );
}