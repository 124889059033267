import { createTheme } from "@mui/material/styles";
import './Fonts.css';

let TrplTheme = createTheme({
    typography: {
      fontFamily: 'VCRMono, RobotoMono, DharmaGothic, RamaGothic',
      },
      palette: {
        primary: {
          main: '#969696',
          light: '#eeeeee',
          dark: '#858585',
        },
        secondary: {
          main: '#64c864',
        }
      },
    });

export default TrplTheme;