import React, { useState } from 'react';

import TrplPageHeading from '../../../components/pages/PageHeading';
import TrplPageHeadingFootnote from '../../../components/pages/PageHeadingFootnote';
import TrplBasicButton from '../../../components/pages/BasicButton';
import TrplPageInputText from '../../../components/pages/PageInputText';
import TrplInputTextField from '../../../components/pages/InputTextField';
import { Box } from '@mui/material';
import BreakoutChart from '../../../components/pages/BreakoutChart';
import TrplPageSubheading from '../../../components/pages/PageSubheading';
import Theme from '../../../Theme';
import Telemetry from '../../../Telemetry';

const pageTitle = 'Level Breakouts';
const pageSubheading = 'This model obtains the recent breakouts for a stock.';
const pageInputText = 'Enter a ticker and press enter to execute the model.';
const breakoutChartText = 'The chart below shows the recent breakouts for the stock.';

interface IDataFrame {
    Date: string;
    Open: number;
    High: number;
    Low: number;
    Close: number;
    Breakouts: number;
    Adjusted_close: number;
}

interface IPriceData {
    Date: string;
    Open: number;
    High: number;
    Low: number;
    Close: number;
    Breakouts: number;
  }

interface IData {
    PriceData: IDataFrame[];
    Levels: number[];
}

export default function LevelBreakouts() {
    const [data, setData] = useState<IData | null>(null);
    const [inputValue, setInputValue] = useState('');

    const [levels, setLevels] = useState<number[]>([]); 

    const [priceData, setPriceData] = useState<IPriceData[]>([]); 

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(event.target.value);
    };

    const fetchData = (ticker: string) => {
        fetch(`${process.env.REACT_APP_BACKEND}/breakout-levels/?stockTicker=${ticker}`)
            .then(response => response.json())
            .then(data => {
                setData(data);
                const newPriceData: IPriceData[] = data.PriceData.map((item: IDataFrame) => ({
                    date: item.Date,
                    Open: item.Open,
                    High: item.High,
                    Low: item.Low,
                    Close: item.Close,
                }));
                
                setPriceData(newPriceData);
                setLevels(data.Levels);
                const telemetry = Telemetry.getInstance();
                telemetry.trackEvent('Level_Breakouts_Fetch', {Stock_Ticker: ticker, Success: true});
            })
            .catch(error => {
                const telemetry = Telemetry.getInstance();
                telemetry.trackEvent('Level_Breakouts_Fetch', { Stock_Ticker: ticker, Success: false, Error: error});
            });
    };
    
    const handleClick = () => {
        fetchData(inputValue);
    };

    return (
        <div>
            <TrplPageHeading title={pageTitle}/>
            <TrplPageHeadingFootnote title={pageSubheading} />
            <Box sx={{ flexDirection: 'row', display: 'flex', gap: '6px', mt: 4, mb: .5}}>
                <TrplInputTextField label='STOCK TICKER' height='4.7vh' fontSize='0.9rem' value={inputValue} onChange={handleInputChange} />
                <TrplBasicButton label='ENTER' height='4.7vh' width='4vw' onClick={handleClick}/>
            </Box>
            <TrplPageInputText title={pageInputText}/>
            <Box height="2vh"/>
            {data && (
                <div>
                    <TrplPageSubheading title="Level Breakout Chart" fontSize="18px" color={Theme.palette.primary.dark} />
                    <TrplPageHeadingFootnote title={breakoutChartText} />
                    <div style={{width: '100%' }}>
                        <Box height={"1vh"}/>

                        <Box height={"1vh"}/>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            <BreakoutChart data={data.PriceData} levels={levels}/>
                        </Box>
                    </div>
               </div>
            )}
        </div>
    );
}