import React, { useEffect, useState } from "react";
import { Box, Container, Typography, Slide, Avatar, Link } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import TrplTheme from "./Theme";
import TrplTitleTextButton from "./components/TitleTextButton";
import Header from './LearnMoreHeader';

import Logo from "./PrismLogo.png";
import StockGPTImage from "./images/SGI.png";
import PortfolioGPTImage from "./images/PGI.png";
import NewsImage from "./images/NMI.png";
import OptionsImage from "./images/OI.png";
import EarningsImage from "./images/EI.png";
import VolatilityImage from "./images/VI.png";
import CyclesImage from "./images/CI.png";
import { useNavigate } from "react-router-dom";

interface LearnMoreProps {
    handleBack: () => void;
    handleLogin: () => void;
  }
  

const LearnMore: React.FC<LearnMoreProps> = ({ handleBack, handleLogin }) => {
  const [showContent, setShowContent] = useState(false);
  const navigate = useNavigate();

  const handleBackClick = () => {
    handleBack();
    navigate('/');
  };

  useEffect(() => {
    setShowContent(true);
  }, []);

  const scrollToSection = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      const yOffset = -100; // Adjust this value based on your header's height
      const yPosition = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: yPosition, behavior: "smooth" });
    }
  };
  

  return (
    <ThemeProvider theme={TrplTheme}>
      <Header handleBack={handleBackClick} handleLogin={handleLogin} />

      <Slide
        direction="up"
        in={showContent}
        timeout={500}
        mountOnEnter
        unmountOnExit
      >
        <Container maxWidth="md" sx={{ fontFamily: "RamaGothic", py: 4}}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 2,
            }}
          >
            <Box sx={{ flexGrow: 1, textAlign: "center" }}>
              <Box sx={{ height: "100px", mb: -4 }}>
                <Avatar
                  src={Logo}
                  alt="Logo"
                  sx={{ width: 70, height: 70, margin: "auto" }}
                />
              </Box>
              <TrplTitleTextButton
                label="PRISM FI"
                fontSize="28px"
                fontFamily="DharmaGothic"
                gutterBottom
              />
              <TrplTitleTextButton label="( BETA )" fontSize="16px"/>
            </Box>
          </Box>

          <Box sx={{ mb: 3 }}/>

          <Box sx={{ textAlign: "center", mb: 4, mt: -1 }}>
            <Typography
              variant="h5"
              fontSize="20px"
              fontFamily="RamaGothic"
              color="#757575"
              gutterBottom
            >
              Your Spectrum of Financial Intelligence.
            </Typography>
          </Box>

          <Typography
            fontSize="18px"
            fontFamily="BebasNeuePro"
            color="#757575"
            gutterBottom
            >
            Thank you for your interest in Prism FI. Registration is currently free for early access.
            <br />
            To receive a 3-Month Trial post-launch, please refer to the{" "}
            <a
                href="#beta-release"
                style={{
                color: "#969696",
                fontSize: "18px",
                fontFamily: "BebasNeuePro",
                textDecoration: "underline"
                }}
            >
                Beta Release Program.
            </a>
          </Typography>


          <Box sx={{ mb: 4, mt: 4 }}>
            <Typography
              variant="h5"
              component="h2"
              fontSize="24px"
              fontFamily="RamaGothic"
              color="#757575"
              gutterBottom
            >
              Index
            </Typography>
            <Typography
              variant="h5"
              component="h2"
              fontSize="18px"
              fontFamily="BebasNeuePro"
              color="#757575"
              gutterBottom
            >
              <ul>
                <li>
                  <Link
                    component="button"
                    variant="body2"
                    fontSize="18px"
                    fontFamily="BebasNeuePro"
                    onClick={() => scrollToSection("overview")}
                  >
                    Overview
                  </Link>
                </li>
                <li>
                  <Link
                    component="button"
                    variant="body2"
                    fontSize="18px"
                    fontFamily="BebasNeuePro"
                    onClick={() => scrollToSection("mission")}
                  >
                    Our Mission
                  </Link>
                </li>
                <li>
                  <Link
                    component="button"
                    variant="body2"
                    fontSize="18px"
                    fontFamily="BebasNeuePro"
                    onClick={() => scrollToSection("why-choose")}
                  >
                    Why Choose Prism FI?
                  </Link>
                </li>
                <li>
                  <Link
                    component="button"
                    variant="body2"
                    fontSize="18px"
                    fontFamily="BebasNeuePro"
                    onClick={() => scrollToSection("key-features")}
                  >
                    Key Features
                  </Link>
                  <ul>
                    <li>
                      <Link
                        component="button"
                        variant="body2"
                        fontSize="18px"
                        fontFamily="BebasNeuePro"
                        onClick={() => scrollToSection("stock-gpt")}
                      >
                        Stock GPT
                      </Link>
                    </li>
                    <li>
                      <Link
                        component="button"
                        variant="body2"
                        fontSize="18px"
                        fontFamily="BebasNeuePro"
                        onClick={() => scrollToSection("portfolio-gpt")}
                      >
                        Portfolio GPT
                      </Link>
                    </li>
                    <li>
                      <Link
                        component="button"
                        variant="body2"
                        fontSize="18px"
                        fontFamily="BebasNeuePro"
                        onClick={() => scrollToSection("news-models")}
                      >
                        Stock and Market News Models
                      </Link>
                    </li>
                    <li>
                      <Link
                        component="button"
                        variant="body2"
                        fontSize="18px"
                        fontFamily="BebasNeuePro"
                        onClick={() => scrollToSection("options-calculator")}
                      >
                        Options Calculator
                      </Link>
                    </li>
                    <li>
                      <Link
                        component="button"
                        variant="body2"
                        fontSize="18px"
                        fontFamily="BebasNeuePro"
                        onClick={() => scrollToSection("earning-summaries")}
                      >
                        Earning Summaries
                      </Link>
                    </li>
                    <li>
                      <Link
                        component="button"
                        variant="body2"
                        fontSize="18px"
                        fontFamily="BebasNeuePro"
                        onClick={() => scrollToSection("price-volatility")}
                      >
                        Price Volatility
                      </Link>
                    </li>
                    <li>
                      <Link
                        component="button"
                        variant="body2"
                        fontSize="18px"
                        fontFamily="BebasNeuePro"
                        onClick={() => scrollToSection("cycles")}
                      >
                        Cycles
                      </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link
                    component="button"
                    variant="body2"
                    fontSize="18px"
                    fontFamily="BebasNeuePro"
                    onClick={() => scrollToSection("beta-release")}
                  >
                    Beta Release Program
                  </Link>
                </li>
              </ul>
            </Typography>
          </Box>

          <Box id="overview" my={4}>
            <Typography
              variant="h5"
              fontSize="24px"
              fontFamily="RamaGothic"
              color="#757575"
              component="h2"
              gutterBottom
            >
              Overview
            </Typography>
            <Typography
              fontSize="18px"
              color="#757575"
              fontFamily="BebasNeuePro"
              paragraph
            >
              Prism FI leverages cutting-edge Generative AI, Machine Learning, and Big Data to transform the way
              you invest. Our platform provides deep insights into stocks,
              portfolios, options, news, market cycles, and more, empowering you
              to make informed investment decisions with confidence.
            </Typography>
          </Box>

          <Box id="mission" my={4}>
            <Typography
              variant="h5"
              fontSize="24px"
              fontFamily="RamaGothic"
              color="#757575"
              component="h2"
              gutterBottom
            >
                Our Mission
            </Typography>
            <Typography
              fontSize="18px"
              color="#757575"
              fontFamily="BebasNeuePro"
              paragraph
            >
              We are a Financial Technology firm dedicated to transforming the way the world understands and interacts with financial markets. 
              We strive to be your investing co-pilot, guiding you through financial markets with precision and clarity. 
            </Typography>
          </Box>

          <Box id="why-choose" my={4}>
            <Typography
              variant="h5"
              component="h2"
              fontSize="24px"
              fontFamily="RamaGothic"
              color="#757575"
              gutterBottom
            >
              Why Choose Prism FI?
            </Typography>
            <Typography
              variant="h5"
              component="h2"
              fontSize="18px"
              fontFamily="BebasNeuePro"
              color="#757575"
              gutterBottom
            >
              <ul>
                <li>
                  AI-Driven Insights: Harness the power of Generative AI and
                  Machine Learning for accurate and actionable investment
                  insights.
                </li>
                <li>
                  Comprehensive Analysis: From individual stocks to entire
                  portfolios, our platform covers all your investment needs.
                </li>
                <li>
                  Real-Time Updates: Stay informed with real-time data tailored
                  to your investment strategy.
                </li>
                <li>
                  Customer Driven: Our platform is designed with investors in mind,
                  offering responsive support.
                </li>
                <li>
                  User-Friendly Interface: Intuitive design and
                  easy-to-understand reports make investing accessible to
                  everyone.
                </li>
              </ul>
            </Typography>
          </Box>

          <Box id="key-features" my={4}>
            <Typography
              variant="h5"
              component="h2"
              fontSize="24px"
              fontFamily="RamaGothic"
              color="#757575"
              gutterBottom
            >
              Key Features
            </Typography>
            <Typography
              variant="h5"
              component="h2"
              fontSize="18px"
              fontFamily="BebasNeuePro"
              color="#757575"
              gutterBottom
            >
              <ul>
                <li>
                  Stock Research and Analysis: Gain insights into fundamentals,
                  earnings, and price trends with advanced AI models.
                </li>
                <li>
                  Portfolio Models: Simulate and optimize your portfolio with
                  AI-powered tools for diversification, allocation, and
                  performance measurement.
                </li>
                <li>
                  Options Calculators: Determine risk and reward with precision,
                  analyze option pricing, and assess risk-reward scenarios.
                </li>
                <li>
                  Market and Stock News Models: Stay informed with real-time
                  news aggregation, sentiment analysis, and insights into price
                  movements.
                </li>
                <li>
                  Price Volatility: Predict market movements using historical
                  analysis, AI-driven analysis, and risk assessment tools.
                </li>
                <li>
                  Cycles: Understand stock, sector, and market cycles to
                  identify headwinds and tailwinds while making strategic
                  investment decisions.
                </li>
              </ul>
            </Typography>
          </Box>

          <Box id="stock-gpt" my={4}>
            <Typography
              variant="h6"
              fontSize="24px"
              fontFamily="RamaGothic"
              color="#757575"
              gutterBottom
            >
              Stock GPT
            </Typography>
            <Typography
              fontSize="20px"
              fontFamily="BebasNeuePro"
              color="#757575"
              paragraph
            >
              Transforming Stock Analysis with AI
            </Typography>
            <Box sx={{ height: "500px", mb: 2 }}>
              <img
                src={StockGPTImage}
                alt="SGI"
                style={{ width: "100%", height: "100%", objectFit: "contain" }}
              />
            </Box>
            <Typography
              fontSize="18px"
              fontFamily="BebasNeuePro"
              color="#757575"
              paragraph
            >
              Stock GPT uses advanced AI models to analyze individual stocks
              comprehensively. Gain insights into price trends, fundamental
              metrics, news, volatility, and market sentiment. Our analytics
              provide you with accurate up-to-date information on over 2000
              stocks.
            </Typography>
          </Box>

          <Box id="portfolio-gpt" my={4}>
            <Typography
              variant="h6"
              fontSize="24px"
              fontFamily="RamaGothic"
              color="#757575"
              gutterBottom
            >
              Portfolio GPT
            </Typography>
            <Typography
              fontSize="20px"
              fontFamily="BebasNeuePro"
              color="#757575"
              paragraph
            >
              Simulate Your Options Portfolio
            </Typography>
            <Box sx={{ height: "500px", mb: 2 }}>
              <img
                src={PortfolioGPTImage}
                alt="PGI"
                style={{ width: "100%", height: "100%", objectFit: "contain" }}
              />
            </Box>
            <Typography
              fontSize="18px"
              fontFamily="BebasNeuePro"
              color="#757575"
              paragraph
            >
              Portfolio GPT is your AI-powered portfolio simulation tool,
              designed to help you analyze your options portfolio strategy,
              composition, and exposure. Use advanced AI to simulate different
              portfolio scenarios based on market performance. Obtain the impact
              of market movements before making real-world decisions.
            </Typography>
          </Box>

          <Box id="news-models" my={4}>
            <Typography
              variant="h6"
              fontSize="24px"
              fontFamily="RamaGothic"
              color="#757575"
              gutterBottom
            >
              Stock and Market News Models
            </Typography>
            <Typography
              fontSize="20px"
              fontFamily="BebasNeuePro"
              color="#757575"
              paragraph
            >
              Stay Informed with Real-Time Generative AI Insights
            </Typography>
            <Box sx={{ height: "500px", mb: 2 }}>
              <img
                src={NewsImage}
                alt="NI"
                style={{ width: "100%", height: "100%", objectFit: "contain" }}
              />
            </Box>
            <Typography
              fontSize="18px"
              fontFamily="BebasNeuePro"
              color="#757575"
              paragraph
            >
              Our Stock and Market News Models aggregate and analyze news from
              various sources in real-time. Utilize AI-driven sentiment analysis
              to gauge market mood and understand the impact of news on stock
              prices and market trends. Stay updated with the latest
              developments and make informed decisions based on comprehensive
              news insights.
            </Typography>
          </Box>

          <Box id="options-calculator" my={4}>
            <Typography
              variant="h6"
              fontSize="24px"
              fontFamily="RamaGothic"
              color="#757575"
              gutterBottom
            >
              Options Calculator
            </Typography>
            <Typography
              fontSize="20px"
              fontFamily="BebasNeuePro"
              color="#757575"
              paragraph
            >
              Navigate Options Trading with Confidence
            </Typography>
            <Box sx={{ height: "500px", mb: 2 }}>
              <img
                src={OptionsImage}
                alt="OI"
                style={{ width: "100%", height: "100%", objectFit: "contain" }}
              />
            </Box>
            <Typography
              fontSize="18px"
              fontFamily="BebasNeuePro"
              color="#757575"
              paragraph
            >
              Our Options Calculator offers in-depth analysis of option
              structures and implied volatility. The Calculator also helps you
              assess risk-reward, empowering you to optimize your trading
              approach effectively.
            </Typography>
          </Box>

          <Box id="earning-summaries" my={4}>
            <Typography
              variant="h6"
              fontSize="24px"
              fontFamily="RamaGothic"
              color="#757575"
              gutterBottom
            >
              Earning Summaries
            </Typography>
            <Typography
              fontSize="20px"
              fontFamily="BebasNeuePro"
              color="#757575"
              paragraph
            >
              Concise and Insightful Earnings Reports
            </Typography>
            <Box sx={{ height: "500px", mb: 2 }}>
              <img
                src={EarningsImage}
                alt="EI"
                style={{ width: "100%", height: "100%", objectFit: "contain" }}
              />
            </Box>
            <Typography
              fontSize="18px"
              fontFamily="BebasNeuePro"
              color="#757575"
              paragraph
            >
              Stay informed with AI-generated summaries of earnings calls. Our
              Earning Summaries feature transcribes and analyzes earnings calls,
              highlighting key points, forward guidance, and critical
              performance indicators. Get the insights you need without spending
              hours on full call transcripts.
            </Typography>
          </Box>

          <Box id="price-volatility" my={4}>
            <Typography
              variant="h6"
              fontSize="24px"
              fontFamily="RamaGothic"
              color="#757575"
              gutterBottom
            >
              Price Volatility
            </Typography>
            <Typography
              fontSize="20px"
              fontFamily="RamaGothic"
              color="#757575"
              paragraph
            >
              Understanding Market Movements
            </Typography>
            <Box sx={{ height: "500px", mb: 2 }}>
              <img
                src={VolatilityImage}
                alt="SGI"
                style={{ width: "100%", height: "100%", objectFit: "contain" }}
              />
            </Box>
            <Typography
              fontSize="18px"
              fontFamily="RamaGothic"
              color="#757575"
              paragraph
            >
              Price Volatility leverages statistical models to track and
              forecast price movements. Analyze historical volatility, and
              assess potential risks and opportunities. Our models help you
              navigate the market's ups and downs with confidence.
            </Typography>
          </Box>

          <Box id="cycles" my={4}>
            <Typography
              variant="h6"
              fontSize="24px"
              fontFamily="RamaGothic"
              color="#757575"
              gutterBottom
            >
              Cycles
            </Typography>
            <Typography
              fontSize="20px"
              fontFamily="BebasNeuePro"
              color="#757575"
              paragraph
            >
              Master the Market Cycles
            </Typography>
            <Box sx={{ height: "500px", mb: 2 }}>
              <img
                src={CyclesImage}
                alt="SGI"
                style={{ width: "100%", height: "100%", objectFit: "contain" }}
              />
            </Box>
            <Typography
              fontSize="18px"
              fontFamily="BebasNeuePro"
              color="#757575"
              paragraph
            >
              Understand the cyclical nature of stocks, sectors, and the broader
              market with our Cycles features. Analyze performance trends,
              identify key turning points, and make informed decisions based on
              historical data. Stay ahead of the curve with insights into market
              dynamics.
            </Typography>
          </Box>

          <Box id="beta-release" my={4}>
            <Typography
                variant="h5"
                component="h2"
                fontSize="24px"
                fontFamily="RamaGothic"
                color="#757575"
                gutterBottom
            >
                Beta Release Program
            </Typography>
            <Typography
                fontSize="18px"
                fontFamily="BebasNeuePro"
                color="#757575"
                gutterBottom
            >
                Thank you for your interest in participating in our Beta Release Program.
                This program is a limited-time offer for users to experience the full capabilities of Prism FI.
                Obtain a Free 3-Month Trial post-launch by following the steps below.
            </Typography>
            <Typography
                variant="h5"
                component="h2"
                fontSize="18px"
                fontFamily="BebasNeuePro"
                color="#757575"
                gutterBottom
            >
                <ul>
                <li>
                    Register an account on Prism FI using a valid email address. It's free.
                </li>
                <li>
                    Gather your feedback as you use the product on our <a href=" https://forms.gle/6xAca1wVGG8KEEf99" target="_blank">Google Form</a>.
                </li>
                <li>
                    Submit your feedback through the Google Form using the email address you registered with on Prism FI.
                </li>
                <li>
                    The Free 3-Month Trial Link will be sent to the registered email address for activation post-launch.
                </li>
                </ul>
            </Typography>
            <Typography
                fontSize="18px"
                fontFamily="BebasNeuePro"
                color="#757575"
                gutterBottom
            >
                
            </Typography>
           </Box>
          <Box height="1vh" />
        </Container>
      </Slide>
    </ThemeProvider>
  );
};

export default LearnMore;