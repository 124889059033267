import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { styled } from '@mui/system';

import TrplPageHeading from '../../../components/pages/PageHeading';
import TrplPageHeadingFootnote from '../../../components/pages/PageHeadingFootnote';
import { Box } from '@mui/material';
import TrplPageSubheading from '../../../components/pages/PageSubheading';
import Theme from '../../../Theme';
import Telemetry from '../../../Telemetry';

const pageTitle = 'Potential Shorts Stocks';
const pageSubheading = 'This page contains a list of the potential shorts stocks according to fundamentals data.';
const stockFundamentalText = 'The table below displays stock data for shorts.';

const TrplDataGrid = styled(DataGrid)(({ theme }) => ({
    '& .MuiDataGrid-cell': {
        borderColor: theme.palette.divider,
        borderWidth: '.5px',
        borderStyle: 'solid',
        color: 'grey',
        fontFamily: 'RobotoMono',
        fontStyle: 'thin',
    },
    '& .MuiDataGrid-columnHeader': {
        borderColor: theme.palette.divider,
        borderWidth: '.5px',
        borderStyle: 'solid',
        fontFamily: 'RobotoMono',
        color: 'grey',
        fontStyle: 'normal',
        backgroundColor: "#eef7ed",
    },
    '& .MuiDataGrid-iconSeparator': {
        display: 'none',
    },
}));

interface Data {
    Name: string;
    Ticker: string;
    Sector: string;
    PERatio: number;
    MarketCap: number;
    SectorAvgPE: number;
    EPSActualGrowth: number;
}

export default function Shorts() {
    const [data, setData] = useState<Data[]>([]);

    useEffect(() => {
        fetch(`${process.env.REACT_APP_BACKEND}/list-shorts/`)
            .then(response => response.json())
            .then((data: Data[]) => {
                const formattedData = data.map((row: Data, index: number) => ({ id: index, ...row }));
                setData(formattedData);
                const telemetry = Telemetry.getInstance();
                telemetry.trackEvent('Watchlist_Shorts_Fetch', {Success: true});
              })
              .catch((error) => {
                const telemetry = Telemetry.getInstance();
                telemetry.trackEvent('Watchlist_Shorts_Fetch', {Success: false, Error: error});
              });
    }, []);

    const columns = [
        { field: 'Name', headerName: 'Name', width: 350 },
        { field: 'Ticker', headerName: 'Ticker', width: 100 },
        { field: 'Sector', headerName: 'Sector', width: 250 },
        { field: 'PERatio', headerName: 'PE Ratio', width: 120 },
        { field: 'SectorAvgPE', headerName: 'Sector PE', width: 120 },
        { field: 'MarketCap', headerName: 'Market Cap', width: 150 },
        { field: 'EPSActualGrowth', headerName: 'EPS Growth', width: 120}
    ];

    return (
        <div>
            <TrplPageHeading title={pageTitle}/>
            <TrplPageHeadingFootnote title={pageSubheading} />
            <Box height="2vh"/>
            {data.length > 0 && (
                <>
                    <TrplPageSubheading title="Shorts Table" fontSize="18px" color={Theme.palette.primary.dark} />
                    <Box height={"1vh"}/>
                    <TrplPageHeadingFootnote title={stockFundamentalText} />
                    <Box height={"1vh"}/>
                    <TrplDataGrid
                        rows={data}
                        style={{ width: 1210, height: 630 }}
                        columns={columns}
                        pageSize={15}
                    />
                    <Box height="10vh"/>
                </>
            )}
        </div>
    );
}
