import React from "react";
import { AppBar, Toolbar, Box, Button, IconButton, Divider } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import TrplTextButton from "./components/TextButton";

interface HeaderProps {
    handleBack: () => void;
    handleLogin: () => void;
  }
  

const Header: React.FC<HeaderProps> = ({ handleBack, handleLogin }) => {
  return (
    <>
      <AppBar position="fixed" sx={{ backgroundColor: "white", color: "#757575", boxShadow: 'none',}}>
        <Toolbar sx={{ justifyContent: "space-between" }}>
          <Box>
            <IconButton onClick={handleBack} sx={{ color: "#757575" }}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box>
            <TrplTextButton
                label="Login / Register"
                fontSize="18px"
                disableRipple={true}
                onClick={handleLogin}
                fontFamily="RamaGothic"
                color="#757575"
            />
          </Box>
        </Toolbar>
        <Divider />
      </AppBar>
      <Toolbar /> {/* This Toolbar is to create space below the AppBar */}
    </>
  );
};

export default Header;
