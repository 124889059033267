import React, { useState } from 'react';
import { Typography, Grid, Box } from '@mui/material';
import Pagination from '@mui/material/Pagination';
import TrplCard from '../../../components/pages/Card';

import TrplPageHeadingFootnote from '../../../components/pages/PageHeadingFootnote';
import TrplTheme from '../../../Theme';
import TrplBasicButton from '../../../components/pages/BasicButton';

interface news_frame {
  title: string;
  link: string;
  date: string;
  content: string;
  pos_sentiment: number;
  neg_sentiment: number;
}

interface NewsCardProps {
  news_frame: news_frame[];
  onSummarizeClick: (articleInfo: { content: string; title: string; date: string }) => void;
}


function truncateTitle(title: string): string {
  const words = title.split(/\s+/); // Split title into words based on spaces
  if (words.length > 12) {
    return words.slice(0, 12).join(' ') + '...'; // Join the first 12 words and add ellipsis
  }
  return title; // Return original title if it's 12 words or less
}

function NewsCard({ news_frame, onSummarizeClick }: NewsCardProps) {
  const itemsPerPage = 6;
  const [page, setPage] = useState(1);

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };
  
  console.log(news_frame[0].content)

  const cardsToRender = news_frame.slice((page - 1) * itemsPerPage, page * itemsPerPage);
  return (
    <>
      <TrplPageHeadingFootnote title={'The recent news surrounding the stock is presented below with the sentiment. Summarize these articles with AI.'} />
      <Box height='1vh'/>
      <Grid container spacing={12} justifyContent="space-between">
        {cardsToRender.map((cardData, index) => (
          <Grid item key={index} style={{marginRight: '1vw'}}>
            <TrplCard>
              <Box display="flex" flexDirection="column" height="100%">
                <a href={cardData.link} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', marginBottom: 'auto' }}>
                  <Typography variant="h5" color="grey" sx={{ fontFamily: 'RobotoMono', fontStyle: 'light', fontSize: '14px',
                      '&:hover': { color: TrplTheme.palette.secondary.main } }}>
                    {truncateTitle(cardData.title)}
                  </Typography>
                </a>
                <Box display="flex" justifyContent="center" marginTop="auto">
                <TrplBasicButton label='SUMMARIZE' height='3.5vh' width='8vw'
                  onClick={() => {
                    onSummarizeClick({
                      content: cardData.content,
                      title: cardData.title,
                      date: cardData.date
                    });
                  }}/>

                </Box>
                <Box display="flex" flexDirection="column" alignItems="center" marginTop="auto">
                  <Typography variant="body2" color="text.secondary" sx={{ fontFamily: 'ChivoMono', fontStyle: 'light', color: 'green', fontSize: '12px' }}>
                      Positive: {cardData.pos_sentiment.toFixed(2)}
                  </Typography>
                  <Typography variant="body2" color="text.secondary" sx={{ fontFamily: 'ChivoMono', fontStyle: 'light', color: 'crimson', fontSize: '12px' }}>
                      Negative: {cardData.neg_sentiment.toFixed(2)}
                  </Typography>
                </Box>
              </Box>
            </TrplCard>
          </Grid>
        ))}
      </Grid>
      <div style={{ marginTop: '2rem', display: 'flex', justifyContent: 'center' }}>
        <Pagination
            count={Math.ceil(news_frame.length / itemsPerPage)}
            page={page}
            onChange={handleChange}
            color="primary"
            sx={{
                '& .MuiPaginationItem-root': {
                fontFamily: 'RobotoMono',
                fontStyle: 'light',
                color: 'grey',
                fontSize: '12px',
                },
                '& .MuiPaginationItem-page.Mui-selected': {
                backgroundColor: 'grey',
                color: 'white',
                },
            }}
        />
      </div>
    </>
  );
}

export default NewsCard;