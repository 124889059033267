import React from 'react';
import { DataGrid, GridColDef, GridRowsProp } from '@mui/x-data-grid';
import { styled } from '@mui/material/styles';
import TrplPageSubheading from '../../../components/pages/PageSubheading';
import TrplPageHeadingFootnote from '../../../components/pages/PageHeadingFootnote';
import { Box } from '@mui/material';

const optionsCalculationDescription = 'This table displays the calculated values for each option contract based on the selected parameters and target prices.';
const summaryCalculationsDescription = 'This table provides a summary of the overall cost, market value, contract P/L, position P/L, and risk/reward ratio for the selected options strategy.';

<TrplPageHeadingFootnote title={summaryCalculationsDescription} />

interface OptionsTableProps {
  optionsData: GridRowsProp;
  summaryData: GridRowsProp;
}

const TrplDataGrid = styled(DataGrid)(({ theme }) => ({   
    '& .MuiDataGrid-cell': {
      borderColor: theme.palette.divider,
      borderWidth: '.5px',
      borderStyle: 'solid',
      color: 'grey',
      fontFamily: 'RobotoMono',
      fontStyle: 'thin',
    },
    '& .MuiDataGrid-columnHeader': {
      borderColor: theme.palette.divider,
      borderWidth: '.5px',
      borderStyle: 'solid',
      fontFamily: 'RobotoMono',
      color: 'grey',
      fontStyle: 'normal',
      backgroundColor: "#eef7ed",
    },
    '& .MuiDataGrid-iconSeparator': {
      display: 'none',
    },
  }));

const optionsColumns: GridColDef[] = [
  { field: 'LONG/SHORT', headerName: 'Position', width: 100 },
  { field: 'Option Type', headerName: 'Option Type', width: 150 },
  { field: 'Expiration', headerName: 'Expiration', width: 120 },
  { field: 'Strike Price', headerName: 'Strike', width: 100 },
  { field: '# of Contracts', headerName: '# Contracts', width: 120 },
  { field: 'Price or Cost', headerName: 'Price', width: 100 },
  { field: 'Intrinsic Value', headerName: 'Intrinsic Value', width: 150 },
  { field: 'Time Value', headerName: 'Time Value', width: 110 },
  { field: 'Implied Price', headerName: 'Implied Value', width: 150 },
  { field: 'Total Cost', headerName: 'Total Cost', width: 110 },
  { field: 'Market Value', headerName: 'Market Value', width: 130 },
  { field: 'Contract P/L', headerName: 'Contract P/L', width: 140 },
  { field: 'Position P/L', headerName: 'Position P/L', width: 140 },
];

const summaryColumns: GridColDef[] = [
  { field: 'Overall Cost', headerName: 'Overall Cost', width: 150 },
  { field: 'Total Market Value', headerName: 'Market Value', width: 150 },
  { field: 'Total Contract P/L', headerName: 'Contract P/L', width: 150 },
  { field: 'Total Position P/L', headerName: 'Position P/L', width: 150 },
  { field: 'Risk/Reward', headerName: 'Risk/Reward', width: 150 },
];

export const OptionsSummaryTable: React.FC<OptionsTableProps> = ({ optionsData, summaryData }) => {
  return (
    <div>
      <TrplPageSubheading title="Options Calculation" fontSize="18px" />
      <TrplPageHeadingFootnote title={optionsCalculationDescription} />
      <Box height="1vh" />
      <TrplDataGrid
        autoHeight
        style={{ maxWidth: 1340}}
        columns={optionsColumns}
        rows={optionsData}
        pageSize={5}
        rowsPerPageOptions={[5]}
        disableColumnMenu
        hideFooter
      />
      <Box height="4vh" />
      <TrplPageSubheading title="Summary Calculations of Options Contracts" fontSize="18px" />
      <TrplPageHeadingFootnote title={summaryCalculationsDescription} />
      <Box height="1vh" />
      <TrplDataGrid
        autoHeight
        style={{ maxWidth: 750}}
        columns={summaryColumns}
        rows={summaryData}
        pageSize={5}
        rowsPerPageOptions={[5]}
        disableColumnMenu
        hideFooter
      />
      <Box height="4vh" />
    </div>
  );
};

export default OptionsSummaryTable;
