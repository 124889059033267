import React from 'react';

import { DataGrid, GridColDef } from '@mui/x-data-grid';

import { Box, styled } from '@mui/material';

import TrplPageHeadingFootnote from '../../../components/pages/PageHeadingFootnote';

import Theme from '../../../Theme';
import TrplPageSubheading from '../../../components/pages/PageSubheading';
import TrplBarChart2 from '../../../components/pages/BarChart2';

interface PresidentialCycleFrame {
    mean: number;
    median: number;
    min: number;
    max: number;
    last: number;
    cycleLabel: string;
}

interface PresidentialCycleGridProps {
    yearly_cycle: PresidentialCycleFrame[];
}

const buildAtrDistributionColumns = (): GridColDef[] => [
    { field: 'cycleLabel', headerName: 'Month(s)', width: 200 },
    { field: 'mean', headerName: 'Mean %', width: 150 },
    { field: 'median', headerName: 'Median %', width: 150 },
    { field: 'min', headerName: 'Min %', width: 150 },
    { field: 'max', headerName: 'Max %', width: 150 },
    { field: 'last', headerName: 'Last Value %', width: 150 },
  ];  
  
  const TrplDataGrid = styled(DataGrid)(({ theme }) => ({   
    '& .MuiDataGrid-cell': {
      borderColor: theme.palette.divider,
      borderWidth: '.5px',
      borderStyle: 'solid',
      color: 'grey',
      fontFamily: 'RobotoMono', // Add your desired font here
      fontStyle: 'thin',
    },
    '& .MuiDataGrid-columnHeader': {
      borderColor: theme.palette.divider,
      borderWidth: '.5px',
      borderStyle: 'solid',
      fontFamily: 'RobotoMono', // Add your desired font here
      color: 'grey',
      fontStyle: 'normal',
      backgroundColor: "#eef7ed",
    },
    '& .MuiDataGrid-iconSeparator': {
        display: 'none',
      },
  }));
  
  const cycleTableText =  'This table contains the seasonal returns of the index for the selected time interval.';
  const cycleChartText =  'This chart contains the distribution of seasonal returns of the index for the selected time interval.';

const PresidentialCycleGrid: React.FC<PresidentialCycleGridProps> = ({ yearly_cycle }) => {    
    const calculateTotalWidth = (columns: GridColDef[]): number => {
        return columns.reduce((accumulator, column) => accumulator + (column.width as number), 0);
      };
    
    const distibutionCols = buildAtrDistributionColumns();
    const distributionWidth = calculateTotalWidth(distibutionCols);
    
    return (
        <div >
          <TrplPageSubheading title="Seasonal Returns Chart" fontSize="18px" color={Theme.palette.primary.dark} />
          <TrplPageHeadingFootnote title={cycleChartText} />
          <Box height={"2vh"}/>
          <TrplBarChart2 yearly_cycle={yearly_cycle} />
          <Box height={"2vh"}/>
          <TrplPageSubheading title="Seasonal Returns Chart" fontSize="18px" color={Theme.palette.primary.dark} />
          <Box height={"1vh"}/>
          <TrplPageHeadingFootnote title={cycleTableText} />
          <Box height={"1vh"}/>
          <TrplDataGrid
                style={{ width: distributionWidth }}
                columns={distibutionCols}
                rows={yearly_cycle.map((row, index) => ({ id: index, ...row }))}

                pageSize={12}
                rowsPerPageOptions={[12]}
                autoHeight
                disableColumnMenu
                hideFooter
            />
            <Box height={"6vh"}/>
        </div>
      );
};

export default PresidentialCycleGrid;
