import { useState } from 'react';

const useLearnMore = () => {
  const [showLearnMore, setShowLearnMore] = useState(false);

  const handleLearnMore = () => {
    setShowLearnMore(true);
  };

  const handleBack = () => {
    setShowLearnMore(false);
  };

  return { showLearnMore, handleLearnMore, handleBack };
};

export default useLearnMore;