import { Box } from '@mui/material';
import React, { useState } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import TrplDropdownMenu from '../../../components/pages/DropdownMenu';
import TrplInputTextField from '../../../components/pages/InputTextField';

import TrplPageHeading from '../../../components/pages/PageHeading';
import TrplPageHeadingFootnote from '../../../components/pages/PageHeadingFootnote';
import TrplBasicButton from '../../../components/pages/BasicButton';
import TrplPageInputText from '../../../components/pages/PageInputText';

import Theme from '../../../Theme';
import AtrDataTable from './AtrDataTable';
import Telemetry from '../../../Telemetry';

const pageTitle = 'Average True Range';
const pageSubheading = 'This model measures the volatility by averaging the entire range of an asset for a given time period.';
const atrDefinition =  'The average true range (ATR) can help traders determine the level of volatility and risk associated with trading that asset.';
const pageInputText = 'Enter the stock ticker and time interval to execute the model.';

const options = ['1 DAY', '1 WEEK', '1 MONTH', '1 QUARTER'];

function getBackendTimeInterval(timeInterval: string): string {
  switch (timeInterval) {
    case '1 DAY':
      return '1d';
    case '1 WEEK':
      return '1wk';
    case '1 MONTH':
      return '1mo';
    case '1 QUARTER':
      return '3mo';
    default:
      return '';
  }
}

interface AtrDistribution {
    time_label: string;
    time_intervals: number;
    atr: number;
}

interface AtrResponse {
  ticker_name: string;
  atr_distribution: AtrDistribution[]; // You have defined the key as "atrDistribution"
}


function ATRVolatility() {
  const [atrData, setAtrData] = useState<AtrResponse | null>(null);
  const fetchData = (stockTicker: string, timeInterval: string) => {
    fetch(`${process.env.REACT_APP_BACKEND}/volatility-atr/?stockTicker=${stockTicker}&timeInterval=${timeInterval}`)
    .then(response => response.json())
    .then(data => {
      const parsedData = JSON.parse(data);
      parsedData.ticker_name = parsedData.ticker_name.replace(/"/g, '');
      setAtrData(parsedData);
      const telemetry = Telemetry.getInstance();
      telemetry.trackEvent('ATR_Volatility_Fetch', { Stock_Ticker: inputValue, Time_Interval: timeInterval, Success: true});
    })
    .catch(error => {
      const telemetry = Telemetry.getInstance();
      telemetry.trackEvent('ATR_Volatility_Fetch', { Stock_Ticker: inputValue, Time_Interval: timeInterval, Success: false, Error: error});
    });
  };

  const [selectedOption, setSelectedOption] = useState(options[0]);
  const handleDropdownChange = (value: string) => {
    setSelectedOption(value);
  };  

  const [inputValue, setInputValue] = useState('');
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const handleClick = () => {
    fetchData(inputValue, getBackendTimeInterval(selectedOption));
  };

  return (
    <div>
        <TrplPageHeading title={pageTitle}/>
        <TrplPageHeadingFootnote title={pageSubheading} />
        <Box sx={{ flexDirection: 'row', display: 'flex', gap: '6px', mt: 4, mb: .5}}>
          <TrplInputTextField label='STOCK TICKER' height='4.7vh' fontSize='0.9rem' value={inputValue} onChange={handleInputChange} />
          <TrplDropdownMenu options={options} onChange={handleDropdownChange} height='4.7vh' width='8vw' endIcon={<KeyboardArrowDownIcon />}/>
          <TrplBasicButton label='ENTER' height='4.7vh' width='4vw' onClick={handleClick}/>
        </Box>
        <TrplPageInputText title={pageInputText}/>
        <Box height="4vh"/>
        {atrData && (
          <>
            <TrplPageHeading title={atrData.ticker_name} fontSize='21px' color={Theme.palette.primary.dark} /> 
            <Box height="1vh"/>
            <TrplPageHeadingFootnote title={atrDefinition} />
            <Box height="2vh"/>
            <AtrDataTable atrResponse={atrData}/>
          </>
        )}

    </div>
  );
}

export default ATRVolatility;
