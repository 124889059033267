import React, { useState } from 'react';

import TrplPageHeading from '../../../components/pages/PageHeading';
import TrplPageHeadingFootnote from '../../../components/pages/PageHeadingFootnote';
import TrplBasicButton from '../../../components/pages/BasicButton';
import TrplPageInputText from '../../../components/pages/PageInputText';
import TrplInputTextField from '../../../components/pages/InputTextField';
import { Box } from '@mui/material';
import TrendlineChart from '../../../components/pages/TrendlineChart';
import TrplPageSubheading from '../../../components/pages/PageSubheading';
import Theme from '../../../Theme';
import Telemetry from '../../../Telemetry';

const pageTitle = 'Trendlines and Breakouts';
const pageSubheading = 'This model obtains the trendlines and possibly recent breakouts for a stock.';
const pageInputText = 'Enter a ticker and press enter to execute the model.';
const breakoutText = 'This chart represents the trendlines and breakout points for the given stock.';

interface IDataFrame {
    Date: string;
    Open: number;
    High: number;
    Low: number;
    Close: number;
    Adjusted_close: number;
    Volume: number;
}

interface IBreakout {
    Date: string;
    BreakoutUp: number | null;
    BreakoutDown: number | null;
}

interface IData {
    PriceData: IDataFrame[];
    TrendlineMin: number[];
    TrendlineMax: number[];
    Breakouts: IBreakout[];
}

export default function TrendBreakouts() {
    const [data, setData] = useState<IData | null>(null);
    const [inputValue, setInputValue] = useState('');

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(event.target.value);
    };

    const fetchData = (ticker: string) => {
        fetch(`${process.env.REACT_APP_BACKEND}/breakout-trendlines/?stockTicker=${ticker}`)
            .then(response => response.json())
            .then(data => {
                const parsedPriceData = data.PriceData.map((item: IDataFrame) => ({
                    ...item,
                    Date: new Date(item.Date),
                }));

                const parsedBreakouts = data.Breakouts.map((item: IBreakout) => ({
                    ...item,
                    Date: new Date(item.Date),
                }));

                setData({
                    ...data,
                    PriceData: parsedPriceData,
                    Breakouts: parsedBreakouts,
                });

                const telemetry = Telemetry.getInstance();
                telemetry.trackEvent('Trend_Breakouts_Fetch', {Stock_Ticker: ticker, Success: true});
            })
            .catch(error => {
                const telemetry = Telemetry.getInstance();
                telemetry.trackEvent('Trend_Breakouts_Fetch', { Stock_Ticker: ticker, Success: false, Error: error});
              });
    };
    
    const handleClick = () => {
        fetchData(inputValue);
    };

    return (
        <div>
            <TrplPageHeading title={pageTitle}/>
            <TrplPageHeadingFootnote title={pageSubheading} />
            <Box sx={{ flexDirection: 'row', display: 'flex', gap: '6px', mt: 4, mb: .5}}>
                <TrplInputTextField label='STOCK TICKER' height='4.7vh' fontSize='0.9rem' value={inputValue} onChange={handleInputChange} />
                <TrplBasicButton label='ENTER' height='4.7vh' width='4vw' onClick={handleClick}/>
            </Box>
            <TrplPageInputText title={pageInputText}/>
            <Box height="2vh"/>
            {data && (
                <div style={{width: '100%' }}>
                    <TrplPageSubheading title="Trendline Breakout Chart" fontSize="18px" color={Theme.palette.primary.dark} />
                    <TrplPageHeadingFootnote title={breakoutText} />
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <TrendlineChart data={data}/>
                    </Box>
                </div>
            )}
        </div>
    );
}