import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { styled } from '@mui/system';

export interface StockAnnualData {
    id: string;
    Date: string;
    PreviousClose: number | null;
    MarketCap: number | null;
    EPSEstimate: number;
    EPSActual: number | null;
    PriceToSales: number | null;
}

export interface StockQuarterData {
    id: string;
    Date: string;
    PreviousClose: number | null;
    MarketCap: number | null;
    EPSEstimate: number;
    EPSActual: number | null;
    PriceToSales_TTM: number | null;
}

export interface sigma_distribution {
    Sigma: number;
    PctRange: string;
    PriceRange: string;
    Probability: number;
  }

export interface PeerData {
    id: string;
    Name: string;
    Ticker: string;
    MarketCap: number;
    FwdPE: number;
    TotalRevenue: number;
    PriceToSales: number;
    ShortRatio: number;
}

export interface implied_volatilities {
    strike_price: number;
    option_type: string;
    date: string;
    implied_vol: number;
    stock_range: string;
  
}

export interface GPTResponseData {
    name: string;
    description: string;
    annual_fundamentals: StockAnnualData[];
    annual_data_interpretation: string;
    quarterly_fundamentals: StockQuarterData[];
    quarterly_data_interpretation: string;
    peer_fundamentals: PeerData[];
    peer_data_interpretation: string;
    earnings_transcript: string;
    news_summary: string;
    sigma_distribution: sigma_distribution[];
    sigma_distribution_interpretation: string;
    stock_data: PerformanceData[];
    performance: PerformanceInterval[];
    implied_volatilities: implied_volatilities[];
  }
  
export interface PerformanceData {
Date: string;  Open: number;
High: number;
Low: number;
Close: number;
}

export interface PerformanceInterval {
    Interval: string;
    Performance: string;
}


// Constants for page text
export const pageTitle = 'Stock GPT';
export const pageSubheading = 'This model obtains key qualitative and quantitative metrics for the stock.';
export const pageInputText = 'Enter a ticker and press enter to execute the model.';
export const annualDataText = 'The table below displays annual fundamental data for the stock.';
export const quarterlyDataText = 'The table below displays quarterly fundamental data for the stock.';
export const peerDataText = 'The table below displays annual fundamental data for the peers of the stock.';

// MUI Table Stylization
export const TrplDataGrid = styled(DataGrid)(({ theme }) => ({
    '& .MuiDataGrid-cell': {
        borderColor: theme.palette.divider,
        borderWidth: '.5px',
        borderStyle: 'solid',
        color: 'grey',
        fontFamily: 'RobotoMono',
        fontStyle: 'thin',
    },
    '& .MuiDataGrid-columnHeader': {
        borderColor: theme.palette.divider,
        borderWidth: '.5px',
        borderStyle: 'solid',
        fontFamily: 'RobotoMono',
        color: 'grey',
        fontStyle: 'normal',
        backgroundColor: "#eef7ed",
    },
    '& .MuiDataGrid-iconSeparator': {
        display: 'none',
    },
}));

// Columns Definition
export const columnsAnnual: GridColDef[] = [
    { field: 'FormattedDate', headerName: 'Date', width: 150 },
    { field: 'PreviousClose', headerName: 'Previous Close', width: 150 },
    { field: 'MarketCap', headerName: 'Market Cap', width: 250 },
    { field: 'EPSEstimate', headerName: 'EPS Estimate', width: 150 },
    { field: 'EPSActual', headerName: 'EPS Actual', width: 150 },
    { field: 'FwdPE', headerName: 'Forward PE', width: 150 },
    { field: 'PriceToSales', headerName: 'Price to Sales', width: 150 },
];

export const columnsQuarterly: GridColDef[] = [
    { field: 'FormattedDate', headerName: 'Date', width: 150 },
    { field: 'PreviousClose', headerName: 'Previous Close', width: 150 },
    { field: 'MarketCap', headerName: 'Market Cap', width: 250 },
    { field: 'EPSEstimate', headerName: 'EPS Estimate', width: 150 },
    { field: 'EPSActual', headerName: 'EPS Actual', width: 150 },
    { field: 'FwdPE', headerName: 'Forward PE', width: 150 },
    { field: 'PriceToSales_TTM', headerName: 'Price to Sales TTM', width: 200 },
];

export const columnsPeer: GridColDef[] = [
    { field: 'Name', headerName: 'Name', width: 300 },
    { field: 'Ticker', headerName: 'Ticker', width: 100 },
    { field: 'Sector', headerName: 'Sector', width: 200 },
    { field: 'Industry', headerName: 'Industry', width: 300 },
    { field: 'MarketCap', headerName: 'Market Cap', width: 150 },
    { field: 'FwdPE', headerName: 'Forward PE', width: 120 },
    { field: 'TotalRevenue', headerName: 'Total Revenue', width: 150 },
    { field: 'PriceToSales', headerName: 'Price to Sales', width: 150 },
    { field: 'ShortRatio', headerName: 'Short Ratio', width: 150 },
];

export const columnsPerformance: GridColDef[] = [
    { field: 'Interval', headerName: 'Interval', width: 150 },
    { field: 'Performance', headerName: 'Performance', width: 150 },
  ];