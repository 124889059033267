import { Typography } from '@mui/material';
import TrplTheme from '../../Theme';

export default function TrplPageText(props: any) {

  const lines = props.title.split('\n');
  return (
    <div>
      {lines.map((line: string, index: number) => (
        <Typography
          key={index}
          variant="h6"
          sx={{
            fontFamily: 'RobotoMono, monospace',
            fontStyle: 'regular',
            fontSize: '0.9rem',
          }}
          color={TrplTheme.palette.primary.main}
          {...props}
        >
          {line}
        </Typography>
      ))}
    </div>
  );
}
