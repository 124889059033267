// PortfolioAnalysis.tsx
import React from "react";
import { Box, Fade, styled } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import TrplBasicButton from "../../../components/pages/BasicButton";
import { Position } from "./PortfolioGPT";
import { usePortfolioAnalysis } from "./UsePortfolioAnalysis";
import TrplPageSubheading from "../../../components/pages/PageSubheading";
import TrplPageMainText from "../../../components/pages/PageMainText";
import PositionExposurePieChart from "./PositionExposurePieChart";
import PortfolioAnalysisChart from "./PortfolioAnalysisChart";

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  "& .MuiDataGrid-cell": {
    borderColor: theme.palette.divider,
    borderWidth: ".5px",
    borderStyle: "solid",
    color: "grey",
    fontFamily: "RobotoMono",
    fontStyle: "thin",
  },
  "& .MuiDataGrid-columnHeader": {
    borderColor: theme.palette.divider,
    borderWidth: ".5px",
    borderStyle: "solid",
    fontFamily: "RobotoMono",
    color: "grey",
    fontStyle: "normal",
    backgroundColor: "#eef7ed",
  },
  "& .MuiDataGrid-iconSeparator": {
    display: "none",
  },
}));

export default function PortfolioAnalysis({
  positions,
  triggerAnalysis,
  onClose,
}: {
  positions: Position[];
  triggerAnalysis: boolean;
  onClose: () => void;
}) {
  const analysisData = usePortfolioAnalysis(positions, triggerAnalysis);

  const calculateTotalWidth = (columns: GridColDef[]): number => {
    return columns.reduce(
      (accumulator, column) => accumulator + (column.width as number),
      0
    );
  };

  return (
    <div>
      {analysisData && (
        <>
          {analysisData.position_exposure &&
            Object.values(analysisData.position_exposure).length > 0 && (
              <Fade in={true} timeout={1000}>
                <div>
                  <Box height="2vh" />
                <TrplPageSubheading title="Exposure Pie Chart" fontSize="18px" />
                <Box height="1vh" />
                <PositionExposurePieChart data={analysisData.position_exposure} />
                  <Box height="1vh" />
                  <TrplPageSubheading
                    title="Position Exposure"
                    fontSize="18px"
                  />
                  <Box height="2vh" />
                  <StyledDataGrid
                    style={{
                      width: calculateTotalWidth([
                        { field: "ticker", headerName: "Ticker", width: 100 },
                        {
                          field: "net_exposure",
                          headerName: "Net Exposure",
                          width: 150,
                        },
                        {
                          field: "absolute_net_exposure",
                          headerName: "Absolute Net Exposure",
                          width: 200,
                        },
                        { field: "size", headerName: "Size", width: 100 },
                        {
                          field: "exposure",
                          headerName: "Exposure",
                          width: 150,
                        },
                      ]),
                    }}
                    rows={analysisData.position_exposure}
                    columns={[
                      { field: "ticker", headerName: "Ticker", width: 100 },
                      {
                        field: "net_exposure",
                        headerName: "Net Exposure",
                        width: 150,
                      },
                      {
                        field: "absolute_net_exposure",
                        headerName: "Absolute Net Exposure",
                        width: 200,
                      },
                      { field: "size", headerName: "Size", width: 100 },
                      { field: "exposure", headerName: "Exposure", width: 150 },
                    ]}
                    getRowId={(row) => row.ticker}
                    autoHeight
                    hideFooter
                  />
                  <Box height="2vh" />
                  <TrplPageSubheading
                    title="Summary Exposure"
                    fontSize="18px"
                  />
                  <Box height="1vh" />
                  <StyledDataGrid
                    style={{
                      width: calculateTotalWidth([
                        {
                          field: "net_exposure",
                          headerName: "Net Exposure",
                          width: 150,
                        },
                        { field: "size", headerName: "Size", width: 100 },
                        {
                          field: "exposure",
                          headerName: "Exposure",
                          width: 150,
                        },
                        {
                          field: "absolute_net_exposure",
                          headerName: "Absolute Net Exposure",
                          width: 200,
                        },
                      ]),
                    }}
                    rows={analysisData.summary_exposure}
                    columns={[
                      {
                        field: "net_exposure",
                        headerName: "Net Exposure",
                        width: 150,
                      },
                      { field: "size", headerName: "Size", width: 100 },
                      { field: "exposure", headerName: "Exposure", width: 150 },
                      {
                        field: "absolute_net_exposure",
                        headerName: "Absolute Net Exposure",
                        width: 200,
                      },
                    ]}
                    getRowId={(row) => row.exposure}
                    autoHeight
                    hideFooter
                  />
                  {analysisData.exposure_interpretation &&
                    analysisData.exposure_interpretation.length > 0 && (
                      <>
                        <Box height="2vh" />
                        <TrplPageSubheading title="Comments" fontSize="16px" />
                        <TrplPageMainText
                          text={analysisData.exposure_interpretation}
                        />
                      </>
                    )}
                </div>
              </Fade>
            )}

          {/* Additional checks before using Object.keys to prevent errors */}
          {analysisData.correlation_matrix &&
            Object.values(analysisData.correlation_matrix).length > 0 && (
              <Fade in={true} timeout={1000}>
                <div>
                  <Box height="2vh" />
                  <TrplPageSubheading
                    title="Correlation Matrix"
                    fontSize="18px"
                  />
                  <Box height="1vh" />
                  {analysisData.correlation_matrix && (
                    <StyledDataGrid
                      style={{
                        width: calculateTotalWidth([
                          { field: "id", headerName: "Ticker", width: 100 },
                          ...Object.keys(
                            analysisData.correlation_matrix || {}
                          ).map((key) => ({
                            field: key,
                            headerName: key,
                            width: 100,
                          })),
                        ]),
                      }}
                      rows={Object.entries(analysisData.correlation_matrix).map(
                        ([key, value]) => ({ id: key, ...value })
                      )}
                      columns={[
                        { field: "id", headerName: "Ticker", width: 100 },
                        ...Object.keys(
                          analysisData.correlation_matrix || {}
                        ).map((key) => ({
                          field: key,
                          headerName: key,
                          width: 100,
                        })),
                      ]}
                      getRowId={(row) => row.id}
                      autoHeight
                      hideFooter
                    />
                  )}
                  <Box height="2vh" />
                  <TrplPageSubheading
                    title="Overall Correlation"
                    fontSize="18px"
                  />
                  <Box height="1vh" />
                  <TrplPageMainText
                    text={`The overall correlation of the porfolio is: ${
                      analysisData.overall_correlation?.toFixed(2) || "N/A"
                    }%.`}
                  />
                  <Box height="2vh" />

                  {analysisData.correlation_interpretation &&
                    analysisData.correlation_interpretation.length > 0 && (
                      <>
                        <TrplPageSubheading title="Comments" fontSize="16px" />
                        <TrplPageMainText
                          text={analysisData.correlation_interpretation}
                        />
                      </>
                    )}
                </div>
              </Fade>
            )}

          {analysisData.high_prices &&
            Object.values(analysisData.high_prices).length > 0 && (
              <Fade in={true} timeout={1000}>
                <div>
                <Box height="1vh" />
                {analysisData.high_prices_p_l &&
                    Object.values(analysisData.high_prices_p_l).length > 0 && (
                <>
                <TrplPageSubheading title="High Price Movement Chart" fontSize="18px" />
                <PortfolioAnalysisChart
                  chartType="high"
                  pricesData={analysisData.high_prices}
                  pricesPLData={analysisData.high_prices_p_l}
                  pricesPositionPLData={analysisData.high_prices_position_pl}
                />
                </>
                )}
                  <Box height="2vh" />
                  <TrplPageSubheading title="High Prices" fontSize="18px" />
                  <Box height="1vh" />
                  {analysisData.high_prices && (
                    <StyledDataGrid
                      style={{
                        width: calculateTotalWidth([
                          {
                            field: "Time Period",
                            headerName: "Time Period",
                            width: 150,
                          },
                          { field: "Date", headerName: "Date", width: 150 },
                          ...Object.keys(analysisData.high_prices[0] || {})
                            .filter(
                              (key) => key !== "Time Period" && key !== "Date"
                            )
                            .map((key) => ({
                              field: key,
                              headerName: key,
                              width: 150,
                            })),
                        ]),
                      }}
                      rows={analysisData.high_prices}
                      columns={[
                        {
                          field: "Time Period",
                          headerName: "Time Period",
                          width: 150,
                        },
                        { field: "Date", headerName: "Date", width: 150 },
                        ...Object.keys(analysisData.high_prices[0] || {})
                          .filter(
                            (key) => key !== "Time Period" && key !== "Date"
                          )
                          .map((key) => ({
                            field: key,
                            headerName: key,
                            width: 150,
                          })),
                      ]}
                      getRowId={(row) => row.Date}
                      autoHeight
                      hideFooter
                    />
                  )}
                  {analysisData.high_prices_p_l &&
                    Object.values(analysisData.high_prices_p_l).length > 0 && (
                      <>
                        <Box height="2vh" />
                        <TrplPageSubheading
                          title="High Prices P&L"
                          fontSize="18px"
                        />
                        <Box height="1vh" />
                        <StyledDataGrid
                          style={{
                            width: calculateTotalWidth([
                              {
                                field: "Week(s)",
                                headerName: "Week(s)",
                                width: 150,
                              },
                              { field: "Date", headerName: "Date", width: 150 },
                              {
                                field: "TOTAL",
                                headerName: "Total",
                                width: 150,
                              },
                              ...Object.keys(
                                analysisData.high_prices_p_l[0] || {}
                              )
                                .filter(
                                  (key) => key !== "Week(s)" && key !== "TOTAL" && key !== "Date"
                                )
                                .map((key) => ({
                                  field: key,
                                  headerName: key,
                                  width: 150,
                                })),
                            ]),
                          }}
                          rows={analysisData.high_prices_p_l}
                          columns={[
                            {
                              field: "Week(s)",
                              headerName: "Week(s)",
                              width: 150,
                            },
                            { field: "Date", headerName: "Date", width: 150 },
                            { field: "TOTAL", headerName: "Total", width: 150 },
                            ...Object.keys(
                              analysisData.high_prices_p_l[0] || {}
                            )
                              .filter(
                                (key) => key !== "Week(s)" && key !== "TOTAL" && key !== "Date"
                              )
                              .map((key) => ({
                                field: key,
                                headerName: key,
                                width: 150,
                              })),
                          ]}
                          getRowId={(row) => `${row["Week(s)"]}-${row.TOTAL}`}
                          autoHeight
                          hideFooter
                        />
                      </>
                    )}

                  {analysisData.high_prices_position_pl &&
                    Object.values(analysisData.high_prices_position_pl).length >
                      0 && (
                      <>
                        <Box height="2vh" />
                        <TrplPageSubheading
                          title="High Prices Position P&L"
                          fontSize="18px"
                        />
                        <Box height="1vh" />
                        <StyledDataGrid
                          style={{
                            width: calculateTotalWidth([
                              {
                                field: "Week(s)",
                                headerName: "Week(s)",
                                width: 150,
                              },
                              { field: "Date", headerName: "Date", width: 150 },
                              {
                                field: "TOTAL",
                                headerName: "Total",
                                width: 150,
                              },
                              ...Object.keys(
                                analysisData.high_prices_position_pl[0] || {}
                              )
                                .filter(
                                  (key) => key !== "Week(s)" && key !== "TOTAL" && key !== "Date"
                                )
                                .map((key) => ({
                                  field: key,
                                  headerName: key,
                                  width: 150,
                                })),
                            ]),
                          }}
                          rows={analysisData.high_prices_position_pl}
                          columns={[
                            {
                              field: "Week(s)",
                              headerName: "Week(s)",
                              width: 150,
                            },
                            { field: "Date", headerName: "Date", width: 150 },
                            { field: "TOTAL", headerName: "Total", width: 150 },
                            ...Object.keys(
                              analysisData.high_prices_position_pl[0] || {}
                            )
                              .filter(
                                (key) => key !== "Week(s)" && key !== "TOTAL" && key !== "Date"
                              )
                              .map((key) => ({
                                field: key,
                                headerName: key,
                                width: 150,
                              })),
                          ]}
                          getRowId={(row) => `${row["Week(s)"]}-${row.TOTAL}`}
                          autoHeight
                          hideFooter
                        />
                      </>
                    )}

                  {analysisData.high_prices_interpretation &&
                    analysisData.high_prices_interpretation.length > 0 && (
                      <>
                        <Box height="2vh" />
                        <TrplPageSubheading
                          title="Comments on High Prices"
                          fontSize="16px"
                        />
                        <TrplPageMainText
                          text={
                            analysisData.high_prices_interpretation ||
                            "No additional comments"
                          }
                        />
                      </>
                    )}
                </div>
              </Fade>
            )}

          {analysisData.low_prices &&
            Object.values(analysisData.low_prices).length > 0 &&
            analysisData.low_prices_p_l &&
            Object.values(analysisData.low_prices_p_l).length > 0 && (
              <Fade in={true} timeout={1000}>
                <div>
                <TrplPageSubheading title="Low Price Movement Chart" fontSize="18px" />
                <PortfolioAnalysisChart
                  chartType="low"
                  pricesData={analysisData.low_prices}
                  pricesPLData={analysisData.low_prices_p_l}
                  pricesPositionPLData={analysisData.low_prices_position_pl}
                />
                  <Box height="2vh" />
                  <TrplPageSubheading title="Low Prices" fontSize="18px" />
                  <Box height="1vh" />
                  <StyledDataGrid
                    style={{
                      width: calculateTotalWidth([
                        {
                          field: "Time Period",
                          headerName: "Time Period",
                          width: 150,
                        },
                        { field: "Date", headerName: "Date", width: 150 },
                        ...Object.keys(analysisData.low_prices[0] || {})
                          .filter(
                            (key) => key !== "Time Period" && key !== "Date"
                          )
                          .map((key) => ({
                            field: key,
                            headerName: key,
                            width: 150,
                          })),
                      ]),
                    }}
                    rows={analysisData.low_prices}
                    columns={[
                      {
                        field: "Time Period",
                        headerName: "Time Period",
                        width: 150,
                      },
                      { field: "Date", headerName: "Date", width: 150 },
                      ...Object.keys(analysisData.low_prices[0] || {})
                        .filter(
                          (key) => key !== "Time Period" && key !== "Date"
                        )
                        .map((key) => ({
                          field: key,
                          headerName: key,
                          width: 150,
                        })),
                    ]}
                    getRowId={(row) => row.Date}
                    autoHeight
                    hideFooter
                  />
                  {analysisData.low_prices_p_l &&
                    Object.values(analysisData.low_prices_p_l).length > 0 && (
                      <>
                        <Box height="2vh" />
                        <TrplPageSubheading
                          title="Low Prices P&L"
                          fontSize="18px"
                        />
                        <Box height="1vh" />
                        <StyledDataGrid
                          style={{
                            width: calculateTotalWidth([
                              {
                                field: "Week(s)",
                                headerName: "Week(s)",
                                width: 150,
                              },
                              { field: "Date", headerName: "Date", width: 150 },
                              {
                                field: "TOTAL",
                                headerName: "Total",
                                width: 150,
                              },
                              ...Object.keys(
                                analysisData.low_prices_p_l[0] || {}
                              )
                                .filter(
                                  (key) => key !== "Week(s)" && key !== "TOTAL" && key !== "Date"
                                )
                                .map((key) => ({
                                  field: key,
                                  headerName: key,
                                  width: 150,
                                })),
                            ]),
                          }}
                          rows={analysisData.low_prices_p_l}
                          columns={[
                            {
                              field: "Week(s)",
                              headerName: "Week(s)",
                              width: 150,
                            },
                            { field: "Date", headerName: "Date", width: 150 },
                            { field: "TOTAL", headerName: "Total", width: 150 },
                            ...Object.keys(analysisData.low_prices_p_l[0] || {})
                              .filter(
                                (key) => key !== "Week(s)" && key !== "TOTAL" && key !== "Date"
                              )
                              .map((key) => ({
                                field: key,
                                headerName: key,
                                width: 150,
                              })),
                          ]}
                          getRowId={(row) => `${row["Week(s)"]}-${row.TOTAL}`}
                          autoHeight
                          hideFooter
                        />
                      </>
                    )}
                  {analysisData.low_prices_position_pl &&
                    Object.values(analysisData.low_prices_position_pl).length >
                      0 && (
                      <>
                        <Box height="2vh" />
                        <TrplPageSubheading
                          title="Low Prices Position P&L"
                          fontSize="18px"
                        />
                        <Box height="1vh" />
                        <StyledDataGrid
                          style={{
                            width: calculateTotalWidth([
                              {
                                field: "Week(s)",
                                headerName: "Week(s)",
                                width: 150,
                              },
                              { field: "Date", headerName: "Date", width: 150 },
                              {
                                field: "TOTAL",
                                headerName: "Total",
                                width: 150,
                              },
                              ...Object.keys(
                                analysisData.low_prices_position_pl[0] || {}
                              )
                                .filter(
                                  (key) => key !== "Week(s)" && key !== "TOTAL" && key !== "Date"
                                )
                                .map((key) => ({
                                  field: key,
                                  headerName: key,
                                  width: 150,
                                })),
                            ]),
                          }}
                          rows={analysisData.low_prices_position_pl}
                          columns={[
                            {
                              field: "Week(s)",
                              headerName: "Week(s)",
                              width: 150,
                            },
                            { field: "Date", headerName: "Date", width: 150 },
                            { field: "TOTAL", headerName: "Total", width: 150 },
                            ...Object.keys(
                              analysisData.low_prices_position_pl[0] || {}
                            )
                              .filter(
                                (key) => key !== "Week(s)" && key !== "TOTAL" && key !== "Date"
                              )
                              .map((key) => ({
                                field: key,
                                headerName: key,
                                width: 150,
                              })),
                          ]}
                          getRowId={(row) => `${row["Week(s)"]}-${row.TOTAL}`}
                          autoHeight
                          hideFooter
                        />
                      </>
                    )}

                  {analysisData.low_prices_interpretation &&
                    analysisData.low_prices_interpretation.length > 0 && (
                      <>
                        <Box height="2vh" />
                        <TrplPageSubheading
                          title="Comments on Low Prices"
                          fontSize="16px"
                        />
                        <TrplPageMainText
                          text={
                            analysisData.low_prices_interpretation ||
                            "No additional comments"
                          }
                        />
                      </>
                    )}
                </div>
              </Fade>
            )}

          <Box height="4vh" />
          <TrplBasicButton
            label="CLOSE ANALYSIS"
            height="4.7vh"
            width="12vw"
            onClick={onClose}
            disabled={positions.length === 0}
          />
          <Box height="4vh" />
        </>
      )}
    </div>
  );
}