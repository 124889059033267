import React from 'react';
import { Avatar, Box, Stack, Typography } from '@mui/material';
import TrplTitleTextButton from './components/TitleTextButton';
import Logo from './PrismLogo.png';

const HomePage: React.FC = () => {
  const title = 'PRISM FI';

  return (
    <Stack spacing={1} sx={{ justifyContent: 'center', alignItems: 'center', minHeight: '77vh' }}>
      <Box marginBottom="-2vh">
        <Avatar src={Logo} alt="Logo" sx={{ width: 100, height: 100, margin: 'auto' }} /> {/* adjust size as necessary */}
      </Box>
      <Box paddingTop="1vh" paddingBottom="0vh">
        <TrplTitleTextButton label={title} fontSize="28px" disableRipple={true} />
      </Box>
      <Box>
        <Typography variant="h6" fontFamily="RamaGothic" fontSize="18px" component="h3" sx={{ color: '#757575' }}>
          Your Spectrum of Financial Intelligence.
        </Typography>
      </Box>
    </Stack>
  );
};

export default HomePage;