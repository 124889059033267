import React, { useEffect, useRef } from 'react';
import * as echarts from 'echarts';
import { ECharts, EChartsOption } from 'echarts';

interface StockFundamentalsData {
    Date: string;
    'Total Revenue': number;
    'EPS Actual': number;
    'EPS Estimate': number;
    'Total Shares': number;
    'Fwd PE': number;
    'Market Cap': number;
    'Net Income': number;
    'Price To Sales': number;
    'Previous Close': number;
}

const chartOptionsMap = {
    'TOTAL REVENUE': 'Total Revenue',
    'EPS ACTUAL': 'EPS Actual',
    'EPS ESTIMATE': 'EPS Estimate',
    'TOTAL SHARES': 'Total Shares',
    'FORWARD PE': 'Fwd PE',
    'MARKET CAP': 'Market Cap',
    'NET INCOME': 'Net Income',
    'PRICE TO SALES': 'Price To Sales',
    'CLOSING PRICE': 'Previous Close',
};

export type OptionKeys = keyof typeof chartOptionsMap; // Create a type that includes the keys of chartOptionsMap

interface FundamentalChartProps {
    data: StockFundamentalsData[];
    option: OptionKeys; // Use the newly created type here
}

  
export const FundamentalChart: React.FC<FundamentalChartProps> = ({ data, option }) => {
    const chartRef = useRef<HTMLDivElement | null>(null);
    const mappedOption = chartOptionsMap[option];

    const filteredData = data.filter(item => item[mappedOption as keyof StockFundamentalsData]);
    
    // Create a Set for unique date tracking
    const dateSet = new Set();
    
    // Remove duplicate dates
    const uniqueData = filteredData.filter((item) => {
        if(!dateSet.has(item.Date)) {
            dateSet.add(item.Date);
            return true;
        }
        return false;
    });
    
    const sortedData = [...uniqueData].sort((a, b) => new Date(a.Date).getTime() - new Date(b.Date).getTime());
    
    // Use mappedOption when accessing data
    useEffect(() => {
        if (chartRef.current && data) {
            const chart: ECharts = echarts.init(chartRef.current);
            const options: EChartsOption = {
                animation: true,
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross'
                    },
                    textStyle: {
                        fontFamily: 'ChivoMono',
                        fontSize: 14,
                    },
                    formatter: function (params: any) {
                        const value = params[0].data;
                        const date = params[0].axisValue;
                        return `Date: ${date}<br/>Value: ${value}`;
                    }
                },
                xAxis: {
                    name: 'Date',
                    nameLocation: 'middle',
                    nameGap: 70,
                    nameTextStyle: {
                        fontFamily: 'ChivoMono',
                        fontSize: 16,
                    },
                    axisLabel: {
                        fontFamily: 'ChivoMono',
                        fontSize: 13,
                        interval: 0,
                        margin: 12,
                        rotate: 30,
                    },
                    data: sortedData.map(item => item.Date),
                },
                grid: {
                    bottom: '10%',  // Increase as needed to give more room at the bottom
                    containLabel: true,
                },
                yAxis: {
                    name: option,
                    nameLocation: 'middle',
                    nameGap: 140,
                    nameTextStyle: {
                        fontFamily: 'ChivoMono',
                        fontSize: 16,
                        padding: [0, -90, 0, 0]
                    },
                    axisLabel: {
                        fontFamily: 'ChivoMono',
                        fontSize: 13,
                        interval: 20,
                        rotate: 0,
                    },
                },
                series: [
                    {
                        type: 'line',
                        data: sortedData.map(item => item[mappedOption as keyof StockFundamentalsData]),
                        itemStyle: {
                            color: '#64c864',
                            borderColor: '#64c864'
                        },
                    }
                ]
            };
            chart.setOption(options);
        }
    }, [data, option]);

    return (
        <div ref={chartRef} style={{ width: '100%', height: '600px' }}></div>
    );
}
