import React from 'react';
import { Box } from '@mui/material';
import TrplPageSubheading from '../../../components/pages/PageSubheading';
import { PerformanceData, PerformanceInterval, TrplDataGrid, columnsPerformance } from './StockGPTConstants';
import CandlestickChart from '../../../components/pages/CandlestickChart';
import TrplPageText from '../../../components/pages/PageText';

interface StockGPTPerformanceProps {
  stockData: PerformanceData[];
  performance: PerformanceInterval[];
  currentPrice: number;
}

const StockGPTPerformance: React.FC<StockGPTPerformanceProps> = ({ stockData, performance, currentPrice }) => {
  // Add a unique id to each row in the performance array
  const performanceWithIds = performance.map((item, index) => ({
    ...item,
    id: `performance-${index}`, // Ensuring each performance entry has a unique ID.
  }));

  return (
    <div>
      <TrplPageSubheading title="Stock Performance" fontSize="18px" />
      <Box height="2vh" />
      <TrplPageText title={`Last Close: $${currentPrice.toFixed(2)}`} />
      <Box height="2vh" />
      <CandlestickChart data={stockData} />
      <Box height="2vh" />
      <TrplPageSubheading title="Price Performance Metrics" fontSize="16px" />
      <Box height="2vh" />
      {/* Using the modified performance array with unique IDs for each row */}
      <TrplDataGrid rows={performanceWithIds} columns={columnsPerformance} style={{ width: 300 }} autoHeight hideFooter />
      <Box height="2vh" />
    </div>
  );
};

export default StockGPTPerformance;
