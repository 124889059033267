import React from 'react';
import { Box } from '@mui/material';
import { TrplDataGrid } from './StockGPTConstants';
import { GridColDef } from '@mui/x-data-grid';
import { implied_volatilities } from './StockGPTConstants';

const columnsIV: GridColDef[] = [
  { field: 'strike_price', headerName: 'Strike Price', width: 150 },
  { field: 'option_type', headerName: 'Option Type', width: 150 },
  { field: 'date', headerName: 'Date', width: 200 },
  { field: 'implied_vol', headerName: 'Implied Volatility', width: 200 },
];

interface ImpliedVolatilityTableProps {
  impliedVolatilities: implied_volatilities[];
}

const ImpliedVolatilityTable: React.FC<ImpliedVolatilityTableProps> = ({ impliedVolatilities }) => {
  const volatilitiesWithIds = impliedVolatilities.map((item, index) => ({
    ...item,
    id: `vol-${index}`, // Ensuring each performance entry has a unique ID.
  }));

  console.log(impliedVolatilities);

  return (

    <Box>
      <TrplDataGrid
        rows={volatilitiesWithIds}
        columns={columnsIV}
        pageSize={8}
        style={{ width: 700 }}
        disableSelectionOnClick
        autoHeight
      />
    </Box>
  );
};

export default ImpliedVolatilityTable;