import React, { useState } from 'react';
import { Box } from '@mui/material';
import VolatilityNewsChild from './VolatilityNewsChild';
import TrplInputTextField from '../../../components/pages/InputTextField';
import TrplBasicButton from '../../../components/pages/BasicButton';
import TrplPageHeading from '../../../components/pages/PageHeading';
import TrplPageHeadingFootnote from '../../../components/pages/PageHeadingFootnote';
import TrplPageInputText from '../../../components/pages/PageInputText';
import Telemetry from '../../../Telemetry';

const pageInputText = 'Enter a ticker and press enter to execute the model.';

interface PerformanceData {
  Date: string;
  Open: number;
  High: number;
  Low: number;
  Close: number;
}

interface HighVolatilityDay {
  Date: string;
  Percent_Close: number;
  Volume: number;
  Classification: string;
}

const VolatilityNews: React.FC = () => {
  const [stockTicker, setStockTicker] = useState('');
  const [stockData, setStockData] = useState<PerformanceData[]>([]);
  const [significantDays, setSignificantDays] = useState<HighVolatilityDay[]>([]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStockTicker(event.target.value);
  };

  const handleSubmit = () => {
    fetch(`${process.env.REACT_APP_BACKEND}/get-high-vol-days/?stockTicker=${stockTicker}`)
      .then(response => response.json())
      .then(data => {
        setStockData(JSON.parse(data.stock_data));
        setSignificantDays(JSON.parse(data.significant_days));
        const telemetry = Telemetry.getInstance();
        telemetry.trackEvent('News_Volatility_Ticker_Fetch', {Stock_Ticker: stockTicker, Success: true});
    })
    .catch(error => {
        const telemetry = Telemetry.getInstance();
        telemetry.trackEvent('News_Volatility_Ticker_Fetch', { Stock_Ticker: stockTicker, Success: false, Error: error});
    });
  };

  return (
    <div>
      <TrplPageHeading title="Stock Volatility Analysis" />
      <TrplPageHeadingFootnote
        title="This tool analyzes the historical price data of a stock and identifies news, events surrounding days with high volatility and trading volume."
      />

      <Box sx={{ flexDirection: 'row', display: 'flex', gap: '6px', mt: 3 }}>
        <TrplInputTextField
          label="STOCK TICKER"
          height="4.7vh"
          fontSize="0.9rem"
          value={stockTicker}
          onChange={handleInputChange}
        />
        <TrplBasicButton label="ENTER" height="4.7vh" width="6vw" onClick={handleSubmit} />
      </Box>
      <TrplPageInputText title={pageInputText} />

      <Box height="4vh" />

      {stockData.length > 0 && significantDays.length > 0 && (
        <VolatilityNewsChild ticker={stockTicker} stockData={stockData} significantDays={significantDays} />
      )}
    </div>
  );
};

export default VolatilityNews;