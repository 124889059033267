import { Box } from '@mui/material';
import React, { useState } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import TrplDropdownMenu from '../../../components/pages/DropdownMenu';

import TrplPageHeading from '../../../components/pages/PageHeading';
import TrplPageHeadingFootnote from '../../../components/pages/PageHeadingFootnote';
import TrplBasicButton from '../../../components/pages/BasicButton';
import TrplPageInputText from '../../../components/pages/PageInputText';
import PresidentialCycleGrid from './PresidentialCycleDataGrid';
import Telemetry from '../../../Telemetry';

const pageTitle = 'Sector Cycle';
const pageSubheading = 'This model measures the seasonality of the market for a specific sectors and slices them by the 4 year cycle.';
const pageInputText = 'Select a sector and time interval to execute the model.';
const pageYearText = 'Change the cycle year through this selection.';

const timeOptions = ['1 MONTH', '1 QUARTER'];
const sectorOptions = ['CONSUMER DISCRETIONARY', 'CONSUMER STAPLES', 'COMMUNICATIONS', 'ENERGY', 'FINANCIALS', 'INDUSTRIALS', 'HEALTH CARE', 'INFORMATION TECHNOLOGY', 'MATERIALS', 'UTILITIES'];
const yearOptions = ['YEAR 1', 'YEAR 2', 'YEAR 3', 'YEAR 4'];

function getBackendIndexTicker(index: string): string {
    switch (index) {
      case 'CONSUMER DISCRETIONARY':
        return 'XLY';
      case 'CONSUMER STAPLES':
        return 'XLP';
      case 'COMMUNICATIONS':
        return 'XLC';
      case 'ENERGY':
        return 'XLE';
      case 'FINANCIALS':
        return 'XLF';
      case 'INDUSTRIALS':
        return 'XLI';
      case 'HEALTH CARE':
        return 'XLV';
      case 'INFORMATION TECHNOLOGY':
        return 'XLK';
      case 'MATERIALS':
        return 'XLB';
      case 'UTILITIES':
        return 'XLU';
      default:
        return '';
    }
  }

function getBackendTimeInterval(timeInterval: string): string {
  switch (timeInterval) {
    case '1 MONTH':
      return '1mo';
    case '1 QUARTER':
      return '3mo';
    default:
      return '';
  }
}

function getYearDisplayOption(year: string): number {
    switch (year) {
      case 'YEAR 1':
        return 0;
      case 'YEAR 2':
        return 1;
      case 'YEAR 3':
        return 2;
      case 'YEAR 4':
        return 3;
      default:
        return -1;
    }
  }

interface SectorCycleFrame {
    mean: number;
    median: number;
    min: number;
    max: number;
    last: number;
    cycleLabel: string;
}

const getYearlyBuckets = ( array: SectorCycleFrame[] ) : SectorCycleFrame[][] => {
    const result: SectorCycleFrame[][] = [];
    const chunkSize = Math.ceil(array.length / 4);
  
    for (let i = 0; i < array.length; i += chunkSize) {
      result.push(array.slice(i, i + chunkSize));
    }
  
    return result;
  };

export default function SectorCycle() {
    const [cycleYearlyData, setCycleYearlyData] = useState<SectorCycleFrame[][] | null>(null);
    const fetchData = (index: string, timeInterval: string) => {
    fetch(`${process.env.REACT_APP_BACKEND}/sector-cycle/?index=${getBackendIndexTicker(index)}&timeInterval=${timeInterval}`)
    .then(response => response.json())
    .then(data => {
        const parsedData = JSON.parse(data);
        setCycleYearlyData(getYearlyBuckets(parsedData.bucket_frame));
        
        const telemetry = Telemetry.getInstance();
        telemetry.trackEvent('Cycles_Sector_Fetch', {Sector: index, Time_Interval: timeInterval, Success: true});
      })
      .catch((error) => {
        const telemetry = Telemetry.getInstance();
        telemetry.trackEvent('Cycles_Sector_Fetch', {Sector: index, Time_Interval: timeInterval, Success: false, Error: error});
      });
  };

  const [selectedYearOption, setSelectedYearOption] = useState(new Date().getFullYear() % 4);

  const handleClick = () => {
    fetchData(selectedIndexOption, getBackendTimeInterval(selectedTimeOption));
  };
  

  const [selectedTimeOption, setSelecteTimeOption] = useState(timeOptions[0]);
  const handleTimeDropdownChange = (value: string) => {
    setSelecteTimeOption(value);
  };  

  const [selectedIndexOption, setSelectedIndexOption] = useState(sectorOptions[0]);
  const handleIndexDropdownChange = (value: string) => {
    setSelectedIndexOption(value);
  };

  const handleYearDropdownChange = (value: string) => {
    setSelectedYearOption(getYearDisplayOption(value));
  };  

  return (
    <div>
        <TrplPageHeading title={pageTitle}/>
        <TrplPageHeadingFootnote title={pageSubheading} />
        <Box sx={{ flexDirection: 'row', display: 'flex', gap: '6px', mt: 4, mb: .5}}>
          <TrplDropdownMenu options={sectorOptions} onChange={handleIndexDropdownChange} height='4.7vh' width='15vw' endIcon={<KeyboardArrowDownIcon />}/>
          <TrplDropdownMenu options={timeOptions} onChange={handleTimeDropdownChange} height='4.7vh' width='8vw' endIcon={<KeyboardArrowDownIcon />}/>
          <TrplBasicButton label='ENTER' height='4.7vh' width='4vw' onClick={handleClick}/>
        </Box>
        <TrplPageInputText title={pageInputText}/>
        <Box height="2vh"/>
        {cycleYearlyData &&
        <>
          <Box height="1vh"/>
          <TrplDropdownMenu options={yearOptions} onChange={handleYearDropdownChange} height='4.7vh' width='8vw' endIcon={<KeyboardArrowDownIcon />}/>
          <Box height=".5vh"/>
          <TrplPageInputText title={pageYearText}/>
          <Box height="3vh"/>
          <PresidentialCycleGrid yearly_cycle={cycleYearlyData[selectedYearOption]} />
          </>
        }

    </div>
  );
}
