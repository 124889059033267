import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from 'history';

class Telemetry {
  private static instance: Telemetry;
  private appInsights: ApplicationInsights;
  private eventBuffer: Array<{ eventName: string, properties?: { [key: string]: any } }> = [];
  private initialized: boolean = false;

  private constructor() {
    const history = createBrowserHistory();
    const reactPlugin = new ReactPlugin();
    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: `${process.env.REACT_APP_INSIGHTS_INSTRUMENTATION_KEY}`,
        extensions: [reactPlugin],
        extensionConfig: {
          [reactPlugin.identifier]: { history: history }
        }
      }
    });

    this.appInsights.loadAppInsights();
    this.appInsights.trackPageView();
    this.initialized = true;
    this.processEventBuffer();
  }

  private static initialize(): Telemetry {
    Telemetry.instance = new Telemetry();
    return Telemetry.instance;
  }

  public static getInstance(): Telemetry {
    if (!Telemetry.instance) {
      return Telemetry.initialize();
    }
    return Telemetry.instance;
  }

  private processEventBuffer(): void {
    if (this.initialized && this.eventBuffer.length > 0) {
      this.eventBuffer.forEach(event => this.trackEvent(event.eventName, event.properties));
      this.eventBuffer = [];
    }
  }

  public trackEvent(eventName: string, properties?: { [key: string]: any }): void {
    if (!this.initialized) {
      this.eventBuffer.push({ eventName, properties });
    } else {
      this.appInsights.trackEvent({
        name: 'Prism_Metrics_' + eventName,
        properties: {
          ...properties,
        }
      });
    }
  }
}

export default Telemetry;
