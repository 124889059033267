import { Typography } from '@mui/material';
import TrplTheme from '../../Theme';

export default function TrplPageSubheading(props: any) {
    const title = props.title || '';
    const lines = title.split('\n');

    return (
        <div>
            {lines.map((line: string, index: number) => (
                <Typography
                    key={index}
                    variant="h6"
                    sx={{
                        fontFamily: 'ChivoMono',
                        fontStyle: 'italic',
                    }}
                    color={TrplTheme.palette.primary.main}
                    {...props}
                >
                    {line}
                </Typography>
            ))}
        </div>
    );
}