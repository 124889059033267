import React, { useState } from 'react';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { Box, styled } from '@mui/material';
import { ECharts, init as initECharts } from 'echarts';
import { EChartsOption } from 'echarts';
import { useEffect, useRef } from 'react';
import TrplPageSubheading from '../../../components/pages/PageSubheading';
import TrplPageHeadingFootnote from '../../../components/pages/PageHeadingFootnote';
import TrplTextButton from '../../../components/TextButton';
import TrplBasicButton from '../../../components/pages/BasicButton';
import TrplPageMainText from '../../../components/pages/PageMainText';
import Theme from '../../../Theme';
import Telemetry from '../../../Telemetry';

interface PerformanceData {
  Date: string;
  Open: number;
  High: number;
  Low: number;
  Close: number;
}

interface HighVolatilityDay {
  Date: string;
  Percent_Close: number;
  Volume: number;
  Classification: string;
}

interface ChildComponentProps {
    ticker: string;
  stockData: PerformanceData[];
  significantDays: HighVolatilityDay[];
}

const TrplDataGrid = styled(DataGrid)(({ theme }) => ({
  '& .MuiDataGrid-cell': {
    borderColor: theme.palette.divider,
    borderWidth: '.5px',
    borderStyle: 'solid',
    color: 'grey',
    fontFamily: 'RobotoMono',
    fontStyle: 'thin',
  },
  '& .MuiDataGrid-columnHeader': {
    borderColor: theme.palette.divider,
    borderWidth: '.5px',
    borderStyle: 'solid',
    fontFamily: 'RobotoMono',
    color: 'grey',
    fontStyle: 'normal',
    backgroundColor: "#eef7ed",
  },
  '& .MuiDataGrid-iconSeparator': {
    display: 'none',
  },
}));

const chartDescription = 'The candlestick chart contains the daily returns of the stock for the past 3 months.';
const tableDescription = 'The table below contains the high volume and volatility days for the past 3 months.';
const marketNewsDescripton = 'The table below contains the market news surrounding the date below.';
const stockNewsDescripton = 'The table below contains the stock news surrounding the date below.';
const econNewsDescripton = 'The table below contains the economic releases surrounding the date below.';

const VolatilityNewsChild: React.FC<ChildComponentProps> = ({ ticker, stockData, significantDays }) => {
  const chartRef = useRef<HTMLDivElement | null>(null);
  const [newsData, setNewsData] = useState<{
    date: string
    market_news: any[];
    stock_news: any[];
    economic_releases: any[];
  } | null>(null);

  useEffect(() => {
    if (chartRef.current) {
      const chart: ECharts = initECharts(chartRef.current);
      const options: EChartsOption = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross'
          },
          textStyle: {
            fontFamily: 'ChivoMono',
            fontSize: 14,
          },
          formatter: function (params: any) {
            const data = params[0].data;
            const date = params[0].axisValue;
            return `
              Date: ${date}<br/>
              Open: ${data[1]}<br/>
              Close: ${data[2]}<br/>
              Low: ${data[3]}<br/>
              High: ${data[4]}
            `;
          }
        },
        xAxis: {
          name: 'Date',
          nameLocation: 'middle',
          nameGap: 50,
          nameTextStyle: {
            fontFamily: 'ChivoMono',
            fontSize: 16,
          },
          axisLabel: {
            fontFamily: 'ChivoMono',
            fontSize: 13,
            interval: 6,
            margin: 15,
            rotate: 20,
          },  
          data: stockData.map(item => new Date(item.Date).toISOString().split('T')[0]),
        },
        yAxis: {
          name: 'Price',
          nameLocation: 'middle',
          nameGap: 80,
          splitNumber: 5,
          nameTextStyle: {
            fontFamily: 'ChivoMono',
            fontSize: 16,
          },
          axisLabel: {
            fontFamily: 'ChivoMono',
            fontSize: 13,
            rotate: 0,
          },
          scale: true,
        },
        series: [{
          type: 'candlestick',
          data: stockData.map(item => [item.Open, item.Close, item.Low, item.High]),
          itemStyle: {
            color: '#64c864',
            color0: '#C86464',
            borderColor: '#64c864',
            borderColor0: '#C86464'
          },
        }],
      };
      chart.setOption(options);
    }
  }, [stockData]);

  const fetchNews = async (date: string) => {
    setVolatilitySummary('');
    try 
    {
      const response = await fetch(`${process.env.REACT_APP_BACKEND}/get-high-vol-news/?stockTicker=${ticker}&date=${date}`);
      const data = await response.json();
      setNewsData(data);
      const telemetry = Telemetry.getInstance();
      telemetry.trackEvent('News_Volatility_GetNews_Fetch', {Stock_Ticker: ticker, Success: true});
    }
    catch(error: any) 
    {
        const telemetry = Telemetry.getInstance();
        telemetry.trackEvent('News_Volatility_GetNews_Fetch', { Stock_Ticker: ticker, Success: false, Error: error});
    }
  };

  const buildHighVolatilityColumns = (): GridColDef[] => [
    {
        field: 'fetch',
        headerName: 'News Data',
        width: 150,
        renderCell: (params: GridRenderCellParams) => (
          <TrplTextButton
            label="OBTAIN NEWS"
            onClick={() => fetchNews(params.row.Date)}
            width="100%"
          />
        ),
      },
    { field: 'Date', headerName: 'Date', width: 150 },
    { field: 'Percent_Close', headerName: '% Close', width: 150 },
    { field: 'Volume', headerName: 'Volume', width: 150 },
    { field: 'Classification', headerName: 'Classification', width: 250 },
  ];

  const highVolatilityCols = buildHighVolatilityColumns();
  const [volatilitySummary, setVolatilitySummary] = useState('');

  const fetchVolatilitySummary = async (date: string, tickerName: string, percentChange: number | undefined, allNewsTitles: any[], allNewsContent: any[]) => {
    setVolatilitySummary('');
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND}/get-news-vol-summary/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          date,
          ticker: tickerName,
          percentChange,
          newsTitles: allNewsTitles,
          newsContent: allNewsContent,
        }),
      });
  
      if (!response.body) throw new Error('Failed to stream response');
      const reader = response.body.getReader();
  
      while (true) {
        const { done, value } = await reader.read();
        if (done) break;
        const chunk = new TextDecoder().decode(value);
        setVolatilitySummary(prevSummary => prevSummary + chunk);
      }
        const telemetry = Telemetry.getInstance();
        telemetry.trackEvent('News_Volatility_GetSummary_Fetch', {Stock_Ticker: ticker, Success: true});
    }
    catch(error: any) 
    {
      const telemetry = Telemetry.getInstance();
      telemetry.trackEvent('News_Volatility_GetSummary_Fetch', { Stock_Ticker: ticker, Success: false, Error: error});
    }
  };

  return (
    <div>
      <TrplPageSubheading title="3 Month Price Chart" fontSize="18px" />
      <TrplPageHeadingFootnote title={chartDescription} />
      <Box height="2vh" />
      <div ref={chartRef} style={{ width: '100%', height: '600px' }}></div>
      <Box height="4vh" />
      <TrplPageSubheading title="High Volatility and Volume Days" fontSize="18px" />

      <TrplPageHeadingFootnote title={tableDescription} />
      <Box height="2vh" />
      <TrplDataGrid
        columns={highVolatilityCols}
        rows={significantDays.map((row, index) => ({ id: index, ...row }))}
        sx={{ width: 850}}
        pageSize={10}
        rowsPerPageOptions={[10]}
        autoHeight
        disableColumnMenu
        
      />
      <Box height="4vh" />
      <Box height="4vh" />
      {newsData && (
        <div>
          <TrplPageSubheading title={`News & Events around Date: ${newsData.date}`} fontSize="18px" />
          <Box height="1vh" />
          <TrplPageSubheading title={`${ticker} Stock News`} fontSize="18px" />
          <TrplPageHeadingFootnote title={stockNewsDescripton} />
          <Box height="1vh" />
          <TrplDataGrid
            columns={[
              { field: 'publishedDate', headerName: 'Date', width: 200 },
              { field: 'site', headerName: 'Source', width: 300 },
              {
                field: 'title',
                headerName: 'Title',
                width: 1200,
                renderCell: (params: GridRenderCellParams) => (
                  <a href={params.row.url} target="_blank" rel="noopener noreferrer">
                    {params.value}
                  </a>
                ),
              },
            ]}
            rows={newsData.stock_news.map((news, index) => ({ id: index, ...news }))}
            sx={{ width: 1200 }}
            pageSize={10}
            rowsPerPageOptions={[10]}
            autoHeight
            disableColumnMenu
          />
          <Box height="4vh" />
          <TrplPageSubheading title="Market News" fontSize="18px" />
          <TrplPageHeadingFootnote title={marketNewsDescripton} />
          <Box height="1vh" />
          <TrplDataGrid
            columns={[
              { field: 'publishedDate', headerName: 'Date', width: 200 },
              { field: 'site', headerName: 'Source', width: 300 },
              {
                field: 'title',
                headerName: 'Title',
                width: 1200,
                renderCell: (params: GridRenderCellParams) => (
                  <a href={params.row.url} target="_blank" rel="noopener noreferrer">
                    {params.value}
                  </a>
                ),
              },
            ]}
            rows={newsData.market_news.map((news, index) => ({ id: index, ...news }))}
            sx={{ width: 1200 }}
            pageSize={10}
            rowsPerPageOptions={[10]}
            autoHeight
            disableColumnMenu
          />
          <Box height="4vh" />
          <TrplPageSubheading title="US Economic Releases" fontSize="18px" />
          <TrplPageHeadingFootnote title={econNewsDescripton} />
          <Box height="1vh" />
          <TrplDataGrid
            columns={[
              { field: 'date', headerName: 'Date', width: 200 },
              { field: 'event', headerName: 'Event', width: 400 },
              { field: 'actual', headerName: 'Actual', width: 100 },
              { field: 'previous', headerName: 'Previous', width: 100 },
            ]}
            rows={newsData.economic_releases.map((release, index) => ({ id: index, ...release }))}
            sx={{ width: 800 }}
            pageSize={10}
            rowsPerPageOptions={[10]}
            autoHeight
            disableColumnMenu
          />
          <Box height="2vh" />
          <TrplBasicButton
            label="OBTAIN SUMMARY"
            height="4.7vh"
            width="10vw"
            onClick={() => {
                const stockNewsTitles = newsData.stock_news.map(news => news.title);
                const marketNewsTitles = newsData.market_news.map(news => news.title);
                const stockNewsContent = newsData.stock_news.map(news => news.text);
                const marketNewsContent = newsData.market_news.map(news => news.text);
                const allNewsTitles = [...stockNewsTitles, ...marketNewsTitles];
                const allNewsContent = [...stockNewsContent, ...marketNewsContent];
                const date = newsData.date;
                const tickerName = ticker;
                const percentChange = significantDays.find(day => day.Date === date)?.Percent_Close;
                fetchVolatilitySummary(date, tickerName, percentChange, allNewsTitles, allNewsContent);
            }}
            />
            <Box height="4vh" />
            {volatilitySummary && (
            <>
                <TrplPageSubheading title={`Volatility Summary for ${ticker} on ${newsData.date}`} fontSize="18px" color={Theme.palette.primary.dark} />
                <TrplPageMainText text={volatilitySummary} />
            </>
            )}
            <Box height="6vh" />
        </div>
      )}
    </div>
  );
};

export default VolatilityNewsChild;