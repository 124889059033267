import React, { useState } from 'react';
import { Box, styled } from '@mui/material';
import TrplPageHeading from '../../../components/pages/PageHeading';
import TrplPageHeadingFootnote from '../../../components/pages/PageHeadingFootnote';
import TrplBasicButton from '../../../components/pages/BasicButton';
import TrplPageSourceText from '../../../components/pages/PageSourceText';
import Telemetry from '../../../Telemetry';

const pageTitle = 'Market News Summary';
const pageSubheading = 'Generate a summary of market news and events that could affect the stock market.';

const MarketNewsSummary: React.FC = () => {
  const [newsSummary, setNewsSummary] = useState<string>('');

  const fetchSummary = async () => {
    try {
      setNewsSummary('');
      const response = await fetch(`${process.env.REACT_APP_BACKEND}/get-daily-news-summary/`, {
        method: 'POST',
      });
  
      if (!response.ok) {
        throw new Error('Failed to fetch summary');
      }

      if (!response.body) {
        throw new Error('Response body is null');
      }
  
      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let streamedSummary = '';
  
      while (true) {
        const { done, value } = await reader.read();
        if (done) break;
        streamedSummary += decoder.decode(value);
        setNewsSummary((prev) => prev + decoder.decode(value));
      }
  
      const telemetry = Telemetry.getInstance();
      telemetry.trackEvent('Market_News_Summary_Ticker_Fetch', {Success: true});
    } 
    catch (error) 
    {
        const telemetry = Telemetry.getInstance();
        telemetry.trackEvent('Market_News_Summary_Ticker_Fetch', { Success: false, Error: error});
    }
  };

  return (
    <div>
      <TrplPageHeading title={pageTitle} />
      <TrplPageHeadingFootnote title={pageSubheading} />
      <Box height="4vh" />
      <TrplBasicButton label="GET MARKET NEWS" height="4.7vh" width="14vw" onClick={fetchSummary} />
      <Box height="4vh" />
      {newsSummary && (
        <>
          <TrplPageSourceText text={newsSummary} />
          <Box height="4vh" />
        </>
      )}
    </div>
  );
};

export default MarketNewsSummary;