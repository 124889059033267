import { styled } from '@mui/material/styles';

import { MenuItem } from '@mui/material';

import TrplTheme from '../Theme';

export default function TrplDropdownMenuItem(props: any) {

    const StyledMenuItem = styled(MenuItem)({
        color: TrplTheme.palette.primary.main,
        fontSize: "14px",
        height: "30px",
    });

    return (
        <StyledMenuItem
            id={props.id}
            onClick={props.onClick}
        >
            {props.label}
        </StyledMenuItem>
    );
}