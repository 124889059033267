import { Box, styled } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import React, { useState } from 'react';

import TrplInputTextField from '../../../components/pages/InputTextField';
import TrplPageHeading from '../../../components/pages/PageHeading';
import TrplPageHeadingFootnote from '../../../components/pages/PageHeadingFootnote';
import TrplBasicButton from '../../../components/pages/BasicButton';
import TrplPageInputText from '../../../components/pages/PageInputText';

import Theme from '../../../Theme';
import TrplPageSubheading from '../../../components/pages/PageSubheading';
import TrplPageText from '../../../components/pages/PageText';
import Telemetry from '../../../Telemetry';

const pageTitle = 'Portfolio Volatility';
const pageSubheading = 'This model measures the historical volatility of assets within a portfolio. It assumes equal weighting for all assets.';
const correlationDefinition = 'The volatility table contains an individual list of volatilities for each asset in the portfolio.';
const pageInputText = 'Enter the stock tickers separated by commas in your portfolio to obtain the correlation.';

  const TrplDataGrid = styled(DataGrid)(({ theme }) => ({   
    '& .MuiDataGrid-cell': {
      borderColor: theme.palette.divider,
      borderWidth: '.5px',
      borderStyle: 'solid',
      color: 'grey',
      fontFamily: 'RobotoMono', // Add your desired font here
      fontStyle: 'thin',
    },
    '& .MuiDataGrid-columnHeader': {
      borderColor: theme.palette.divider,
      borderWidth: '.5px',
      borderStyle: 'solid',
      fontFamily: 'RobotoMono', // Add your desired font here
      color: 'grey',
      fontStyle: 'normal',
      backgroundColor: "#eef7ed",
    },
    '& .MuiDataGrid-iconSeparator': {
        display: 'none',
      },
      overflow: 'auto',
  }));

  interface VolatilityResponse {
    volatility: string;
    volatility_matrix: { [key: string]: number };
  }
  
  export default function Volatility(): React.ReactElement {
    const [volData, setVolData] = useState<VolatilityResponse | null>(null);
    const fetchData = (stockTicker: string) => {
      fetch(`${process.env.REACT_APP_BACKEND}/portfolio-vol/?stockTicker=${stockTicker}`)
        .then(response => response.text())
        .then(data => {
          const parsedData: VolatilityResponse = JSON.parse(data);
          setVolData(parsedData);
          const telemetry = Telemetry.getInstance();
          telemetry.trackEvent('Portfolio_Volatility_Fetch', {Portfolio_Positions: stockTicker, Success: true});
        })
        .catch(error => {
          const telemetry = Telemetry.getInstance();
          telemetry.trackEvent('Portfolio_Volatility_Fetch', { Portfolio_Positions: stockTicker, Success: false, Error: error});
        });
    };
    
  const [inputValue, setInputValue] = useState('');
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const handleClick = () => {
    fetchData(inputValue);
  };

  const createColumns = () => {
    return [
      { field: 'id', headerName: 'Ticker', minWidth: 150 },
      { field: 'volatility', headerName: 'Volatility %', minWidth: 150 },
    ];
  };
  
  const correlationMatrixToRows = (matrix: { [key: string]: number }): any[] => {
    const tickers = Object.keys(matrix);
  
    return tickers.map((ticker) => ({
      id: ticker,
      volatility: matrix[ticker],
    }));
  };
  


  const columns = volData ? createColumns() : [];
  const rows = volData ? correlationMatrixToRows(volData.volatility_matrix) : [];


  const maxVisibleColumns = 9;
  const maxVisibleRows = 9;
  const columnWidth = 150; // Min width of each column
  const columnHeaderHeight = 58; // Approximate height of the column header
  const rowHeight = 50; // Approximate height of each row

  const gridWidth = Math.min(columns.length, maxVisibleColumns) * columnWidth;
  const gridHeight = Math.min(rows.length, maxVisibleRows) * rowHeight + columnHeaderHeight;


  return (
    <div>
      <TrplPageHeading title={pageTitle} />
      <TrplPageHeadingFootnote title={pageSubheading} />
      <Box sx={{ flexDirection: 'row', display: 'flex', gap: '6px', mt: 4, mb: 0.5 }}>
        <TrplInputTextField
          label="PORTFOLIO TICKERS"
          height="4.7vh"
          width="25vw"
          fontSize="0.9rem"
          value={inputValue}
          onChange={handleInputChange}
        />
        <TrplBasicButton label="ENTER" height="4.7vh" width="4vw" onClick={handleClick} />
      </Box>
      <TrplPageInputText title={pageInputText} />
      <Box height="4vh" />
      {volData && columns.length > 0 && (
        <div style={{ height: 400, width: '100%' }}>
          <TrplPageSubheading title='Overall Volatility' fontSize="18px" color={Theme.palette.primary.dark} />
          <Box height="1vh" />
          <TrplPageText title={`The overall portfolio volatility is: ${volData.volatility}%.`}/>
          <Box height="2vh" />
          <TrplPageSubheading title='Volatility Table' fontSize="18px" color={Theme.palette.primary.dark} />
          <TrplPageHeadingFootnote title={correlationDefinition} />
          <Box height="1vh" />
          <TrplDataGrid
            style={{ width: gridWidth, height: gridHeight }}
            rows={correlationMatrixToRows(volData.volatility_matrix)}
            columns={createColumns()}
            pageSize={15}
            rowsPerPageOptions={[15]}
            checkboxSelection={false}
            rowHeight={50}
            hideFooter
      />
        </div>
      )}
    </div>
  );
}
