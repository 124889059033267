import { styled } from '@mui/material/styles';
import React, { ChangeEvent } from 'react';
import TextField, { TextFieldProps } from '@mui/material/TextField';

type StyledTextFieldProps = TextFieldProps & {
  height: string | number;
  fontSize: string | number;
  width?: string | number;
};

const StyledTextField = styled(TextField)<StyledTextFieldProps>(
  ({ theme, height, fontSize, width = '12%' }) => ({
    // Other styles here
    width: width,
    height: height,
    borderRadius: 0,
    borderWidth: 0,
    '& .MuiInputBase-root': {
      borderRadius: 0,
      height: '100%',
    },
    '& .MuiInputBase-input': {
      color: theme.palette.primary.main,
      fontSize: fontSize,
      textTransform: 'uppercase',
    },
    '& .MuiInputLabel-root': {
      color: theme.palette.primary.main,
      fontSize: fontSize,
      top: '50%',
      left: '0.8rem',
      transform: 'translate(0, -50%)',
    },
    '& .MuiInputLabel-shrink': {
      transform: 'translate(0, -200%)',
      fontSize: '0.68rem',
    },
    '& .MuiOutlinedInput-root': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderRadius: 0,
      },
    },
  })
);

function TrplInputTextField(props: StyledTextFieldProps) {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    // Transform the input value to uppercase
    event.target.value = event.target.value.toUpperCase();
    // Call the original onChange handler, if provided
    if (props.onChange) {
      props.onChange(event);
    }
  };

  return (
    <StyledTextField
      {...props}
      onChange={handleChange}
      multiline={false}
      color="primary"
    />
  );
}

export default TrplInputTextField;
