import React, { useState } from 'react';
import { Box } from '@mui/material';
import TrplPageHeading from '../../../components/pages/PageHeading';
import TrplPageHeadingFootnote from '../../../components/pages/PageHeadingFootnote';
import TrplBasicButton from '../../../components/pages/BasicButton';
import TrplInputTextField from '../../../components/pages/InputTextField';
import TrplPageSourceText from '../../../components/pages/PageSourceText';
import Telemetry from '../../../Telemetry';

const pageTitle = 'Stock News Summary';
const pageSubheading = 'Generate a summary of news surrounding a specific stock ticker.';

const TickerNewsSummary: React.FC = () => {
  const [newsSummary, setNewsSummary] = useState<string>('');
  const [stockTicker, setStockTicker] = useState<string>('');

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStockTicker(event.target.value);
  };

  const fetchSummary = async () => {
    try {
      setNewsSummary('');
      const response = await fetch(`${process.env.REACT_APP_BACKEND}/get-ticker-news-summary/`, {
        method: 'POST',
        body: JSON.stringify({ ticker: stockTicker }),
      });

      if (!response.ok) {
        throw new Error('Failed to fetch summary');
      }

      if (!response.body) {
        throw new Error('Response body is null');
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let streamedSummary = '';

      while (true) {
        const { done, value } = await reader.read();
        if (done) break;
        streamedSummary += decoder.decode(value);
        setNewsSummary((prev) => prev + decoder.decode(value));
      }

      const telemetry = Telemetry.getInstance();
      telemetry.trackEvent('Stock_News_Summary_Fetch', {Stock_Ticker: stockTicker, Success: true});
    } 
    catch (error) 
    {
        const telemetry = Telemetry.getInstance();
        telemetry.trackEvent('Stock_News_Summary_Fetch', { Stock_Ticker: stockTicker, Success: false, Error: error});
    }
  };

  return (
    <div>
      <TrplPageHeading title={pageTitle} />
      <TrplPageHeadingFootnote title={pageSubheading} />
      <Box height="4vh" />
      <Box>
      `<TrplInputTextField
        label="STOCK TICKER"
        height="4.7vh"
        fontSize="0.9rem"
        value={stockTicker}
        onChange={handleInputChange}
      />`
      <TrplBasicButton label="GENERATE NEWS SUMMARY" height="4.7vh" width="12vw" onClick={fetchSummary} />
      </Box>
      
      <Box height="4vh" />
      {newsSummary && (
        <>
          <TrplPageSourceText text={newsSummary} />
          <Box height="4vh" />
        </>
      )}
    </div>
  );
};

export default TickerNewsSummary;