import { Box, IconButton, TextField, styled } from '@mui/material';
import React, { useState, useEffect } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import DeleteIcon from '@mui/icons-material/Delete';
import TrplDropdownMenu from '../../../components/pages/DropdownMenu';
import TrplInputTextField from '../../../components/pages/InputTextField';
import TrplPageHeading from '../../../components/pages/PageHeading';
import TrplPageSubheading from '../../../components/pages/PageSubheading';
import TrplPageHeadingFootnote from '../../../components/pages/PageHeadingFootnote';
import TrplBasicButton from '../../../components/pages/BasicButton';
import TrplPageInputText from '../../../components/pages/PageInputText';
import OptionsSummaryTable from './OptionsSummaryTable';

import { DataGrid } from '@mui/x-data-grid';
import Telemetry from '../../../Telemetry';

const pageTitle = 'Options Calculator';
const pageSubheading = 'This calculator allows you to select multiple options for a given stock ticker and calculate the projected P/L.';
const pageInputText = 'Enter the stock ticker and click enter to fetch the expiration dates.';
const selectedOptionsDescription = 'Review and modify the selected options in the data grid below. You can change the number of contracts for each option and delete options as needed.';
const targetPriceDescription = 'Enter the target price(s) for the underlying stock at each expiration date in the table below.';

interface Option {
  id: number;
  expiration: string;
  optionType: string;
  strikePrice: number;
  positionType: string;
  lastPrice: number;
  bid: number;
  ask: number;
  change: number;
  percentChange: number;
  volume: number;
  openInterest: number;
  impliedVolatility: number;
  targetPrice: number;
  numContracts: number;
  totalCost: number;
}

const TrplDataGrid = styled(DataGrid)(({ theme }) => ({   
  '& .MuiDataGrid-cell': {
    borderColor: theme.palette.divider,
    borderWidth: '.5px',
    borderStyle: 'solid',
    color: 'grey',
    fontFamily: 'RobotoMono',
    fontStyle: 'thin',
  },
  '& .MuiDataGrid-columnHeader': {
    borderColor: theme.palette.divider,
    borderWidth: '.5px',
    borderStyle: 'solid',
    fontFamily: 'RobotoMono',
    color: 'grey',
    fontStyle: 'normal',
    backgroundColor: "#eef7ed",
  },
  '& .MuiDataGrid-iconSeparator': {
    display: 'none',
  },
}));

const TargetPriceInput = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-input': {
    padding: '8px',
    fontSize: '14px',
    fontFamily: 'RobotoMono',
    color: 'grey',
    backgroundColor: theme.palette.background.paper,
    borderRadius: '4px',
  },
}));

function OptionsCalculator() {
  const [stockTicker, setStockTicker] = useState('');
  const [expirationDates, setExpirationDates] = useState<string[]>([]);
  const [selectedExpiration, setSelectedExpiration] = useState('');
  const [optionType, setOptionType] = useState('CALL');
  const [strikePrices, setStrikePrices] = useState<number[]>([]);
  const [selectedStrike, setSelectedStrike] = useState(0);
  const [positionType, setPositionType] = useState('LONG');
  const [options, setOptions] = useState<Option[]>([]);
  const [gridWidth, setGridWidth] = useState(0);
  const [lastPrice, setLastPrice] = useState(0);
  const [isCalculateEnabled, setIsCalculateEnabled] = useState(false);
  const [optionsData, setOptionsData] = useState<any[]>([]);
  const [summaryData, setSummaryData] = useState<any[]>([]);


  const handleNumContractsChange = (optionId: number, value: string) => {
    const parsedValue = value !== '' && !isNaN(parseInt(value)) ? parseInt(value) : 1;
    setOptions((prevOptions) =>
      prevOptions.map((option) =>
        option.id === optionId ? { ...option, numContracts: parsedValue, totalCost: parsedValue * 100 * option.lastPrice } : option
      )
    );
  };

  const columns = [
    {
      field: 'delete',
      headerName: 'Delete?',
      width: 80,
      renderCell: (params: any) => (
        <IconButton
          onClick={() => handleDeleteOption(params.row.id)}
          color="inherit"
        >
          <DeleteIcon />
        </IconButton>
      ),
    },
    {
      field: 'numContracts',
      headerName: '# Contracts',
      width: 120,
      renderCell: (params: any) => (
        <TargetPriceInput
          value={params.value}
          onChange={(event) => handleNumContractsChange(params.row.id, event.target.value)}
          required
        />
      ),
    },
    { field: 'positionType', headerName: 'Position', width: 100 },
    { field: 'optionType', headerName: 'Option Type', width: 120 },
    { field: 'strikePrice', headerName: 'Strike Price', width: 130 },
    { field: 'expiration', headerName: 'Expiration', width: 120 },
    { field: 'lastPrice', headerName: 'Price', width: 120 },
    {
      field: 'totalCost',
      headerName: 'Total Cost',
      width: 120,
      valueFormatter: (params: any) => `$${params.value.toFixed(2)}`,
    },
    { field: 'impliedVolatility', headerName: 'IV', width: 90 },
    { field: 'bid', headerName: 'Bid', width: 80 },
    { field: 'ask', headerName: 'Ask', width: 80 },
    { field: 'change', headerName: 'Change', width: 80 },
    { field: 'percentChange', headerName: '% Change', width: 100 },
    { field: 'volume', headerName: 'Volume', width: 100 },
    { field: 'openInterest', headerName: 'Open Interest', width: 150 },
  ];

  const targetPriceColumns = [
    { field: 'expiration', headerName: 'Expiration Date', width: 150 },
    {
      field: 'targetPrice',
      headerName: 'Target Price',
      width: 150,
      renderCell: (params: any) => (
        <TargetPriceInput
          value={params.value || ''}
          onChange={(event) => handleTargetPriceChangeByExpiration(params.row.expiration, event.target.value)}
          required
        />
      ),
    },
  ];

  useEffect(() => {
    calculateGridWidth();
  }, [options]);

  useEffect(() => {
    if (expirationDates.length > 0) {
      setSelectedExpiration(expirationDates[0]);
    }
  }, [expirationDates]);

  useEffect(() => {
    if (strikePrices.length > 0) {
      setSelectedStrike(strikePrices[0]);
    }
  }, [strikePrices]);

  const fetchExpirationDates = (ticker: string) => {
    fetch(`${process.env.REACT_APP_BACKEND}/option-expiration-dates/?ticker=${ticker}`)
      .then(response => response.json())
      .then(data => {
        setExpirationDates(data.expiration_dates);
        if (data.lastPrice !== undefined) {
          setLastPrice(data.lastPrice);
        }
        const telemetry = Telemetry.getInstance();
        telemetry.trackEvent('Options_Calculator_Expiration_Dates_Fetch', {Stock_Ticker: stockTicker, Option_Type: optionType, Position_Type: positionType, Success: true});
      })
      .catch((error) => {
        const telemetry = Telemetry.getInstance();
        telemetry.trackEvent('Options_Calculator_Expiration_Dates_Fetch', {Stock_Ticker: stockTicker, Option_Type: optionType, Position_Type: positionType, Success: false, Error: error});
      });
  };

  const fetchStrikePrices = (ticker: string, expiration: string, optionType: string) => {
    fetch(`${process.env.REACT_APP_BACKEND}/strike-prices/?ticker=${ticker}&expiration_date=${expiration}&option_type=${optionType}`)
      .then(response => response.json())
      .then(data => {
        setStrikePrices(data.strike_prices);
        const telemetry = Telemetry.getInstance();
        telemetry.trackEvent('Options_Calculator_Strikes_Fetch', {Stock_Ticker: stockTicker, Expiration: expiration,  Option_Type: optionType, Position_Type: positionType, Success: true});
      })
      .catch((error) => {
        const telemetry = Telemetry.getInstance();
        telemetry.trackEvent('Options_Calculator_Strikes_Fetch', {Stock_Ticker: stockTicker, Expiration: expiration,  Option_Type: optionType, Position_Type: positionType, Success: false, Error: error});
      });
  };

  const fetchOptionData = (ticker: string, expiration: string, optionType: string, strikePrice: number, positionType: string) => {
    fetch(`${process.env.REACT_APP_BACKEND}/option-metadata/?ticker=${ticker}&expiration_date=${expiration}&option_type=${optionType}&strike_price=${strikePrice}`)
      .then(response => response.json())
      .then(data => {
        const newOption: Option = {
          id: options.length + 1,
          expiration: data.expiration_date,
          optionType: data.option_type,
          strikePrice: data.strike_price,
          positionType,
          lastPrice: data.last_price,
          bid: data.bid,
          ask: data.ask,
          change: data.change,
          percentChange: data.percent_change,
          volume: data.volume,
          openInterest: data.open_interest,
          impliedVolatility: data.implied_volatility,
          targetPrice: 0,
          numContracts: 1,
          totalCost: 100 * data.last_price,
        };
        setOptions([...options, newOption]);
        const telemetry = Telemetry.getInstance();
        telemetry.trackEvent('Options_Calculator_Data_Fetch', {Stock_Ticker: stockTicker, Expiration: expiration,  Option_Type: optionType, Strike_Price: strikePrice, Position_Type: positionType, Success: true});
      })
      .catch((error) => {
        const telemetry = Telemetry.getInstance();
        telemetry.trackEvent('Options_Calculator_Data_Fetch', {Stock_Ticker: stockTicker, Expiration: expiration,  Option_Type: optionType, Strike_Price: strikePrice, Position_Type: positionType, Success: false, Error: error});
      });
  };

  const handleTargetPriceChangeByExpiration = (expiration: string, value: string) => {
    const parsedValue = value !== '' ? parseFloat(value) : 0;
    setOptions((prevOptions) =>
      prevOptions.map((option) =>
        option.expiration === expiration ? { ...option, targetPrice: parsedValue } : option
      )
    );
  };

  const handleTickerChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStockTicker(event.target.value);
  };

  const handleExpirationChange = (value: string) => {
    setSelectedExpiration(value);
  };

  const handleOptionTypeChange = (value: string) => {
    setOptionType(value);
  };

  const handleStrikeChange = (value: string) => {
    setSelectedStrike(parseFloat(value));
  };

  const handlePositionTypeChange = (value: string) => {
    setPositionType(value);
  };

  const handleEnter0 = () => {
    setExpirationDates([]);
    setSelectedExpiration('');
    setOptionType('CALL');
    setStrikePrices([]);
    setSelectedStrike(0);
    setPositionType('LONG');
    fetchExpirationDates(stockTicker);
    setOptions([]);
    setOptionsData([]);
    setSummaryData([]);
  };

  const handleEnter1 = () => {
    setStrikePrices([]);
    setSelectedStrike(0);
    setPositionType('LONG');
    fetchStrikePrices(stockTicker, selectedExpiration, optionType);
  };

  const handleAddOption = () => {
    fetchOptionData(stockTicker, selectedExpiration, optionType, selectedStrike, positionType);
  };

  const handleDeleteOption = (optionId: number) => {
    setOptions((prevOptions) => prevOptions.filter((option) => option.id !== optionId));
  };

  const handleCalculate = () => {
    const requestData = {
      options: JSON.stringify(options),
      currentStockPrice: lastPrice,
    };

    fetch(`${process.env.REACT_APP_BACKEND}/calculate-options/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestData),
    })
      .then((response) => response.json())
      .then((data) => {
        const optionsDataWithIds = data.optionsData.map((item: any, index: number) => ({
          id: index,
          ...item,
        }));

        const summaryDataWithIds = data.summaryData.map((item: any, index: number) => ({
          id: index,
          ...item,
        }));

        setOptionsData(optionsDataWithIds);
        setSummaryData(summaryDataWithIds);

        const telemetry = Telemetry.getInstance();
        telemetry.trackEvent('Options_Calculator_Fetch', {Stock_Ticker: stockTicker, Options: JSON.stringify(options), Success: true});
      })
      .catch((error) => {
        const telemetry = Telemetry.getInstance();
        telemetry.trackEvent('Options_Calculator_Fetch', {Stock_Ticker: stockTicker, Options: JSON.stringify(options), Success: false, Error: error});
      });
  };
 

  const calculateGridWidth = () => {
    const totalWidth = columns.reduce((accumulator, column) => accumulator + column.width, 0);
    setGridWidth(totalWidth);
  };

  const uniqueExpirationDates = Array.from(new Set(options.map((option) => option.expiration)));
  const targetPriceRows = uniqueExpirationDates.map((expiration) => {
    const targetPrice = options.find((option) => option.expiration === expiration)?.targetPrice || 0;
    return {
      id: expiration,
      expiration,
      targetPrice,
    };
  });

  useEffect(() => {
    const isValid =
      stockTicker !== '' &&
      selectedExpiration !== '' &&
      selectedStrike !== 0 &&
      targetPriceRows.every((row) => row.targetPrice !== 0);
    setIsCalculateEnabled(isValid);
  }, [stockTicker, selectedExpiration, selectedStrike, targetPriceRows]);


  return (
    <div>
      <TrplPageHeading title={pageTitle} />
      <TrplPageHeadingFootnote title={pageSubheading} />
      <Box sx={{ flexDirection: 'row', display: 'flex', gap: '6px', mt: 4 }}>
        <TrplInputTextField
          label="STOCK TICKER"
          height="4.7vh"
          fontSize="0.9rem"
          value={stockTicker}
          onChange={handleTickerChange}
        />
        <TrplBasicButton label="ENTER" height="4.7vh" width="4vw" onClick={handleEnter0} />
      </Box>
      <TrplPageInputText title={pageInputText} />
      {lastPrice !== undefined && lastPrice !== 0 && (
        <>
          <Box height="2vh" />
          <TrplPageSubheading title={`Stock Price: $${lastPrice.toFixed(2)}`} fontSize="16px" />
        </>
      )}
      {expirationDates.length > 0 && (
        <>
          <Box height="1vh" />
          <TrplPageSubheading title="Select Expiration and Option Type" fontSize="16px" />
          <Box height="1vh" />
          <Box sx={{ flexDirection: 'row', display: 'flex', gap: '6px', mb: 0.5 }}>
            <TrplDropdownMenu
              options={expirationDates}
              onChange={handleExpirationChange}
              height="4.7vh"
              width="8vw"
              endIcon={<KeyboardArrowDownIcon />}
              value={selectedExpiration}
            />
            <TrplDropdownMenu
              options={['CALL', 'PUT']}
              onChange={handleOptionTypeChange}
              height="4.7vh"
              width="6vw"
              endIcon={<KeyboardArrowDownIcon />}
            />
            <TrplBasicButton label="ENTER" height="4.7vh" width="4vw" onClick={handleEnter1} />
          </Box>
          <TrplPageInputText title="Select the expiration date and option type, then click enter to fetch the strike prices." />
        </>
      )}
      {strikePrices.length > 0 && (
        <>
          <Box height="3vh" />
          <TrplPageSubheading title="Select Strike Price and Position Type" fontSize="16px" />
          <Box height="1vh" />
          <Box sx={{ flexDirection: 'row', display: 'flex', gap: '6px', mb: 0.5 }}>
            <TrplDropdownMenu
              options={strikePrices.map((price) => price.toString())}
              onChange={handleStrikeChange}
              height="4.7vh"
              width="8vw"
              endIcon={<KeyboardArrowDownIcon />}
              value={selectedStrike.toString()}
            />
            <TrplDropdownMenu
              options={['LONG', 'SHORT']}
              onChange={handlePositionTypeChange}
              height="4.7vh"
              width="6vw"
              endIcon={<KeyboardArrowDownIcon />}
            />
            <TrplBasicButton label="ADD" height="4.7vh" width="4vw" onClick={handleAddOption} />
          </Box>
          <TrplPageInputText title="Select the strike price, position type, and click add to include the option in the data grid." />
        </>
      )}
      {options.length > 0 && (
        <>
          <Box height="3vh" />
          <TrplPageSubheading title="Selected Options" fontSize="18px" />
          <TrplPageHeadingFootnote title={selectedOptionsDescription} />
          <Box height="1vh" />
          <TrplDataGrid
            style={{ width: gridWidth, maxWidth: 1340}}
            columns={columns}
            rows={options}
            pageSize={10}
            rowsPerPageOptions={[10]}
            autoHeight
            hideFooter
          />
          <Box height="4vh" />
          <TrplPageSubheading title="Target Price(s)" fontSize="18px" />
          <TrplPageHeadingFootnote title={targetPriceDescription} />
          <Box height="1vh" />
          <TrplDataGrid
            style={{ width: 300 }}
            columns={targetPriceColumns}
            rows={targetPriceRows}
            pageSize={5}
            rowsPerPageOptions={[5]}
            autoHeight
            disableColumnMenu
            hideFooter
          />
          <Box height="4vh" />
          <TrplBasicButton label="CALCULATE P/L" height="4.7vh" width="12vw" onClick={handleCalculate} disabled={!isCalculateEnabled}/>
          <Box height="4vh" />
        </>
      )}
      {optionsData.length > 0 && summaryData.length > 0 && (
        <OptionsSummaryTable optionsData={optionsData} summaryData={summaryData} />
      )}
    </div>
  );
}

export default OptionsCalculator;