import React, { useState } from 'react';
import { Box, styled } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import TrplPageHeading from '../../../components/pages/PageHeading';
import TrplPageHeadingFootnote from '../../../components/pages/PageHeadingFootnote';
import TrplBasicButton from '../../../components/pages/BasicButton';
import Theme from '../../../Theme';
import TrplPageSubheading from '../../../components/pages/PageSubheading';
import TrplPageText from '../../../components/pages/PageText';
import TrplInputTextField from '../../../components/pages/InputTextField';
import TrplPageInputText from '../../../components/pages/PageInputText';
import TrplIVChart from './TrplIVChart';
import Telemetry from '../../../Telemetry';

const pageTitle = 'Stock Implied Volatility';
const pageSubheading = 'This model calculates the implied volatility and range for a stock.';

interface StockData {
  current_price: number;
  data: {
    'Time Period': string;
    'Implied Volatility': number;
    'High Price': number;
    'Low Price': number;
  }[];
}

const TrplDataGrid = styled(DataGrid)(({ theme }) => ({
  '& .MuiDataGrid-cell': {
    borderColor: theme.palette.divider,
    borderWidth: '.5px',
    borderStyle: 'solid',
    color: 'grey',
    fontFamily: 'RobotoMono',
    fontStyle: 'thin',
  },
  '& .MuiDataGrid-columnHeader': {
    borderColor: theme.palette.divider,
    borderWidth: '.5px',
    borderStyle: 'solid',
    fontFamily: 'RobotoMono',
    color: 'grey',
    fontStyle: 'normal',
    backgroundColor: "#eef7ed",
  },
  '& .MuiDataGrid-iconSeparator': {
    display: 'none',
  },
}));

const stockTableText = 'This table contains the implied volatility and price range for the stock for various time periods.';
const stockChartText = 'This chart displays the stock price range for the stock for various time periods.';
const pageInputText = 'Enter the stock ticker and time interval to execute the model.';

const StockVolatility: React.FC = () => {
  const [stockData, setStockData] = useState<StockData | null>(null);
  const [inputValue, setInputValue] = useState('');

  const fetchData = (ticker: string) => {
    fetch(`${process.env.REACT_APP_BACKEND}/get-stock-iv-and-range/?ticker=${ticker}`)
      .then(response => response.json())
      .then(data => {
        setStockData(data);
        const telemetry = Telemetry.getInstance();
        telemetry.trackEvent('Stock_Implied_Volatility_Fetch', { Stock_Ticker: inputValue, Success: true});
      })
      .catch(error => {
        
        const telemetry = Telemetry.getInstance();
        telemetry.trackEvent('Stock_Implied_Volatility_Fetch', { Stock_Ticker: inputValue, Success: false, Error: error});
      });
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const handleClick = () => {
    fetchData(inputValue);
  };

  const buildStockDataColumns = (): GridColDef[] => [
    { field: 'Time Period', headerName: 'Time Period', width: 150 },
    { field: 'Implied Volatility', headerName: 'Implied Volatility', width: 200 },
    { field: 'High Price', headerName: 'High Price', width: 150 },
    { field: 'Low Price', headerName: 'Low Price', width: 150 },
  ];

  const calculateTotalWidth = (columns: GridColDef[]): number => {
    return columns.reduce((accumulator, column) => accumulator + (column.width as number), 0);
  };

  const stockDataCols = buildStockDataColumns();
  const stockDataWidth = calculateTotalWidth(stockDataCols);

  return (
    <div>
      <TrplPageHeading title={pageTitle} />
      <TrplPageHeadingFootnote title={pageSubheading} />
      <Box sx={{ flexDirection: 'row', display: 'flex', gap: '6px', mt: 4, mb: .5}}>
        <TrplInputTextField
            label="STOCK TICKER"
            height="4.7vh"
            fontSize="0.9rem"
            value={inputValue}
            onChange={handleInputChange}
        />
        <TrplBasicButton label="ENTER" height="4.7vh" width="4vw" onClick={handleClick} />
      </Box>
      <TrplPageInputText title={pageInputText}/>
      <Box height="4vh" />
      {stockData && (
        <>
            <Box>
                <TrplPageSubheading title="Stock Volatility Chart" fontSize="18px" color={Theme.palette.primary.dark} />
                <TrplPageHeadingFootnote title={stockChartText} />
                <TrplIVChart
                    currentPrice={stockData.current_price}
                    highPrices={stockData.data.map(item => item['High Price'])}
                    lowPrices={stockData.data.map(item => item['Low Price'])}
                />
            </Box>
            <Box height="4vh" />
          <TrplPageSubheading title="Stock Volatility Table" fontSize="18px" color={Theme.palette.primary.dark} />
          <Box height="1vh" />
          <TrplPageHeadingFootnote title={stockTableText} />
          <Box height="3vh" />
          <TrplPageText title={`Stock Last Close: ${stockData.current_price}`} />
          <Box height="1vh" />
          <TrplDataGrid
            style={{ width: stockDataWidth }}
            columns={stockDataCols}
            rows={stockData.data.map((row, index) => ({ id: index, ...row }))}
            pageSize={12}
            rowsPerPageOptions={[12]}
            autoHeight
            disableColumnMenu
            hideFooter
          />
          <Box height="6vh" />
        </>
      )}
    </div>
  );
};

export default StockVolatility;