import { Menu, MenuType } from "../components/MenuInterface";

import TrplDashboardMenu from "./DashboardMenu";
import TrplTrainingMenu from "./TrainingMenu";


export const TrplMenuMap: Menu[] = [
  {
      name: 'DASHBOARD',
      menuList: TrplDashboardMenu,
      type: MenuType.Dashboard,
  },
];
