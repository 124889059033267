import React, { useState } from 'react';
import { Box, styled } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import TrplPageHeading from '../../../components/pages/PageHeading';
import TrplPageHeadingFootnote from '../../../components/pages/PageHeadingFootnote';
import TrplBasicButton from '../../../components/pages/BasicButton';
import Theme from '../../../Theme';
import TrplPageSubheading from '../../../components/pages/PageSubheading';
import TrplPageText from '../../../components/pages/PageText';
import TrplIVChart from './TrplIVChart';
import Telemetry from '../../../Telemetry';

const pageTitle = 'Market Implied Volatility';
const pageSubheading = 'This model calculates the implied volatility and range for the S&P 500 index.';

interface SpData {
  current_price: number;
  data: {
    'Time Period': string;
    'Implied Volatility': number;
    'High Price': number;
    'Low Price': number;
  }[];
}

const TrplDataGrid = styled(DataGrid)(({ theme }) => ({
  '& .MuiDataGrid-cell': {
    borderColor: theme.palette.divider,
    borderWidth: '.5px',
    borderStyle: 'solid',
    color: 'grey',
    fontFamily: 'RobotoMono',
    fontStyle: 'thin',
  },
  '& .MuiDataGrid-columnHeader': {
    borderColor: theme.palette.divider,
    borderWidth: '.5px',
    borderStyle: 'solid',
    fontFamily: 'RobotoMono',
    color: 'grey',
    fontStyle: 'normal',
    backgroundColor: "#eef7ed",
  },
  '& .MuiDataGrid-iconSeparator': {
    display: 'none',
  },
}));

const spTableText = 'This table contains the implied volatility and price range for the S&P 500 index for various time periods.';
const spChartText = 'This chart displays the Market price range for the index for various time periods.';

const MarketVolatility: React.FC = () => {
  const [spData, setSpData] = useState<SpData | null>(null);

  const fetchData = () => {
    fetch(`${process.env.REACT_APP_BACKEND}/calculate-sp500-iv-and-range/`)
      .then(response => response.json())
      .then(data => {
        setSpData(data);
        const telemetry = Telemetry.getInstance();
        telemetry.trackEvent('Market_Implied_Volatility_Fetch', { Success: true});
      })
      .catch(error => {
        
        const telemetry = Telemetry.getInstance();
        telemetry.trackEvent('Market_Implied_Volatility_Fetch', { Success: false, Error: error});
      });
  };

  const buildSpDataColumns = (): GridColDef[] => [
    { field: 'Time Period', headerName: 'Time Period', width: 150 },
    { field: 'Implied Volatility', headerName: 'Implied Volatility', width: 200 },
    { field: 'High Price', headerName: 'High Price', width: 150 },
    { field: 'Low Price', headerName: 'Low Price', width: 150 },
  ];

  const calculateTotalWidth = (columns: GridColDef[]): number => {
    return columns.reduce((accumulator, column) => accumulator + (column.width as number), 0);
  };

  const spDataCols = buildSpDataColumns();
  const spDataWidth = calculateTotalWidth(spDataCols);

  return (
    <div>
      <TrplPageHeading title={pageTitle} />
      <TrplPageHeadingFootnote title={pageSubheading} />
      <Box height="4vh" />
      <TrplBasicButton label="GET MARKET VOLATILITY" height="4.7vh" width="12vw" onClick={fetchData} />
      <Box height="4vh" />
      {spData && (
        <>
            <Box>
                <TrplPageSubheading title="Index Volatility Chart" fontSize="18px" color={Theme.palette.primary.dark} />
                <TrplPageHeadingFootnote title={spChartText} />
                <TrplIVChart
                    currentPrice={spData.current_price}
                    highPrices={spData.data.map(item => item['High Price'])}
                    lowPrices={spData.data.map(item => item['Low Price'])}
                />
            </Box>
            <Box height="4vh" />
          <TrplPageSubheading title="S&P 500 Volatility Table" fontSize="18px" color={Theme.palette.primary.dark} />
          <Box height="1vh" />
          <TrplPageHeadingFootnote title={spTableText} />
          <Box height="3vh" />
          <TrplPageText title={`Index Last Close: ${spData.current_price}`} />
          <Box height="1vh" />
          <TrplDataGrid
            style={{ width: spDataWidth }}
            columns={spDataCols}
            rows={spData.data.map((row, index) => ({ id: index, ...row }))}
            pageSize={12}
            rowsPerPageOptions={[12]}
            autoHeight
            disableColumnMenu
            hideFooter
          />
          <Box height="6vh" />
        </>
      )}
    </div>
  );
};

export default MarketVolatility;