import React from 'react';
import { EChartsOption } from 'echarts';
import ReactECharts from 'echarts-for-react';

import Theme from '../../Theme';

interface TrplBarChartProps {
  data: RangeDistribution[];
}

interface RangeDistribution {
  PctRange: string;
  Frequency: number;
  Probability: string;
}

const TrplBarChart: React.FC<TrplBarChartProps> = ({ data }) => {
  const xAxisData = data.map((item) => item.PctRange);
  const yAxisData = data.map((item) => item.Frequency);

  const options: EChartsOption = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'none',
      },
      textStyle: {
        fontFamily: 'ChivoMono', // Add your desired font here
        fontSize: 14, // Change the font size as needed
      },
      formatter: function (params : any) {
        const { value, name } = params[0];
        return `Range: ${name}<br />Frequency: ${value}`;
      },
    },
    grid: {
        bottom: '20%', // Increase this value if needed
      },
    xAxis: {
      type: 'category',
      data: xAxisData,
      name: '% Range',
      nameLocation: 'middle',
      nameGap: 110,
      nameTextStyle: {
        fontFamily: 'ChivoMono',
        fontSize: 16,
      },
      axisLabel: {
        fontFamily: 'ChivoMono',
        fontSize: 13,
        interval: 0,
        rotate: 45,
        margin: 15,
      },
    },
    yAxis: {
      type: 'value',
      name: 'Frequency',
      nameLocation: 'middle',
      nameGap: 82,
      splitNumber: 5,
      nameTextStyle: {
        fontFamily: 'ChivoMono',
        fontSize: 16,
      },
      axisLabel: {
        fontFamily: 'ChivoMono',
        fontSize: 13,
        rotate: 0,
      },
    },
    series: [
      {
        data: yAxisData,
        type: 'bar',
        itemStyle: {
          color: Theme.palette.secondary.light,
        },
      },
    ],
  };

  return (
    <div style={{ height: '700px', width: '1000px' }}>
      <ReactECharts option={options} style={{ height: '100%' }} />
    </div>
  );
};

export default TrplBarChart;
