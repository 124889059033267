import { styled } from '@mui/material/styles';

import Button from '@mui/material/Button';

import TrplTheme from '../Theme';

export default function TrplTextButton(props: any) {
    
    const StyledButton = styled(Button)({
        '&:hover': {
            color: TrplTheme.palette.secondary.light,
            backgroundColor: 'transparent',
        },
        
        color: props.color ?  props.color : TrplTheme.palette.primary.main,
        fontFamily: props.fontFamily ? props.fontFamily : TrplTheme.typography.fontFamily,
        fontSize: props.fontSize,
        padding: props.padding,
        transform: props.transform,
        paddingLeft: props.mx,
        paddingRight: props.mx,
        textTransform: 'none',
    });

    return (
        <StyledButton 
            disableRipple={props.disableRipple}
            id={props.id}
            variant="text"
            onClick={props.onClick}
            endIcon={props.endIcon}
            startIcon={props.startIcon}
        >
            {props.label}
        </StyledButton>
    );
}