import React from 'react';
import { ECharts, init as initECharts } from 'echarts';
import { EChartsOption } from 'echarts';
import { useEffect, useRef } from 'react';

interface PerformanceData {
  Date: string;
  Open: number;
  High: number;
  Low: number;
  Close: number;
}

interface CandlestickChartProps {
  data: PerformanceData[];
}

const CandlestickChart: React.FC<CandlestickChartProps> = ({ data }) => {
  const chartRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (chartRef.current) {
      const chart: ECharts = initECharts(chartRef.current);
      const options: EChartsOption = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross'
          },
          textStyle: {
            fontFamily: 'ChivoMono',
            fontSize: 14,
          },
          formatter: function (params: any) {
            const data = params[0].data;
            const date = params[0].axisValue;
            return `
              Date: ${date}<br/>
              Open: ${data[1]}<br/>
              Close: ${data[2]}<br/>
              Low: ${data[3]}<br/>
              High: ${data[4]}
            `;
          }
        },
        xAxis: {
          name: 'Date',
          nameLocation: 'middle',
          nameGap: 50,
          nameTextStyle: {
            fontFamily: 'ChivoMono',
            fontSize: 16,
          },
          axisLabel: {
            fontFamily: 'ChivoMono',
            fontSize: 13,
            interval: 6,
            margin: 15,
            rotate: 20,
          },
          data: data.map(item => item.Date),
        },
        yAxis: {
          name: 'Price',
          nameLocation: 'middle',
          nameGap: 80,
          splitNumber: 5,
          nameTextStyle: {
            fontFamily: 'ChivoMono',
            fontSize: 16,
          },
          axisLabel: {
            fontFamily: 'ChivoMono',
            fontSize: 13,
            rotate: 0,
          },
          scale: true,
        },
        series: [{
          type: 'candlestick',
          data: data.map(item => [item.Open, item.Close, item.Low, item.High]),
          itemStyle: {
            color: '#64c864', // color for up days
            color0: '#C86464', // color for down days
            borderColor: '#64c864',
            borderColor0: '#C86464'
          },
        }],
      };

      chart.setOption(options);
    }
  }, [data]);

  return (
    <div ref={chartRef} style={{width: '100%', height: '600px'}}></div>
  );
};

export default CandlestickChart;