import React from 'react';
import { ECharts, init as initECharts } from 'echarts';
import { EChartsOption } from 'echarts';
import { useEffect, useRef } from 'react';

interface IPriceData {
    Date: string;
    Open: number;
    High: number;
    Low: number;
    Close: number;
}

interface CandlestickChartProps {
    data: IPriceData[];
    levels: number[];
}

const SupportResistanceChart: React.FC<CandlestickChartProps> = ({ data, levels }) => {
    const chartRef = useRef<HTMLDivElement | null>(null);

    const minLow = Math.min(...data.map(item => item.Low));
    const maxHigh = Math.max(...data.map(item => item.High));

    const yAxisMin = Math.round(minLow * 0.95);
    const yAxisMax = Math.round(maxHigh * 1.01);

    console.log(data);

    useEffect(() => {
        if (chartRef.current) {
            const chart: ECharts = initECharts(chartRef.current);

            const options: EChartsOption = {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross'
                    },
                    textStyle: {
                        fontFamily: 'ChivoMono',
                        fontSize: 14,
                    },
                    formatter: function (params: any) {
                        const data = params[0].data;
                        const date = params[0].axisValue;
                        return `
                            Date: ${date}<br/>
                            Open: ${data[1]}<br/>
                            Close: ${data[2]}<br/>
                            Low: ${data[3]}<br/>
                            High: ${data[4]}
                        `;
                    }
                },
                xAxis: {
                    name: 'Date',
                    nameLocation: 'middle',
                    nameGap: 70,
                    nameTextStyle: {
                        fontFamily: 'ChivoMono',
                        fontSize: 16,
                    },
                    axisLabel: {
                        fontFamily: 'ChivoMono',
                        fontSize: 13,
                        interval: 5,
                        margin: 12,
                        rotate: 30,
                    },
                    data: data.map(item => {
                        const date = new Date(item.Date);
                        return date.toISOString().split('T')[0]; 
                    }),
                },
                grid: {
                    bottom: '5%',  // Increase as needed to give more room at the bottom
                    containLabel: true,
                },
                yAxis: {
                    name: 'Price ($)',
                    nameLocation: 'middle',
                    nameGap: 50,
                    min: yAxisMin,
                    max: yAxisMax,
                    splitLine: {
                        show: false,
                    },
                    nameTextStyle: {
                        fontFamily: 'ChivoMono',
                        fontSize: 16,
                        padding: [0, -90, 0, 0]
                    },
                    axisLabel: {
                        fontFamily: 'ChivoMono',
                        fontSize: 13,
                        interval: 20,
                        rotate: 0,
                    },
                },
                series: [{
                    type: 'candlestick',
                    data: data.map(item => [item.Open, item.Close, item.Low, item.High]),
                    itemStyle: {
                        color: '#64c864',
                        color0: '#C86464',
                        borderColor: '#64c864',
                        borderColor0: '#C86464'
                    },
                    markLine: {
                        silent: true,
                        symbol: 'none', // hides the dot on the left
                        label: {
                            show: false, // hides the label after the arrow
                        },
                        lineStyle: {
                            color: 'grey',
                        },
                        data: levels.map(level => ({yAxis: level}))
                    },                    
                }],
            };

            chart.setOption(options);
        }
    }, [data, levels]);

    return (
        <div ref={chartRef} style={{ width: '100%', height: '600px' }}></div>
    );
};

export default SupportResistanceChart;
