import { Box, styled } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import React, { useState } from 'react';

import TrplInputTextField from '../../../components/pages/InputTextField';
import TrplPageHeading from '../../../components/pages/PageHeading';
import TrplPageHeadingFootnote from '../../../components/pages/PageHeadingFootnote';
import TrplBasicButton from '../../../components/pages/BasicButton';
import TrplPageInputText from '../../../components/pages/PageInputText';

import Theme from '../../../Theme';
import TrplPageSubheading from '../../../components/pages/PageSubheading';
import TrplPageText from '../../../components/pages/PageText';
import CandlestickChart from '../../../components/pages/CandlestickChart';
import Telemetry from '../../../Telemetry';

const pageTitle = 'Portfolio Performance';
const pageSubheading = 'This model computes the historical performance of a given portfolio by calculating an equal weighted index.';
const perfDefinition = 'The candlestick chart contains the monthly returns of the portfolio for the past 5 years.';
const pageInputText = 'Enter the stock tickers separated by commas in your portfolio to obtain the historical performance.';

  const TrplDataGrid = styled(DataGrid)(({ theme }) => ({   
    '& .MuiDataGrid-cell': {
      borderColor: theme.palette.divider,
      borderWidth: '.5px',
      borderStyle: 'solid',
      color: 'grey',
      fontFamily: 'RobotoMono', // Add your desired font here
      fontStyle: 'thin',
    },
    '& .MuiDataGrid-columnHeader': {
      borderColor: theme.palette.divider,
      borderWidth: '.5px',
      borderStyle: 'solid',
      fontFamily: 'RobotoMono', // Add your desired font here
      color: 'grey',
      fontStyle: 'normal',
      backgroundColor: "#eef7ed",
    },
    '& .MuiDataGrid-iconSeparator': {
        display: 'none',
      },
      overflow: 'auto',
  }));

  interface PerformanceData {
    Date: string;
    Open: number;
    High: number;
    Low: number;
    Close: number;
  }
  
  interface PerformanceResponse {
    performance: string;
    performance_matrix: PerformanceData[];
  }
  

export default function TrplPerformance() {
  const [perfData, setPerfData] = useState<PerformanceResponse | null>(null);
  const fetchData = (stockTicker: string) => {
    fetch(`${process.env.REACT_APP_BACKEND}/portfolio-perf/?stockTicker=${stockTicker}`)
      .then(response => response.text())
      .then(data => {
        const parsedData: PerformanceResponse = JSON.parse(data);
        setPerfData(parsedData);
        const telemetry = Telemetry.getInstance();
        telemetry.trackEvent('Portfolio_Performance_Fetch', {Portfolio_Positions: stockTicker, Success: true});
      })
    .catch(error => {
        const telemetry = Telemetry.getInstance();
        telemetry.trackEvent('Portfolio_Performance_Fetch', { Portfolio_Positions: stockTicker, Success: false, Error: error});
    });
  };

  const [inputValue, setInputValue] = useState('');
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const handleClick = () => {
    fetchData(inputValue);
  };

  return (
    <div>
      <TrplPageHeading title={pageTitle} />
      <TrplPageHeadingFootnote title={pageSubheading} />
      <Box sx={{ flexDirection: 'row', display: 'flex', gap: '6px', mt: 4, mb: 0.5 }}>
        <TrplInputTextField
          label="PORTFOLIO TICKERS"
          height="4.7vh"
          width="25vw"
          fontSize="0.9rem"
          value={inputValue}
          onChange={handleInputChange}
        />
        <TrplBasicButton label="ENTER" height="4.7vh" width="4vw" onClick={handleClick} />
      </Box>
      <TrplPageInputText title={pageInputText} />
      <Box height="4vh" />
      {perfData && (
        <div style={{width: '100%' }}>
          <TrplPageSubheading title='Overall Performance' fontSize="18px" color={Theme.palette.primary.dark} />
          <Box height="1vh" />
          <TrplPageText title={`The overall portfolio performance over the past 5 years is: ${perfData.performance}%.`}/>
          <Box height="2vh" />
          <TrplPageSubheading title='Performance Index' fontSize="18px" color={Theme.palette.primary.dark} />
          <TrplPageHeadingFootnote title={perfDefinition} />
          <Box height="1vh" />
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <CandlestickChart data={perfData.performance_matrix} />
          </Box>
          <Box height="5vh" />
        </div>
      )}
    </div>
  );
}
