// HistoricalVolatilityTable.tsx
import React from 'react';
import { GridColDef } from '@mui/x-data-grid';
import { sigma_distribution, TrplDataGrid } from './StockGPTConstants';


interface HistoricalVolatilityTableProps {
  sigmaDistribution: sigma_distribution[];
}

const sigmaDistributionColumns: GridColDef[] = [
  { field: 'Sigma', headerName: 'Sigma', width: 100 },
  { field: 'PctRange', headerName: 'Percentage Range', width: 200 },
  { field: 'PriceRange', headerName: 'Price Range', width: 200 },
  { field: 'Probability', headerName: 'Probability', width: 150 },
];

const HistoricalVolatilityTable: React.FC<HistoricalVolatilityTableProps> = ({ sigmaDistribution }) => {
  const sigmaDistributionWidth = sigmaDistributionColumns.reduce(
    (total, column) => total + (column.width || 0),
    0
  );

  return (
    <TrplDataGrid
      style={{ width: sigmaDistributionWidth }}
      columns={sigmaDistributionColumns}
      rows={sigmaDistribution.map((row, index) => ({ id: index, ...row }))}
      pageSize={10}
      rowsPerPageOptions={[10]}
      autoHeight
      disableColumnMenu
      hideFooter
    />
  );
};

export default HistoricalVolatilityTable;