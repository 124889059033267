import React, { useState } from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Box, styled } from '@mui/material';

import TrplInputTextField from '../../../components/pages/InputTextField';
import TrplPageHeading from '../../../components/pages/PageHeading';
import TrplPageHeadingFootnote from '../../../components/pages/PageHeadingFootnote';
import TrplBasicButton from '../../../components/pages/BasicButton';
import TrplPageInputText from '../../../components/pages/PageInputText';
import TrplPageSubheading from '../../../components/pages/PageSubheading';
import Theme from '../../../Theme';
import LoadingErrorDisplay from '../../../components/pages/LoadingErrorDisplay';

const pageTitle = 'Bull Calendar Spread Pricing';
const pageSubheading = 'This model calculates the optimal bull calendar spread pricing for an asset, considering different expiration dates for buying and selling options.';
const pageInputText = 'Enter the stock ticker, position size, and target price to execute the model.';
const callTableText = 'This table contains the optimal calendar spread for the target price sorted by risk-reward profiles.';

const TrplDataGrid = styled(DataGrid)(({ theme }) => ({
  '& .MuiDataGrid-cell': {
    borderColor: theme.palette.divider,
    borderWidth: '.5px',
    borderStyle: 'solid',
    color: 'grey',
    fontFamily: 'RobotoMono',
    fontStyle: 'thin',
  },
  '& .MuiDataGrid-columnHeader': {
    borderColor: theme.palette.divider,
    borderWidth: '.5px',
    borderStyle: 'solid',
    fontFamily: 'RobotoMono',
    color: 'grey',
    fontStyle: 'normal',
    backgroundColor: "#eef7ed",
  },
  '& .MuiDataGrid-iconSeparator': {
    display: 'none',
  },
}));

interface StockData {
  id: number;
  BuyOptionDate: string;
  SellOptionDate: string;
  'Buy at Strike': number;
  'Buy Price': number;
  'Cost of Buy': number;
  'Sell at Strike': number;
  'Sell Price': number;
  'Cost of Sell': number;
  'Max Loss': number;
  'Max Profit': number;
  'Risk-Reward': number;
  'Max Price': number;
  'Scaled Vol': number;
}

function BullCalendarSpread() {
  const [stockData, setStockData] = useState<StockData[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<{ message: string; code?: number } | null>(null);

  const fetchData = async (stockTicker: string, positionSize: string, targetPrice: string) => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND}/bullcalendar-spread-options/?stockTicker=${stockTicker}&positionSize=${positionSize}&targetPrice=${targetPrice}`);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      if (data.CalendarSpread) {
        const callOptionsWithIds = JSON.parse(data.CalendarSpread).map((option: any, index: number) => ({
          ...option,
          id: index,
        }));
        setStockData(callOptionsWithIds);
      } else {
        throw new Error('Invalid data format');
      }
    } catch (error: any) {
      setError({ message: error.toString(), code: error.status });
    } finally {
      setIsLoading(false);
    }
  };

  const [tickerValue, setTickerValue] = useState('');
  const [positionSize, setPositionSize] = useState('');
  const [targetPrice, setTargetPrice] = useState('');

  const columns: GridColDef[] = [
    { field: 'BuyOptionDate', headerName: 'BUY DATE', width: 150 },
    { field: 'Buy at Strike', headerName: 'BUY STRIKE', width: 100 },
    { field: 'Buy Price', headerName: 'BUY PRICE', width: 100 },
    { field: 'Cost of Buy', headerName: 'BUY COST', width: 100 },
    { field: 'SellOptionDate', headerName: 'SELL DATE', width: 150 },
    { field: 'Sell at Strike', headerName: 'SELL STRIKE', width: 110 },
    { field: 'Sell Price', headerName: 'SELL PRICE', width: 100 },
    { field: 'Cost of Sell', headerName: 'SELL COST', width: 100 },
    { field: 'Max Profit', headerName: 'MAX PROFIT', width: 120 },
  ];

  return (
    <div>
      <TrplPageHeading title={pageTitle} />
      <TrplPageHeadingFootnote title={pageSubheading} />
      <Box sx={{ flexDirection: 'row', display: 'flex', gap: '6px', mt: 4, mb: .5 }}>
        <TrplInputTextField label='STOCK TICKER' height='4.7vh' fontSize='0.9rem' value={tickerValue} onChange={(e) => setTickerValue(e.target.value)} />
        <TrplInputTextField label='POSITION SIZE' height='4.7vh' width='8vw' fontSize='0.9rem' value={positionSize} onChange={(e) => setPositionSize(e.target.value)} />
        <TrplInputTextField label='TARGET PRICE' height='4.7vh' width='8vw' fontSize='0.9rem' value={targetPrice} onChange={(e) => setTargetPrice(e.target.value)} />
        <TrplBasicButton label='ENTER' height='4.7vh' width='4vw' onClick={() => fetchData(tickerValue, positionSize, targetPrice)} />
      </Box>
      <TrplPageInputText title={pageInputText} />
      <Box height="4vh" />

      <LoadingErrorDisplay isLoading={isLoading} error={error} />
      {!isLoading && !error && stockData.length > 0 && (
        <>
          <TrplPageSubheading title="Optimal Bull Calendar Spreads" fontSize="18px" color={Theme.palette.primary.dark} />
          <Box height={"1vh"} />
          <TrplPageHeadingFootnote title={callTableText} />
          <Box height={"1vh"} />
          <TrplDataGrid
            style={{ width: 1030, height: 630 }}
            rows={stockData}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[10]}
            checkboxSelection={false}
          />
          <Box height={"10vh"} />
        </>
      )}
    </div>
  );
}

export default BullCalendarSpread;
