import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { LicenseInfo } from '@mui/x-license-pro';
import { WorkOS } from '@workos-inc/node';
import MainPage from './MainPage';
import { AuthCallback } from './AuthCallback';
import LearnMore from './LearnMore';
import SignIn from './SignIn';
import useLearnMore from './UseLearnMore';
import UnsupportedDeviceMessage from './UnsupportedDeviceMessage';
import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import TrplTheme from './Theme';
import { isMobile } from 'react-device-detect';

LicenseInfo.setLicenseKey(`${process.env.LICENSE_KEY}`);

export default function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const workos = new WorkOS(`${process.env.REACT_AUTH_KEY}`);
  const { showLearnMore, handleBack } = useLearnMore();

  useEffect(() => {
    const storedAuthState = localStorage.getItem('PrismFI_isAuthenticated');
    if (storedAuthState === 'true') {
      setIsAuthenticated(true);
    }
    setIsLoading(false);
  }, []);

  const handleLogin = async () => {
    try {
      const redirectUri = `${process.env.REACT_APP_REDIRECT_URI}`;
      const clientId = `${process.env.REACT_APP_AUTH_CLIENT}`;
      const authorizationUrl = await workos.userManagement.getAuthorizationUrl({
        provider: 'authkit',
        redirectUri,
        clientId,
      });
      window.location.href = authorizationUrl;
    } catch (error) {
      console.error('Error during WorkOS login:', error);
    }
  };

  if (isLoading) {
    return null;
  }

  if (isMobile) {
    return (
      <ThemeProvider theme={TrplTheme}>
        <CssBaseline />
        <UnsupportedDeviceMessage />
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={TrplTheme}>
      <CssBaseline />
      <BrowserRouter>
        <Routes>
          <Route path="/*" element={isAuthenticated ? <MainPage /> : <SignIn handleLogin={handleLogin} />} />
          <Route path="/auth/redirect" element={<AuthCallback setIsAuthenticated={setIsAuthenticated} />} />
          <Route path="/about" element={<LearnMore handleBack={handleBack} handleLogin={handleLogin} />} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}