import React, { useEffect, useRef } from 'react';
import { ECharts, init as initECharts } from 'echarts';
import { EChartsOption } from 'echarts';

interface IPriceData {
    Date: string;
    Open: number;
    High: number;
    Low: number;
    Close: number;
    Adjusted_close: number;
    Volume: number;
}

interface IBreakout {
    Date: string;
    BreakoutUp: number | null;
    BreakoutDown: number | null;
}

interface IData {
    PriceData: IPriceData[];
    TrendlineMin: number[];
    TrendlineMax: number[];
    Breakouts: IBreakout[];
}

interface CandlestickChartProps {
    data: IData;
}

const TrendlineChart: React.FC<CandlestickChartProps> = ({ data }) => {
    const chartRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        if (chartRef.current && data) {
            const chart: ECharts = initECharts(chartRef.current);

            const chartData = data; // Assuming single data set for now

            // Map price data for candlestick chart
            const priceData = chartData.PriceData.map(item => [new Date(item.Date).getTime(), item.Open, item.Close, item.Low, item.High]);

            // Prepare trendline data
            const trendLineMinData = chartData.TrendlineMin.map((value, index) => [new Date(chartData.PriceData[index].Date).getTime(), value]);
            const trendLineMaxData = chartData.TrendlineMax.map((value, index) => [new Date(chartData.PriceData[index].Date).getTime(), value]);

            const upBreakouts = chartData.Breakouts
            .map((item, index) => item.BreakoutUp !== null ? [index, (item.BreakoutUp) * 0.97] : null)
            .filter((item): item is number[] => item !== null);
        
            const downBreakouts = chartData.Breakouts
                .map((item, index) => item.BreakoutDown !== null ? [index, item.BreakoutDown * 1.03] : null)
                .filter((item): item is number[] => item !== null);
        
            // Set chart options
            const options: EChartsOption = {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross'
                    },
                    textStyle: {
                        fontFamily: 'ChivoMono',
                        fontSize: 14,
                    },
                    formatter: function (params: any) {
                        const data = params[0].data;
                        const date = params[0].axisValue;
                        return `
                            Date: ${date}<br/>
                            Open: ${data[1]}<br/>
                            Close: ${data[2]}<br/>
                            Low: ${data[3]}<br/>
                            High: ${data[4]}
                        `;
                    }
                },
                xAxis: {
                    name: 'Date',
                    nameLocation: 'middle',
                    nameGap: 70,
                    nameTextStyle: {
                        fontFamily: 'ChivoMono',
                        fontSize: 16,
                    },
                    axisLabel: {
                        fontFamily: 'ChivoMono',
                        fontSize: 13,
                        interval: 5,
                        margin: 12,
                        rotate: 30,
                    },
                    data: chartData.PriceData.map(item => {
                        const date = new Date(item.Date);
                        return date.toISOString().split('T')[0]; 
                    }),
                },
                grid: {
                    bottom: '5%',  // Increase as needed to give more room at the bottom
                    containLabel: true,
                },
                yAxis: {
                    name: 'Price ($)',
                    nameLocation: 'middle',
                    nameGap: 50,
                    min: Math.round(Math.min(...data.PriceData.map(item => item.Low)) * 0.95),
                    max: Math.round(Math.max(...data.PriceData.map(item => item.High)) * 1.02),
                    splitLine: {
                        show: false,
                    },
                    nameTextStyle: {
                        fontFamily: 'ChivoMono',
                        fontSize: 16,
                        padding: [0, -90, 0, 0]
                    },
                    axisLabel: {
                        fontFamily: 'ChivoMono',
                        fontSize: 13,
                        interval: 20,
                        rotate: 0,
                    },
                },
                series: [
                    {
                        type: 'candlestick',
                        data: priceData.map(item => [item[1], item[2], item[3], item[4]]),
                        itemStyle: {
                            color: '#64c864',
                            color0: '#C86464',
                            borderColor: '#64c864',
                            borderColor0: '#C86464'
                        }
                    },
                    {
                        type: 'scatter',
                        symbol: 'triangle',
                        symbolSize: 11,
                        itemStyle: {
                            color: 'darkgreen'
                        },
                        data: upBreakouts,
                    },
                    {
                        type: 'scatter',
                        symbol: 'path://M512 273.067l-438.4-273.067h876.8l-438.4 273.067z',
                        symbolSize: 11,
                        itemStyle: {
                            color: 'maroon'
                        },
                        data: downBreakouts,
                    },
                    {
                        type: 'line',
                        data: trendLineMinData.map(item => item[1]),
                        smooth: true,
                        lineStyle: {
                            type: 'dashed',
                            color: 'grey',
                        },
                        showSymbol: false // Add this
                    },
                    {
                        type: 'line',
                        data: trendLineMaxData.map(item => item[1]),
                        smooth: true,
                        lineStyle: {
                            type: 'dashed',
                            color: 'grey',
                        },
                        showSymbol: false // Add this
                    },
                ]
            };

            chart.setOption(options);
        }
    }, [data]);

    return (
        <div ref={chartRef} style={{ width: '100%', height: '600px' }}></div>
    );
};

export default TrendlineChart;
