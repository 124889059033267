// PositionExposurePieChart.tsx
import React, { useEffect, useRef } from 'react';
import * as echarts from 'echarts';

interface PositionExposureData {
  ticker: string;
  net_exposure: number;
  absolute_net_exposure: number;
  size: number;
  exposure: string;
}

interface PositionExposurePieChartProps {
  data: PositionExposureData[];
}

const PositionExposurePieChart: React.FC<PositionExposurePieChartProps> = ({ data }) => {
  const chartRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (chartRef.current) {
      const chart = echarts.init(chartRef.current);

      const options = {
        tooltip: {
          trigger: 'item',
          formatter: (params: any) => {
            const { ticker, net_exposure, absolute_net_exposure, size, exposure } = params.data;
            return `
              Ticker: ${ticker}<br/>
              Net Exposure: ${Math.round(net_exposure)}<br/>
              Absolute Net Exposure: ${Math.round(absolute_net_exposure)}<br/>
              Size: ${Math.round(size)}<br/>
              Exposure: ${exposure}
            `;
          },
          textStyle: {
            fontFamily: 'ChivoMono',
            fontSize: 14,
          },
        },
        series: [
          {
            type: 'pie',
            data: data.map((item) => ({
              name: item.ticker,
              value: Math.round(item.absolute_net_exposure),
              ticker: item.ticker,
              net_exposure: Math.round(item.net_exposure),
              absolute_net_exposure: Math.round(item.absolute_net_exposure),
              size: Math.round(item.size),
              exposure: item.exposure,
            })),
            label: {
              fontFamily: 'ChivoMono',
              fontSize: 14,
            },
          },
        ],
      };

      chart.setOption(options);

      return () => {
        chart.dispose();
      };
    }
  }, [data]);

  return <div ref={chartRef} style={{ width: '100%', height: '400px' }}></div>;
};

export default PositionExposurePieChart;