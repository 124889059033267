import { Typography, Box, Link } from '@mui/material';
import TrplTheme from '../../Theme';

export default function TrplPageSourceText({ text }: { text: string }) {
  // Split the text into points based on the delimiter (src:URL)
  const points = text.split(/(\(src:[^)]*\))/).filter((part) => part.trim() !== '');

  return (
    <Box sx={{ marginRight: '30px' }}>
      <ul style={{ color: TrplTheme.palette.primary.main }}>
        {points.map((point, index) => {
          // Check if the point is a source URL
          if (point.startsWith('(src:')) {
            // Extract the URL from the source
            const url = point.slice(5, -1);

            // Find the corresponding text for the source
            const textIndex = index - 1;
            let pointText = points[textIndex].trim();

            // Remove the numbered list from the point text
            pointText = pointText.replace(/^\d+\.\s*/, '');

            return (
              <li key={index}>
                <Typography
                  variant="h6"
                  sx={{
                    fontFamily: 'RobotoMono, monospace',
                    fontStyle: 'regular',
                    fontSize: '0.9rem',
                    color: TrplTheme.palette.primary.main,
                  }}
                >
                  {pointText}
                  <sup>
                    <Link href={url} target="_blank" rel="noopener" style={{ fontSize: '0.7rem' }}>
                      [{textIndex / 2 + 1}]
                    </Link>
                  </sup>
                </Typography>
              </li>
            );
          }
          return null;
        })}
      </ul>
    </Box>
  );
}