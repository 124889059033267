import { Box } from '@mui/material';
import React, { useState } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import TrplDropdownMenu from '../../../components/pages/DropdownMenu';

import TrplPageHeading from '../../../components/pages/PageHeading';
import TrplPageHeadingFootnote from '../../../components/pages/PageHeadingFootnote';
import TrplBasicButton from '../../../components/pages/BasicButton';
import TrplPageInputText from '../../../components/pages/PageInputText';
import PresidentialCycleGrid from './PresidentialCycleDataGrid';
import TrplInputTextField from '../../../components/pages/InputTextField';
import Telemetry from '../../../Telemetry';

const pageTitle = 'Stock Cycle';
const pageSubheading = 'This model measures the seasonality of the stock and slices it by the 4 year cycle.';
const pageInputText = 'Select a sector and time interval to execute the model.';
const pageYearText = 'Change the cycle year through this selection.';

const timeOptions = ['1 MONTH', '1 QUARTER'];
const yearOptions = ['YEAR 1', 'YEAR 2', 'YEAR 3', 'YEAR 4'];

function getBackendTimeInterval(timeInterval: string): string {
  switch (timeInterval) {
    case '1 MONTH':
      return '1mo';
    case '1 QUARTER':
      return '3mo';
    default:
      return '';
  }
}

function getYearDisplayOption(year: string): number {
    switch (year) {
      case 'YEAR 1':
        return 0;
      case 'YEAR 2':
        return 1;
      case 'YEAR 3':
        return 2;
      case 'YEAR 4':
        return 3;
      default:
        return -1;
    }
  }

interface SectorCycleFrame {
    mean: number;
    median: number;
    min: number;
    max: number;
    last: number;
    cycleLabel: string;
}

const getYearlyBuckets = ( array: SectorCycleFrame[] ) : SectorCycleFrame[][] => {
    const result: SectorCycleFrame[][] = [];
    const chunkSize = Math.ceil(array.length / 4);
  
    for (let i = 0; i < array.length; i += chunkSize) {
      result.push(array.slice(i, i + chunkSize));
    }
  
    return result;
  };

export default function StockCycle() {
    const [cycleYearlyData, setCycleYearlyData] = useState<SectorCycleFrame[][] | null>(null);
    const fetchData = (index: string, timeInterval: string) => {
    fetch(`${process.env.REACT_APP_BACKEND}/stock-cycle/?stock=${index}&timeInterval=${timeInterval}`)
    .then(response => response.json())
    .then(data => {
        const parsedData = JSON.parse(data);
        setCycleYearlyData(getYearlyBuckets(parsedData.bucket_frame));
        const telemetry = Telemetry.getInstance();
        telemetry.trackEvent('Cycles_Stock_Fetch', {Stock_Ticker: index, Time_Interval: timeInterval, Success: true});
      })
      .catch((error) => {
        const telemetry = Telemetry.getInstance();
        telemetry.trackEvent('Cycles_Stock_Fetch', {Stock_Ticker: index, Time_Interval: timeInterval, Success: false, Error: error});
      });
  };

  const [selectedYearOption, setSelectedYearOption] = useState(new Date().getFullYear() % 4);

  const handleClick = () => {
    fetchData(inputValue, getBackendTimeInterval(selectedTimeOption));
  };
  
  const [inputValue, setInputValue] = useState('');
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };


  const [selectedTimeOption, setSelecteTimeOption] = useState(timeOptions[0]);
  const handleTimeDropdownChange = (value: string) => {
    setSelecteTimeOption(value);
  }; 

  const handleYearDropdownChange = (value: string) => {
    setSelectedYearOption(getYearDisplayOption(value));
  };  

  return (
    <div>
        <TrplPageHeading title={pageTitle}/>
        <TrplPageHeadingFootnote title={pageSubheading} />
        <Box sx={{ flexDirection: 'row', display: 'flex', gap: '6px', mt: 4, mb: .5}}>
         <TrplInputTextField label='STOCK TICKER' height='4.7vh' fontSize='0.9rem' value={inputValue} onChange={handleInputChange} />
          <TrplDropdownMenu options={timeOptions} onChange={handleTimeDropdownChange} height='4.7vh' width='8vw' endIcon={<KeyboardArrowDownIcon />}/>
          <TrplBasicButton label='ENTER' height='4.7vh' width='4vw' onClick={handleClick}/>
        </Box>
        <TrplPageInputText title={pageInputText}/>
        <Box height="2vh"/>
        {cycleYearlyData &&
        <>
          <Box height="1vh"/>
          <TrplDropdownMenu options={yearOptions} onChange={handleYearDropdownChange} height='4.7vh' width='8vw' endIcon={<KeyboardArrowDownIcon />}/>
          <Box height=".5vh"/>
          <TrplPageInputText title={pageYearText}/>
          <Box height="3vh"/>
          <PresidentialCycleGrid yearly_cycle={cycleYearlyData[selectedYearOption]} />
          </>
        }

    </div>
  );
}
