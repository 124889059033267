import * as React from 'react';

export function useDropdownMenu(initialLabel: string, onChange: (label: string) => void) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedLabel, setSelectedLabel] = React.useState(initialLabel);

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (label: string) => {
    if (selectedLabel !== label) {
      setSelectedLabel(label);
      onChange(label);
    }
    setAnchorEl(null);
  };

  return { anchorEl, selectedLabel, open, handleClick, handleClose };
}
