import React from "react";
import { Typography, Box, Stack, Avatar } from "@mui/material";
import TrplTitleTextButton from "./components/TitleTextButton";
import Logo from "./PrismLogo.png";

const UnsupportedDeviceMessage: React.FC = () => {
  const title = "PRISM FI";
  return (
    <Stack
      spacing={1}
      sx={{
        justifyContent: "center",
        alignItems: "center",
        minHeight: "90vh",
      }}
    >
      <Box paddingTop="10vh">
        <Avatar
          src={Logo}
          alt="Logo"
          sx={{ width: 90, height: 90, margin: "auto" }}
        />
      </Box>
      <Box
        paddingBottom="9vh"
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <TrplTitleTextButton label={title} fontSize="32px" />
      </Box>

      <Box padding="3vh">
        <Typography
          variant="h6"
          fontSize="18px"
          fontFamily="RamaGothic"
          align="center"
          color={"#858585"}
        >
          Thank you for your interest in Prism FI. Currently, we only support
          desktop web access. Please visit us using a laptop or desktop
          computer.
        </Typography>
      </Box>
    </Stack>
  );
};

export default UnsupportedDeviceMessage;