import React from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';

interface LoadingErrorDisplayProps {
  isLoading: boolean;
  error: { message: string; code?: number } | null;
}

const LoadingErrorDisplay: React.FC<LoadingErrorDisplayProps> = ({ isLoading, error }) => {
  if (isLoading) {
    return (
      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" height={"55vh"}>
        <Typography color="primary" variant="h6">LOADING</Typography>
        <CircularProgress color="secondary" />
      </Box>
    );
  }

  if (error) {
    return (
      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" height={"55vh"}>
        <Typography align="center" color="primary" variant="h6">
          {`${error.message.toUpperCase()}${error.code ? ` (CODE: ${error.code})` : ''}`}
        </Typography>
      </Box>
    );
  }

  return null; // Return null if there's no loading or error state
};

export default LoadingErrorDisplay;
