import { Button } from "@mui/material";
import TrplTheme from "../../Theme";

export default function TrplBasicButton(props: any) {
  return (
    <Button
      disableRipple={props.disableRipple}
      id={props.id}
      variant="outlined"
      endIcon={props.endIcon}
      sx={{
        fontSize: props.fontSize,
        padding: props.padding,
        borderRadius: 0,
        backgroundColor: props.disabled ? "#f5f5f5" : TrplTheme.palette.primary.light,
        color: props.disabled ? "#bdbdbd" : TrplTheme.palette.primary.dark,
        width: props.width,
        height: props.height,
        "&:hover": {
          backgroundColor: props.disabled ? "#f5f5f5" : TrplTheme.palette.primary.light,
        },
      }}
      onClick={props.onClick}
      disabled={props.disabled}
    >
      {props.label}
    </Button>
  );
}