import React, { useState, useRef } from 'react';
import { Box } from '@mui/material';
import TrplDropdownMenu from '../../../components/pages/DropdownMenu';
import TrplInputTextField from '../../../components/pages/InputTextField';
import TrplPageHeading from '../../../components/pages/PageHeading';
import TrplPageHeadingFootnote from '../../../components/pages/PageHeadingFootnote';
import TrplBasicButton from '../../../components/pages/BasicButton';
import TrplPageInputText from '../../../components/pages/PageInputText';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import TrplPageSubheading from '../../../components/pages/PageSubheading';
import Theme from '../../../Theme';
import TrplPageMainText from '../../../components/pages/PageMainText';
import FetchEarningsKPI, { FetchEarningsKPIHandles } from './FetchEarningsKPI';

import ReplayIcon from '@mui/icons-material/Replay';
import FetchEarningsGuidance, { FetchGudianceHandles } from './FetchEarningsGuidance';
import Telemetry from '../../../Telemetry';

const pageTitle = 'Earnings Summary';
const pageSubheading = 'This page generates a summary of earnings for a stock based on quarterly reports.';
const selectEarningsSubheading = 'Select a year and quarter to obtain the summary of the earnings report from below.';
const pageInputText = 'Enter the stock ticker to execute the model.';

interface EarningYear {
  year: number;
  quarters: number[];
}

export default function StreamEarningsSummary() {
  const [earningsYears, setEarningsYears] = useState<EarningYear[]>([]);
  const [selectedYear, setSelectedYear] = useState<string | null>(null);
  const [selectedQuarter, setSelectedQuarter] = useState<string | null>(null);
  const [inputValue, setInputValue] = useState('');
  const [earningsTranscript, setEarningsTranscript] = useState('');
  const [earningsSummary, setEarningsSummary] = useState(''); 
  const [stockTicker, setStockTicker] = useState('');
  const [year, setYear] = useState('');
  const [quarter, setQuarter] = useState('');
  const [showButtons, setShowButtons] = useState(false);
  const [kpiData, setKpiData] = useState(''); // Holds the streamed KPI data
  const [guidanceData, setGuidanceData] = useState(''); // Holds the streamed KPI data

  const fetchKPIRef = useRef<FetchEarningsKPIHandles>(null);
  const fetchGuidanceRef = useRef<FetchGudianceHandles>(null);

  const handleKPIStream = (chunk: string) => {
    setKpiData(prevKpiData => prevKpiData + chunk);
  };

  const handleGuidanceStream = (chunk: string) => {
    setGuidanceData(prevGuidanceData => prevGuidanceData + chunk);
  };

  const fetchData = (stockTicker: string) => {
    setShowButtons(false);
    fetch(`https://financialmodelingprep.com/api/v4/earning_call_transcript?symbol=${stockTicker}&apikey=307c58e7925620ff3a1fda9992a66db3`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      }
    })
    .then(response => response.json())
    .then((data: any[]) => { // Assuming data is an array, adjust if the structure is different
      const yearsMap: { [year: number]: number[] } = {};
      data.slice(0, 12).forEach((item: any) => {
        const quarter: number = item[0];
        const year: number = item[1];
        yearsMap[year] = yearsMap[year] || [];
        if (!yearsMap[year].includes(quarter)) {
          yearsMap[year].push(quarter);
        }
      });
  
      const earningsYears: EarningYear[] = Object.keys(yearsMap).map((yearStr: string) => {
        const year: number = parseInt(yearStr);
        const quarters: number[] = yearsMap[year].sort((a: number, b: number) => a - b);
        return { year, quarters };
      }).sort((a: EarningYear, b: EarningYear) => b.year - a.year);
  
      setEarningsYears(earningsYears);
      if (earningsYears.length > 0) {
        const lastYear = earningsYears[0];
        setSelectedYear(lastYear.year.toString()); // Automatically select the last year
        setSelectedQuarter(`Q${lastYear.quarters[0]}`); // Automatically select the first quarter of the last year
      }
    })
    .catch(error => console.error('Fetching error:', error));
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const handleClick = () => {
    yearOptions = [];
    setKpiData('');
    setGuidanceData('');
    setEarningsSummary('');
    setEarningsTranscript('');
    setEarningsYears([]);
    setSelectedYear(null);
    setSelectedQuarter(null);
    setShowButtons(false);
    fetchData(inputValue);
  };

  const fetchEarningsSummaryFromBackend = async (stockTicker: string, year: string, quarter: string) => {
    if (!year || !quarter) return;

    setEarningsSummary('');
    setKpiData('');
    setGuidanceData('');
    const apiUrl = `https://financialmodelingprep.com/api/v3/earning_call_transcript/${stockTicker}?year=${year}&quarter=${quarter}&apikey=307c58e7925620ff3a1fda9992a66db3`;
    
    try {
      const response = await fetch(apiUrl);
      const data = await response.json();
      const transcriptContent = data[0]?.content;
  
      if (transcriptContent) {
        setEarningsTranscript(transcriptContent);
        const payload = {
          earnings: transcriptContent
        };
  
        const backendUrl = `${process.env.REACT_APP_BACKEND}/earnings-summary/`;
        const backendResponse = await fetch(backendUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(payload),
        });
  
        if (!backendResponse.body) throw new Error('Failed to stream response');
        const reader = backendResponse.body.getReader();
        let streamedSummary = '';

        setStockTicker(inputValue);
        setYear(selectedYear ?? '');
        setQuarter(selectedQuarter ?? '');

        while (true) {
          const { done, value } = await reader.read();
          if (done) break;
          streamedSummary += new TextDecoder().decode(value);
          setEarningsSummary((prev) => prev + new TextDecoder().decode(value));
        }

        setShowButtons(true);
        const telemetry = Telemetry.getInstance();
        telemetry.trackEvent('AI_Earnings_Summary', {Stock_Ticker: inputValue, Year: year, Quarter: quarter, Success: true});
      } 
      else {
        console.error('No transcript content available.');
        const telemetry = Telemetry.getInstance();
        telemetry.trackEvent('AI_Earnings_Summary', {Stock_Ticker: inputValue, Year: year, Quarter: quarter, Success: false, Error: 'No transcript content available.'});
      }
    } 
    catch (error) {
      console.error('Error fetching earnings transcript or streaming summary:', error);
      const telemetry = Telemetry.getInstance();
        telemetry.trackEvent('AI_Earnings_Summary', {Stock_Ticker: inputValue, Year: year, Quarter: quarter, Success: false, Error: error});
    }
  };
  
  const handleInputForSummary = () => {
    setShowButtons(false);
    if (selectedYear && selectedQuarter && inputValue) {
      fetchEarningsSummaryFromBackend(inputValue, selectedYear, selectedQuarter)
        .catch(error => console.error('Error streaming earnings summary:', error));
    }
  };

  const handleYearChange = (selectedItem: string) => {
    setSelectedYear(selectedItem);
    const year = parseInt(selectedItem);
    const yearData = earningsYears.find(ed => ed.year === year);
    if (yearData) {
      const quarters = yearData.quarters;
      if (quarters.length > 0) {
        handleQuarterChange(`Q${quarters[0]}`);
      }
    }
  };

  const handleQuarterChange = (selectedItem: string) => {
    setSelectedQuarter(selectedItem);
  };

  var yearOptions = Array.from(new Set(earningsYears.map(ed => ed.year.toString())));
    const quarterOptions = selectedYear
    ? earningsYears
        .filter(ed => ed.year.toString() === selectedYear)
        .flatMap(ed => ed.quarters.map(quarter => `Q${quarter}`))
    : [];

    const handleGetKPI = () => {
      kpiData && setKpiData('');
      if (fetchKPIRef.current) {
        fetchKPIRef.current.fetchKPI({
          earningsTranscript: earningsTranscript,
          onEarningsKPIReceived: handleKPIStream,
        });
      }
    };

    const handleGetGuidance = () => {
      guidanceData && setGuidanceData('');
      if (fetchGuidanceRef.current) {
        fetchGuidanceRef.current.fetchGuidance({
          earningsTranscript: earningsTranscript,
          onEarningsGuidanceReceived: handleGuidanceStream,
        });
      }
    };
  return (
    <div>
      <TrplPageHeading title={pageTitle} />
      <TrplPageHeadingFootnote title={pageSubheading} />
      <Box sx={{ flexDirection: 'row', display: 'flex', gap: '6px', mt: 4, mb: .5 }}>
        <TrplInputTextField label='STOCK TICKER' height='4.7vh' fontSize='0.9rem' value={inputValue} onChange={handleInputChange} />
        <TrplBasicButton label='ENTER' height='4.7vh' width='4vw' onClick={handleClick} />
      </Box>
      <TrplPageInputText title={pageInputText} />
      {yearOptions.length > 0 && (
        <>
            <Box height="1vh" />
            <TrplPageSubheading title="Select Date " fontSize="18px" color={Theme.palette.primary.dark} />
            <TrplPageHeadingFootnote title={selectEarningsSubheading} />
        </>
        )}
      <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', mt: 2 }}>
            {yearOptions.length > 0 && (
                <>
                    <TrplDropdownMenu options={yearOptions} onChange={handleYearChange} height='4.7vh' width='8vw' endIcon={<KeyboardArrowDownIcon />}/>
                </>
            )}
            {selectedYear && quarterOptions.length > 0 && (
                <>
                <TrplDropdownMenu key={`quarter-dropdown-${selectedYear}`} options={quarterOptions} onChange={handleQuarterChange} height='4.7vh' width='8vw' endIcon={<KeyboardArrowDownIcon />}/>
                <TrplBasicButton label='ENTER' height='4.7vh' width='4vw' onClick={handleInputForSummary} />
                </>
            )}
        </Box>

        <Box height="1vh" />
        {earningsSummary && 
        <>
            <TrplPageSubheading title={`Summary of Earnings for ${stockTicker} ${year} ${quarter}`} fontSize="18px" color={Theme.palette.primary.dark} />
            <TrplPageMainText text={earningsSummary} />
        </>}
        <Box height="1vh" />
        {earningsSummary && showButtons &&
          <>
            <Box sx={{ display: 'flex', gap: 3, alignItems: 'center', mt: 2 }}>
              <TrplBasicButton label='RETRY' height='4.7vh' width='8vw' endIcon={<ReplayIcon />} onClick={handleInputForSummary}/>
              <TrplBasicButton label='GET KEY PERFORMANCE INDICATORS' height='4.7vh' width='18vw' onClick={handleGetKPI} />
              <TrplBasicButton label='GET FORWARD LOOKING GUIDANCE' height='4.7vh' width='18vw' onClick={handleGetGuidance}/>
            </Box>
            
            <Box height="3vh" />
            {kpiData && 
              <>
                <TrplPageSubheading title="Key Performance Indicators" fontSize="18px" color={Theme.palette.primary.dark} />
                <TrplPageMainText text={kpiData} />
              </>
            }

            <Box height="1vh" />
            {guidanceData && 
              <>
                <TrplPageSubheading title="Forward Looking Guidance" fontSize="18px" color={Theme.palette.primary.dark} />
                <TrplPageMainText text={guidanceData} />
              </>
            }
          </>}
          <FetchEarningsKPI ref={fetchKPIRef} />
          <FetchEarningsGuidance ref={fetchGuidanceRef} />
    </div>
  );
}