import { Typography, Box } from '@mui/material';
import TrplTheme from '../../Theme';

export default function TrplPageMainText({ text }: { text: string}) {
  // Split the text into points based on a pattern that identifies the start of each point.
  // This pattern looks for a digit followed by a period and a space, which precedes each point in the input text.
  // We then filter out any empty strings that might result from this split operation.
  const points = text.split(/(?<=^|\s)(1[0-9]|20|[1-9])\.\s/).filter(point => point.trim() !== '' && !/^(1[0-9]|20|[1-9])$/.test(point.trim()));

  return (
    <Box sx={{ marginRight: '30px' }}> {/* Added Box with left margin */}
      <ul style={{ color: TrplTheme.palette.primary.main }}>
        {points.map((point, index) => (
          <li key={index}>
            <Typography
              variant="h6"
              sx={{
                fontFamily: 'RobotoMono, monospace',
                fontStyle: 'regular',
                fontSize: '0.9rem',
                color: TrplTheme.palette.primary.main,
              }}
            >
              {point.trim()}
            </Typography>
          </li>
        ))}
      </ul>
    </Box>
  );
}
