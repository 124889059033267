import { Box } from '@mui/material';
import React, { useState } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import TrplDropdownMenu from '../../../components/pages/DropdownMenu';
import TrplInputTextField from '../../../components/pages/InputTextField';

import TrplPageHeading from '../../../components/pages/PageHeading';
import TrplPageHeadingFootnote from '../../../components/pages/PageHeadingFootnote';
import TrplBasicButton from '../../../components/pages/BasicButton';
import TrplPageInputText from '../../../components/pages/PageInputText';
import StockDataTable from './StockDataTable';
import TrplPageText from '../../../components/pages/PageText';

import Theme from '../../../Theme';
import Telemetry from '../../../Telemetry';

const pageTitle = 'Historical Volatility';
const pageSubheading = 'This model returns the historical price range of an asset for a given time interval. Use it to ascertain the risk-reward profile for the asset.';
const pageInputText = 'Enter the stock ticker and time interval to execute the model.';

const options = ['1 DAY', '1 WEEK', '1 MONTH', '1 QUARTER'];

function getBackendTimeInterval(timeInterval: string): string {
  switch (timeInterval) {
    case '1 DAY':
      return '1d';
    case '1 WEEK':
      return '1wk';
    case '1 MONTH':
      return '1mo';
    case '1 QUARTER':
      return '3mo';
    default:
      return '';
  }
}

interface SigmaDistribution {
  Sigma: number;
  PctRange: string;
  PriceRange: string;
  Probability: number;
}

interface RangeDistribution {
  PctRange: string;
  Frequency: number;
  Probability: string;
}

export interface StockData {
  last_close: string;
  sigma_distribution: SigmaDistribution[];
  range_distribution: RangeDistribution[];
}

function HistoricalVolatility() {
  const [stockData, setStockData] = useState<StockData | null>(null);

  const fetchData = (stockTicker: string, timeInterval: string) => {
    fetch(`${process.env.REACT_APP_BACKEND}/volatility-historical/?stockTicker=${stockTicker}&timeInterval=${timeInterval}`)
    .then(response => response.json())
    .then(data => {
      const parsedData = JSON.parse(data);
      parsedData.last_close = parsedData.last_close.replace(/"/g, '');
      setStockData(parsedData);
      const telemetry = Telemetry.getInstance();
      telemetry.trackEvent('Historical_Volatility_Fetch', { Stock_Ticker: inputValue, Time_Interval: selectedOption, Success: true});
    })
    .catch(error => {
      const telemetry = Telemetry.getInstance();
      telemetry.trackEvent('Historical_Volatility_Fetch', { Stock_Ticker: inputValue, Time_Interval: selectedOption, Success: false, Error: error});
    });
  };

  const [selectedOption, setSelectedOption] = useState(options[0]);
  const handleDropdownChange = (value: string) => {
    setSelectedOption(value);
  };  

  const [inputValue, setInputValue] = useState('');
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const handleClick = () => {
    fetchData(inputValue, getBackendTimeInterval(selectedOption));
  };

  return (
    <div>
        <TrplPageHeading title={pageTitle}/>
        <TrplPageHeadingFootnote title={pageSubheading} />
        <Box sx={{ flexDirection: 'row', display: 'flex', gap: '6px', mt: 4, mb: .5}}>
          <TrplInputTextField label='STOCK TICKER' height='4.7vh' fontSize='0.9rem' value={inputValue} onChange={handleInputChange} />
          <TrplDropdownMenu options={options} onChange={handleDropdownChange} height='4.7vh' width='8vw' endIcon={<KeyboardArrowDownIcon />}/>
          <TrplBasicButton label='ENTER' height='4.7vh' width='4vw' onClick={handleClick}/>
        </Box>
        <TrplPageInputText title={pageInputText}/>
        <Box height="4vh"/>
        {stockData && (
          <>
            <TrplPageHeading title={stockData.last_close.split('|')[0]} fontSize='21px' color={Theme.palette.primary.dark} /> 
            <Box height="2vh"/>
            <TrplPageText title={stockData.last_close.split('|')[1]} fontSize='14px' color={Theme.palette.primary.main}/>
            <StockDataTable stockData={stockData} />
          </>
        )}

    </div>
  );
}

export default HistoricalVolatility;
