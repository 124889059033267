import { Box, styled } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import React, { useState } from 'react';

import TrplInputTextField from '../../../components/pages/InputTextField';
import TrplPageHeading from '../../../components/pages/PageHeading';
import TrplPageHeadingFootnote from '../../../components/pages/PageHeadingFootnote';
import TrplBasicButton from '../../../components/pages/BasicButton';
import TrplPageInputText from '../../../components/pages/PageInputText';

import Theme from '../../../Theme';
import TrplPageSubheading from '../../../components/pages/PageSubheading';
import TrplPageText from '../../../components/pages/PageText';
import Telemetry from '../../../Telemetry';

const pageTitle = 'Portfolio Correlation';
const pageSubheading = 'This model measures the historical correlation of assets within a portfolio. It assumes equal weighting for all assets.';
const correlationDefinition = 'The correlation matrix contains an individual comparison of correlations for each asset in the portfolio.';
const pageInputText = 'Enter the stock tickers separated by commas in your portfolio to obtain the correlation.';

  const TrplDataGrid = styled(DataGrid)(({ theme }) => ({   
    '& .MuiDataGrid-cell': {
      borderColor: theme.palette.divider,
      borderWidth: '.5px',
      borderStyle: 'solid',
      color: 'grey',
      fontFamily: 'RobotoMono', // Add your desired font here
      fontStyle: 'thin',
    },
    '& .MuiDataGrid-columnHeader': {
      borderColor: theme.palette.divider,
      borderWidth: '.5px',
      borderStyle: 'solid',
      fontFamily: 'RobotoMono', // Add your desired font here
      color: 'grey',
      fontStyle: 'normal',
      backgroundColor: "#eef7ed",
    },
    '& .MuiDataGrid-iconSeparator': {
        display: 'none',
      },
      overflow: 'auto',
  }));

interface CorrelationMatrix {
  [key: string]: number;
}

interface CorrelationResponse {
  correlation: string;
  correlation_matrix: CorrelationMatrix[];
}

export default function Correlation() {
  const [atrData, setAtrData] = useState<CorrelationResponse | null>(null);
  const fetchData = (stockTicker: string) => {
    fetch(`${process.env.REACT_APP_BACKEND}/portfolio-corr/?stockTicker=${stockTicker}`)
      .then(response => response.text())
      .then(data => {
        const parsedData: CorrelationResponse = JSON.parse(data);
        setAtrData(parsedData);
        const telemetry = Telemetry.getInstance();
        telemetry.trackEvent('Portfolio_Correlation_Fetch', {Portfolio_Positions: stockTicker, Success: true});
      })
    .catch(error => {
        const telemetry = Telemetry.getInstance();
        telemetry.trackEvent('Portfolio_Correlation_Fetch', { Portfolio_Positions: stockTicker, Success: false, Error: error});
    });
  };

  const [inputValue, setInputValue] = useState('');
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const handleClick = () => {
    fetchData(inputValue);
  };

  const createColumns = (matrix: CorrelationMatrix[]): any[] => {
    if (!matrix || matrix.length === 0) return [];

    const tickers = Object.keys(matrix[0]);

    const columns = tickers.map(ticker => ({
      field: ticker,
      headerName: ticker,
      minWidth: 150,
    }));

    return [{ field: 'id', headerName: 'Ticker', minWidth: 150 }, ...columns];
  };

  const correlationMatrixToRows = (matrix: CorrelationMatrix[]): any[] => {
    const tickers = matrix.length > 0 ? Object.keys(matrix[0]) : [];
  
    return matrix.map((row, index) => ({
      id: tickers[index],
      ...row,
    }));
  };


  const columns = atrData ? createColumns(atrData.correlation_matrix) : [];
  const rows = atrData ? correlationMatrixToRows(atrData.correlation_matrix) : [];

  const maxVisibleColumns = 9;
  const maxVisibleRows = 9;
  const columnWidth = 150; // Min width of each column
  const columnHeaderHeight = 58; // Approximate height of the column header
  const rowHeight = 50; // Approximate height of each row

  const gridWidth = Math.min(columns.length, maxVisibleColumns) * columnWidth;
  const gridHeight = Math.min(rows.length, maxVisibleRows) * rowHeight + columnHeaderHeight;


  return (
    <div>
      <TrplPageHeading title={pageTitle} />
      <TrplPageHeadingFootnote title={pageSubheading} />
      <Box sx={{ flexDirection: 'row', display: 'flex', gap: '6px', mt: 4, mb: 0.5 }}>
        <TrplInputTextField
          label="PORTFOLIO TICKERS"
          height="4.7vh"
          width="25vw"
          fontSize="0.9rem"
          value={inputValue}
          onChange={handleInputChange}
        />
        <TrplBasicButton label="ENTER" height="4.7vh" width="4vw" onClick={handleClick} />
      </Box>
      <TrplPageInputText title={pageInputText} />
      <Box height="4vh" />
      {atrData && columns.length > 0 && (
        <div style={{ height: 400, width: '100%' }}>
          <TrplPageSubheading title='Overall Correlation' fontSize="18px" color={Theme.palette.primary.dark} />
          <Box height="1vh" />
          <TrplPageText title={`The overall portfolio correlation is: ${atrData.correlation}%.`}/>
          <Box height="2vh" />
          <TrplPageSubheading title='Correlation Matrix' fontSize="18px" color={Theme.palette.primary.dark} />
          <TrplPageHeadingFootnote title={correlationDefinition} />
          <Box height="1vh" />
          <TrplDataGrid
            style={{ width: gridWidth, height: gridHeight }}
            rows={rows}
            columns={columns}
            pageSize={15}
            rowsPerPageOptions={[15]}
            checkboxSelection={false}
            rowHeight={50}
            hideFooter
          />
        </div>
      )}
    </div>
  );
}
